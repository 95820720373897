import { DomBuilder, getURI, in_array, ucfirst } from '../../chat/page_actions_main';
import { default as axios } from "axios";
import { isEmpty, isEqualWith, isNull, isNumber, isSet, isString, isUndefined, parseInt } from 'lodash';
import { Alert } from '../alert';


window.friendAction = () => {
    let acceptFriendBtn = [
        document.querySelectorAll('div#friends-item li > a#accept-friend'),
        ...[
            ...Array.from(document.querySelectorAll('ul#friends')).map(ul => ul.querySelectorAll('a#add-friend'))
        ],
    ],
    cancelFriendBtn = [
        document.querySelectorAll('div#friends-item li > a#cancel-friend'),
        ...[
            ...Array.from(document.querySelectorAll('ul#friends')).map(ul => ul.querySelectorAll('a#cancel-friend'))
        ],
    ];
    document.querySelectorAll('div#friends-item li > a#add-friend').forEach((btn, index) => { //   addFriendBtn
        btn.addEventListener('click', () => {
            const state = btn.getAttribute('data-state');
            if (state && isEqualWith(state, "blank")) {
                const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
                axios({
                    method: 'POST',
                    url: getURI() + '/api/send-friend-request',
                    data: {
                        filiate_id: user_id,
                        user_id: window.id
                    }
                }).then(response => {
                    // console.log(response.data);
                    if (response.data.code && response.data.code == 200) {
                        let ul = document.querySelectorAll('div#friends-item li > a#add-friend')[index].parentElement.parentElement,
                            link1 = document.createElement('a'),
                            link2 = document.createElement('a'),
                            li1 = document.createElement('li'),
                            li2 = document.createElement('li');

                        link1.setAttribute('href', 'javascript: void(0)');
                        link1.setAttribute('aria-describedby', '');
                        link1.setAttribute('class', 'disabled');
                        link1.setAttribute('style', 'cursor: auto; pointer-events: none;');
                        link1.textContent = 'Pending response';
                        link2.setAttribute('href', 'javascript: void(0)');
                        link2.setAttribute('aria-describedby', '');
                        link2.setAttribute('id', 'cancel-friend');
                        link2.setAttribute('class', 'text-danger');
                        link2.setAttribute('data-state', 'cancel');
                        link2.textContent = 'Cancel invitation';

                        ul.firstElementChild.remove();
                        li1.appendChild(link1);
                        li2.appendChild(link2);

                        ul.prepend(li2);
                        ul.prepend(li1);
                        let alert = Alert('success', 'Your invitation sent successfully !!!');
                        document.body.appendChild(alert);
                        setTimeout(() => {
                            document.querySelector('#alert-btn').click();
                            setTimeout(() => {
                                alert.remove();
                            }, 2000);
                        }, 10000);
                    } else {
                        let alert = Alert('danger', 'Something wen wrong.');
                        document.body.appendChild(alert);
                        setTimeout(() => {
                            document.querySelector('#alert-btn').click();
                            setTimeout(() => {
                                alert.remove();
                            }, 2000);
                        }, 10000);
                    }
                    friendAction();
                });
            } else {
                let alert = HTMLDivElement;
                switch (document.querySelectorAll('div#friends-item li > a#add-friend')[index].getAttribute('data-state')) {
                    case 'in_progress':
                        alert = Alert('warning', 'Your request is pending response.');
                        break;
                    case 'refuse':
                        alert = Alert('warning', 'Your request was refused.');
                        break;
                    case 'blocked':
                        alert = Alert('warning', 'This user is blocked.');
                        break;

                    default:
                        alert = Alert('warning', 'Something wen wrong.');
                        break;
                }
                document.body.appendChild(alert);
                setTimeout(() => {
                    document.querySelector('#alert-btn').click();
                    setTimeout(() => {
                        alert.remove();
                    }, 2000);
                }, 10000);
            }
        }, false);
    });
    cancelFriendBtn.forEach((button, key) => { //    cancelFriendBtn
        button.forEach((btn, index) => {
            btn.addEventListener('click', () => {
                const state = btn.getAttribute('data-state') || false;
                if (state && isEqualWith(state, "cancel")) {
                    const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
                    axios({
                        method: 'POST',
                        url: getURI() + '/api/cancel-friend-request',
                        data: {
                            afiliate_id: user_id,
                            user_id: window.id
                        }
                    }).then(response => {
                        if (response.data.code && response.data.code == 200) {
                            document.querySelectorAll('#friends-len').forEach(len => {
                                len.textContent = parseInt(len.textContent) == NaN || parseInt(len.textContent) <= 0 ? 0 : (parseInt(len.textContent) - 1);
                            })
                            document.querySelectorAll('ul#friends').forEach(ul => ul.children[index] ? ul.children[index].remove() : null)
                            if (btn.hasAttribute('data-partial') && btn.getAttribute('data-partial') == "true") {
                                return
                            }
                            let ul = btn.parentElement.parentElement,
                                link = document.createElement('a'),
                                li = document.createElement('li'),
                                sender = response.data.sender;
    
                            if ((ul.children[0].textContent.toLowerCase() == "pending response" && ul.children[1].textContent.toLowerCase() == "cancel invitation") || ul.children[0].textContent.toLowerCase() == "reject invitation") {
                                if (sender == window.id) { //   If it's me launched the invitation
                                    let item1 = ul.children[0],
                                        item2 = ul.children[1];
                                    item1.remove();
                                    item2.remove();
                                } else {
                                    ul.children[0].remove();
                                }
    
                                link.setAttribute('href', 'javascript: void(0)');
                                link.setAttribute('aria-describedby', '');
                                link.setAttribute('id', 'add-friend');
                                link.setAttribute('data-state', 'blank');
                                link.textContent = 'Add as friend';
                                li.appendChild(link);
                                ul.prepend(li);
    
                                let alert = Alert('success', 'Your cancellation has been taken into account.');
                                document.body.appendChild(alert);
                                setTimeout(() => {
                                    document.querySelector('#alert-btn').click();
                                    setTimeout(() => {
                                        alert.remove();
                                    }, 2000);
                                }, 10000);
                            } else {
                                window.location.reload();
                            }
                        } else {
                            let alert = Alert('danger', 'Something wen wrong.');
                            document.body.appendChild(alert);
                            setTimeout(() => {
                                document.querySelector('#alert-btn').click();
                                setTimeout(() => {
                                    alert.remove();
                                }, 2000);
                            }, 10000);
                        }
                        friendAction();
                    });
                } else {
                    let alert = HTMLDivElement;
                    switch (document.querySelectorAll('div#friends-item li > a#add-friend')[index].getAttribute('data-state')) {
                        case 'in_progress':
                            alert = Alert('warning', 'Your request is pending response.');
                            break;
                        case 'refuse':
                            alert = Alert('warning', 'Your request was refused.');
                            break;
                        case 'blocked':
                            alert = Alert('warning', 'This user is blocked.');
                            break;
    
                        default:
                            alert = Alert('warning', 'Something wen wrong.');
                            break;
                    }
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                }
            }, false);
        })
    });
    document.querySelectorAll('div#friends-item li > a#remove-friend, a#remove-friend').forEach((btn, index) => { //    removeFriendBtn
        btn.addEventListener('click', () => {
            const state = btn.getAttribute('data-state');
            if (state && isEqualWith(state, "established")) {
                const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
                console.log('ok');
                return
                axios({
                    method: 'POST',
                    url: getURI() + '/api/remove-friend-request',
                    data: {
                        afiliate_id: user_id,
                        user_id: window.id
                    }
                }).then(response => {
                    // console.log(response.data);
                    if (response.data.code && response.data.code == 200) {
                        if (btn.hasAttribute('data-note') && btn.getAttribute('data-note') == "away") {
                            document.querySelectorAll('#frnd-inner')[index].remove();
                            return
                        }
                        let ul = btn.parentElement.parentElement,
                            link = document.createElement('a'),
                            li = document.createElement('li'),
                            sender = response.data.sender;

                        if ((ul.children[0].textContent.toLowerCase() == "pending response" && ul.children[1].textContent.toLowerCase() == "cancel invitation") || ul.children[0].textContent.toLowerCase() == "remove as friend") {
                            if (sender == window.id) { //   If it's me launched the invitation
                                let item1 = ul.children[0],
                                    item2 = ul.children[1];
                                item1.remove();
                                item2.remove();
                            } else {
                                ul.children[0].remove();
                            }

                            link.setAttribute('href', 'javascript: void(0)');
                            link.setAttribute('aria-describedby', '');
                            link.setAttribute('id', 'add-friend');
                            link.setAttribute('data-state', 'blank');
                            link.textContent = 'Add as friend';
                            li.appendChild(link);
                            ul.prepend(li);

                            let alert = Alert('success', 'Your deleting has been taken into account.');
                            document.body.appendChild(alert);
                            setTimeout(() => {
                                document.querySelector('#alert-btn').click();
                                setTimeout(() => {
                                    alert.remove();
                                }, 2000);
                            }, 10000);
                        } else {
                            window.location.reload();
                        }
                    } else {
                        let alert = Alert('danger', 'Something wen wrong.');
                        document.body.appendChild(alert);
                        setTimeout(() => {
                            document.querySelector('#alert-btn').click();
                            setTimeout(() => {
                                alert.remove();
                            }, 2000);
                        }, 10000);
                    }
                    friendAction();
                });
            } else {
                let alert = HTMLDivElement;
                switch (document.querySelectorAll('div#friends-item li > a#add-friend')[index].getAttribute('data-state')) {
                    case 'in_progress':
                        alert = Alert('warning', 'Your request is pending response.');
                        break;
                    case 'refuse':
                        alert = Alert('warning', 'Your request was refused.');
                        break;
                    case 'blocked':
                        alert = Alert('warning', 'This user is blocked.');
                        break;

                    default:
                        alert = Alert('warning', 'Something wen wrong.');
                        break;
                }
                document.body.appendChild(alert);
                setTimeout(() => {
                    document.querySelector('#alert-btn').click();
                    setTimeout(() => {
                        alert.remove();
                    }, 2000);
                }, 10000);
            }
        }, false);
    });
    acceptFriendBtn.forEach((button, key) => { //    acceptFriendBtn
        button.forEach((btn, index) => {
            btn.addEventListener('click', () => {
                const state = btn.getAttribute('data-state');
                if (state && isEqualWith(state, "accept")) {
                    const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
                    axios({
                        method: 'POST',
                        url: getURI() + '/api/accept-friend-request',
                        data: {
                            user_id: user_id,
                            my_id: window.id
                        }
                    }).then(response => {
                        if (response.data.code && response.data.code == 200) {
                            document.querySelectorAll('#friends-len').forEach(len => {
                                len.textContent = parseInt(len.textContent) == NaN || parseInt(len.textContent) <= 0 ? 0 : (parseInt(len.textContent) - 1);
                            })
                            document.querySelectorAll('ul#friends').forEach(ul => ul.children[index] ? ul.children[index].remove() : null)
                            if (btn.hasAttribute('data-partial') && btn.getAttribute('data-partial') == "true") {
                                return
                            }
                            let ul = btn.parentElement.parentElement,
                                link = document.createElement('a'),
                                li = document.createElement('li');
    
                            let item1 = ul.children[0],
                                item2 = ul.children[1];
                            item1.remove();
                            item2.remove();
    
                            link.setAttribute('href', 'javascript: void(0)');
                            link.setAttribute('aria-describedby', '');
                            link.setAttribute('id', 'remove-friend');
                            link.setAttribute('class', 'text-danger');
                            link.setAttribute('data-state', 'established');
                            link.textContent = 'Remove as friend';
                            li.appendChild(link);
                            ul.prepend(li);
    
                            let alert = Alert('success', 'You friend saved successfully !!!');
                            document.body.appendChild(alert);
                            setTimeout(() => {
                                document.querySelector('#alert-btn').click();
                                setTimeout(() => {
                                    alert.remove();
                                }, 2000);
                            }, 10000);
                        } else {
                            let alert = Alert('warning', 'Something wen wrong.');
                            document.body.appendChild(alert);
                            setTimeout(() => {
                                document.querySelector('#alert-btn').click();
                                setTimeout(() => {
                                    alert.remove();
                                }, 2000);
                            }, 10000);
                        }
                        friendAction();
                    });
                } else {
                    let alert = HTMLDivElement;
                    switch (document.querySelectorAll('div#friends-item li > a#add-friend, ul#friends a#add-friend')[index].getAttribute('data-state')) {
                        case 'in_progress':
                            alert = Alert('warning', 'Your request is pending response.');
                            break;
                        case 'refuse':
                            alert = Alert('warning', 'Your request was refused.');
                            break;
                        case 'blocked':
                            alert = Alert('warning', 'This user is blocked.');
                            break;
    
                        default:
                            alert = Alert('warning', 'Something wen wrong.');
                            break;
                    }
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                }
            }, false);
        })
    });
    document.querySelectorAll('div#friends-item li > a#unfollow-friend').forEach((btn) => { //  Unfollow
        btn.addEventListener('click', () => {
            const state = btn.getAttribute('data-state');
            if (state && isEqualWith(state, "followed")) {
                const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
                axios({
                    method: 'POST',
                    url: getURI() + '/api/unfollow-friend-request',
                    data: {
                        user_id: user_id,
                        my_id: window.id
                    }
                }).then(response => {
                    // console.log(response.data);
                    if (response.data.code && response.data.code == 200) {
                        let link = document.createElement('a');

                        link.setAttribute('href', 'javascript: void(0)');
                        link.setAttribute('aria-describedby', '');
                        link.setAttribute('id', 'follow-friend');
                        link.setAttribute('data-state', 'unfollowed');
                        link.textContent = 'Follow';

                        const li = btn.parentElement;
                        li.replaceChild(link, li.firstElementChild);

                        const numberOfFollowing = parseInt(document.querySelector('ins#following-count').textContent);
                        document.querySelector('ins#following-count').textContent = (numberOfFollowing - 1);

                        let alert = Alert('success', 'Done !!!');
                        document.body.appendChild(alert);
                        setTimeout(() => {
                            document.querySelector('#alert-btn').click();
                            setTimeout(() => {
                                alert.remove();
                            }, 2000);
                        }, 10000);
                    } else {
                        let alert = Alert('danger', 'Something wen wrong.');
                        document.body.appendChild(alert);
                        setTimeout(() => {
                            document.querySelector('#alert-btn').click();
                            setTimeout(() => {
                                alert.remove();
                            }, 2000);
                        }, 10000);
                    }
                    friendAction();
                });
            } else {
                let alert = Alert('warning', 'Something wen wrong.');
                document.body.appendChild(alert);
                setTimeout(() => {
                    document.querySelector('#alert-btn').click();
                    setTimeout(() => {
                        alert.remove();
                    }, 2000);
                }, 10000);
            }
        }, false);
    });
    document.querySelectorAll('div#friends-item li > a#follow-friend').forEach((btn) => { //    Follow
        btn.addEventListener('click', () => {
            const state = btn.getAttribute('data-state');
            if (state && isEqualWith(state, "unfollowed")) {
                const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
                axios({
                    method: 'POST',
                    url: getURI() + '/api/follow-friend-request',
                    data: {
                        user_id: user_id,
                        my_id: window.id
                    }
                }).then(response => {
                    // console.log(response.data);
                    if (response.data.code && response.data.code == 200) {
                        let link = document.createElement('a');

                        link.setAttribute('href', 'javascript: void(0)');
                        link.setAttribute('aria-describedby', '');
                        link.setAttribute('id', 'unfollow-friend');
                        link.setAttribute('data-state', 'followed');
                        link.textContent = 'Unfollow';

                        const li = btn.parentElement;
                        li.replaceChild(link, li.firstElementChild);

                        const numberOfFollowing = parseInt(document.querySelector('ins#following-count').textContent);
                        document.querySelector('ins#following-count').textContent = (numberOfFollowing + 1);

                        let alert = Alert('success', 'Done !!!');
                        document.body.appendChild(alert);
                        setTimeout(() => {
                            document.querySelector('#alert-btn').click();
                            setTimeout(() => {
                                alert.remove();
                            }, 2000);
                        }, 10000);
                    } else {
                        let alert = Alert('danger', 'Something wen wrong.');
                        document.body.appendChild(alert);
                        setTimeout(() => {
                            document.querySelector('#alert-btn').click();
                            setTimeout(() => {
                                alert.remove();
                            }, 2000);
                        }, 10000);
                    }
                    friendAction();
                });
            } else {
                let alert = Alert('warning', 'Something wen wrong.');
                document.body.appendChild(alert);
                setTimeout(() => {
                    document.querySelector('#alert-btn').click();
                    setTimeout(() => {
                        alert.remove();
                    }, 2000);
                }, 10000);
            }
        }, false);
    });
    document.querySelectorAll('div#friends-item li > a#mute-friend').forEach((btn) => { // mute Friend
        btn.addEventListener('click', () => {
            const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
            axios({
                method: 'POST',
                url: getURI() + '/api/toggle-notification-friend-request',
                data: {
                    user_id: user_id,
                    my_id: window.id
                }
            }).then(response => {
                // console.log(response.data);
                if (response.data.code && response.data.code == 200) {
                    let li = btn.parentElement,
                        link = document.createElement('a');

                    link.setAttribute('href', 'javascript: void(0)');
                    link.setAttribute('id', 'unmute-friend');
                    link.setAttribute('aria-describedby', '');
                    link.textContent = 'Unmute Notifications';

                    li.replaceChild(link, btn);

                    let alert = Alert('success', 'Done !!!');
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                } else {
                    let alert = Alert('danger', 'Something wen wrong.');
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                }
                friendAction();
            });
        }, false);
    });
    document.querySelectorAll('div#friends-item li > a#unmute-friend').forEach((btn) => { //   unmuteFriendBtn
        btn.addEventListener('click', () => {
            const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
            axios({
                method: 'POST',
                url: getURI() + '/api/toggle-notification-friend-request',
                data: {
                    user_id: user_id,
                    my_id: window.id
                }
            }).then(response => {
                // console.log(response.data);
                if (response.data.code && response.data.code == 200) {
                    let li = btn.parentElement,
                        link = document.createElement('a');

                    link.setAttribute('href', 'javascript: void(0)');
                    link.setAttribute('id', 'mute-friend');
                    link.setAttribute('aria-describedby', '');
                    link.textContent = 'mute Notifications';

                    li.replaceChild(link, btn);

                    let alert = Alert('success', 'Done !!!');
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                } else {
                    let alert = Alert('danger', 'Something wen wrong.');
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                }
                friendAction();
            });
        }, false);
    });
    document.querySelectorAll('div#friends-item li > a#block-friend').forEach((btn) => { //    blockFriendBtn
        btn.addEventListener('click', () => {
            const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
            axios({
                method: 'POST',
                url: getURI() + '/api/toggle-block_state-friend-request',
                data: {
                    user_id: user_id,
                    my_id: window.id
                }
            }).then(response => {
                // console.log(response.data);
                if (response.data.code && response.data.code == 200) {
                    let li = btn.parentElement,
                        link = document.createElement('a');

                    link.setAttribute('href', 'javascript: void(0)');
                    link.setAttribute('id', 'unblocked-friend');
                    link.setAttribute('aria-describedby', '');
                    link.textContent = 'Unblock';

                    li.replaceChild(link, btn);

                    let alert = Alert('success', 'Done !!!');
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                } else {
                    let alert = Alert('danger', 'Something wen wrong.');
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                }
                friendAction();
            });
        }, false);
    });
    document.querySelectorAll('div#friends-item li > a#unblock-friend').forEach((btn) => { //  unblockFriendBtn
        btn.addEventListener('click', () => {
            const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
            axios({
                method: 'POST',
                url: getURI() + '/api/toggle-block_state-friend-request',
                data: {
                    user_id: user_id,
                    my_id: window.id
                }
            }).then(response => {
                // console.log(response.data);
                if (response.data.code && response.data.code == 200) {
                    let li = btn.parentElement,
                        link = document.createElement('a');

                    link.setAttribute('href', 'javascript: void(0)');
                    link.setAttribute('id', 'blocked-friend');
                    link.setAttribute('aria-describedby', '');
                    link.textContent = 'Block';

                    li.replaceChild(link, btn);

                    let alert = Alert('success', 'Done !!!');
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                } else {
                    let alert = Alert('danger', 'Something wen wrong.');
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                }
                friendAction();
            });
        }, false);
    });
    let visibility = {
        /**
         * @type {NodeListOf<Element>} hide
         */
        hide: [],
        /**
         * @type {NodeListOf<Element>} show
         */
        show: []
    };
    document.querySelectorAll('div#friends-item li > a#hide-friend').forEach(item => { visibility.hide.push(item) });
    document.querySelectorAll('div#friends-item li > a#show-friend').forEach(item => { visibility.show.push(item) });
    visibility.hide.forEach((btn, index) => {
        btn.addEventListener('click', () => {
            const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
            axios({
                method: 'POST',
                url: getURI() + '/api/toggle-visibility-friend-request',
                data: {
                    user_id: user_id,
                    my_id: window.id
                }
            }).then(response => {
                // console.log(response.data);
                if (response.data.code && response.data.code == 200) {
                    let li = btn.parentElement,
                        link = document.createElement('a');

                    link.setAttribute('href', 'javascript: void(0)');
                    link.setAttribute('id', 'show-friend');
                    link.setAttribute('aria-describedby', '');
                    link.textContent = 'Show from friend list';
                    visibility.hide[index] = link;

                    li.replaceChild(link, btn);

                    let alert = Alert('success', 'Done !!!');
                    document.body.appendChild(alert);
                    document.querySelectorAll('#friends-item > div')[index].remove();
                    let total = parseInt(document.querySelector('#total-result').textContent);
                    if (isNumber(total)) {
                        document.querySelector('#total-result').textContent = total > 0 ? (total - 1) : 0;
                        document.querySelector('#result-count').textContent = total > 0 ? (total - 1) : 0;
                    }
                    friendAction();
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                } else {
                    let alert = Alert('danger', 'Something wen wrong.');
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                }
            });
        }, false);
    });
    visibility.show.forEach((btn, index) => {
        btn.addEventListener('click', () => {
            const user_id = parseInt(btn.parentElement.parentElement.getAttribute('data-item').match(/[0-9]+/)[0]);
            axios({
                method: 'POST',
                url: getURI() + '/api/toggle-visibility-friend-request',
                data: {
                    user_id: user_id,
                    my_id: window.id
                }
            }).then(response => {
                // console.log(response.data);
                if (response.data.code && response.data.code == 200) {
                    let li = btn.parentElement,
                        link = document.createElement('a');

                    link.setAttribute('href', 'javascript: void(0)');
                    link.setAttribute('id', 'hide-friend');
                    link.setAttribute('aria-describedby', '');
                    link.textContent = 'Hide from friend list';
                    visibility.show[index] = link;

                    li.replaceChild(link, btn);

                    let alert = Alert('success', 'Done !!!');
                    document.body.appendChild(alert);
                    friendAction();
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                } else {
                    let alert = Alert('danger', 'Something wen wrong.');
                    document.body.appendChild(alert);
                    setTimeout(() => {
                        document.querySelector('#alert-btn').click();
                        setTimeout(() => {
                            alert.remove();
                        }, 2000);
                    }, 10000);
                }
            });
        }, false);
    });
}

function toggleViewList() {
    document.querySelector('.invitation.popups').classList.value = 'invitation popups show-popups'
}

if (!isNull(document.querySelectorAll('#view-all-invitation'))) document.querySelectorAll('#view-all-invitation').forEach(btn => btn.addEventListener('click', toggleViewList, false))

if (!isNull(document.querySelector('div#searchFriends2, div#searchFriends, a#remove-friend, ul#friends'))) {
    friendAction();
}
if (!isNull(document.querySelector('a#add-friend-now'))) {
    document.querySelector('a#add-friend-now').addEventListener('click', async () => {
        const id = parseInt(document.querySelector('a#add-friend-now').getAttribute('data-tken') || '0')
        if (id < 1) return

        let response = await axios({
            method: 'POST',
            url: getURI() + '/api/send-friend-request',
            data: {
                filiate_id: id,
                user_id: window.id
            }
        }).then(response => response.data)

        if (response.code == 200) {
            let loader = DomBuilder(`
                <div class="spinner-border text-light" role="status" style="width: 18px; height: 18px;">
                    <span class="sr-only">Loading...</span>
                </div>
            `).children[0]
            document.querySelector('a#add-friend-now').innerHTML = '';
            ('append' in document.querySelector('a#add-friend-now')) ? 
                document.querySelector('a#add-friend-now').append(loader) :
                document.querySelector('a#add-friend-now').appendChild(loader)
                
            document.querySelector('a#add-friend-now').removeAttribute('id');
            document.querySelector('a#add-friend-now').removeAttribute('data-tken');
        }
    }, false)
}
/**
 * @param {string} value
 */
async function getFilter(value) {
    const data = await axios({
        method: 'GET',
        url:  `${getURI()}/api/filterFriends2?filter=${value}&user_id=${window.id}`
    }).then(data => data.data)
    if (data.code == 200) {
        let users = dataFactory(data.response);
        $('#searchFriends2').html('');
        users.forEach(user => document.querySelector('#searchFriends2').append(user));
        friendAction();
    } else {
        $('#searchFriends2').html('');
    }
}

async function showMoreFriends2() {
    // get value of clicked option
    var nb = document.getElementById("searchFriends2").childElementCount;
    const data = await axios({
        method: 'GET',
        url:  `${getURI()}/api/showMoreFriends2?filter=${nb}&user_id=${window.id}`
    }).then(data => data.data)
    if (data.code == 200) {
        let users = dataFactory(data.response);
        $('#searchFriends2').html('');
        users.forEach(user => document.querySelector('#searchFriends2').append(user));
        friendAction();
    } else {
        $('#searchFriends2').html('');
    }
}

let pagination = {
    page: 1,
    searchType: 'text',
    by: 'none',
    value: '',
    perPage: 10,
    user_id: window.zipusr,
};

/**
 * @param {string} by
 * @param {string} value
 * @param {string} type
 * @returns
 */
async function sortFriends(by, value, type) {
    let total_user = parseInt(document.querySelector('#total-result').textContent);

    type = !in_array(type, ['text', 'sort_by', 'function', 'more']) ? 'text' : type
    by = !in_array(by, ['a_to_z', 'see_all', 'newest', 'oldest', 'hided', 'public', 'private', 'muted', 'unmuted', 'none']) ? 'none' : by
    value = !isString(value) || isEmpty(value) ? '' : value
    if (!isNumber(total_user)) return;

    if (type !== 'more') {
        pagination = {
            ...pagination,
            ...{
                searchType: type,
                by: by,
                value: value,
                page: 1
            }
        }
    } else {
        pagination.page++;
    }

    let params = new URLSearchParams();
    params.append('filter', pagination.by);
    params.append('searchText', pagination.value);
    params.append('searchType', pagination.searchType);
    params.append('user_id', pagination.user_id);
    params.append('length', pagination.perPage);
    params.append('page', pagination.page);

    let data = await axios({
        method: 'GET',
        url:  `${getURI()}/api/sortFriends?${params.toString()}`
    }).then(data => data.data)
    if (data.code == 200) {
        let users = dataFactory2(data.response);
        data = data.response;
        pagination.page = data.pagination.current_page;

        if (pagination.searchType == 'more') {
            document.querySelector('#slice-result').textContent = `1 - ${((paginate) => {
                if (paginate.currentPage == 1) {
                    return 10
                }
                if (paginate.currentPage > 1) {
                    return ((paginate.currentPage - 1) * pagination.perPage) + data.results.length
                }
            })(data.pagination)}`
        } else {
            $('#friends-item').html('');
        }

        users.forEach(user => document.querySelector('#friends-item').append(user));
        friendAction();
    } else {
        document.querySelector('#result-count').textContent = 0;
        $('#friends-item').html('');
    }
}

/**
 * @param {string} by
 * @param {string} value
 * @param {string} type
 * @returns
 */
 async function sortFriends2(by, value, type) {
    let total_user = parseInt(document.querySelector('#total-result').textContent);
    type = !in_array(type, ['text', 'sort_by', 'function', 'more']) ? 'text' : type
    by = !in_array(by, ['a_to_z', 'see_all', 'newest', 'oldest', 'none']) ? 'none' : by
    value = !isString(value) || isEmpty(value) ? '' : value
    if (!isNumber(total_user) || total_user <= 0) return;

    if (type !== 'more') {
        pagination = {
            ...pagination,
            ...{
                searchType: type,
                by: by,
                value: value,
                page: 1
            }
        }
    } else {
        pagination.page++;
    }

    let params = new URLSearchParams();
    params.append('filter', pagination.by);
    params.append('searchText', pagination.value);
    params.append('searchType', pagination.searchType);
    params.append('user_id', pagination.user_id);
    params.append('length', pagination.perPage);
    params.append('page', pagination.page);

    let data = await axios({
        method: 'GET',
        url:  `${getURI()}/api/searchFriends2?${params.toString()}`
    }).then(response => response.data);
    if (data.code == 200) {
        let users = dataFactory(data.response);
        data = data.response;
        pagination.page = data.pagination.current_page;

        if (pagination.searchType == 'more') {
            document.querySelector('#slice-result').textContent = `1 - ${((paginate) => {
                if (paginate.currentPage == 1) {
                    return 10
                }
                if (paginate.currentPage > 1) {
                    return ((paginate.currentPage - 1) * pagination.perPage) + data.results.length
                }
            })(data.pagination)}`
        } else {
            $('#searchFriends2').html('');
        }

        users.forEach(user => document.querySelector('#searchFriends2').append(user));
        friendAction();
    } else {
        document.querySelector('#result-count').textContent = 0;
        $('#searchFriends2').html('');
    }
}

window.getFilter = getFilter;
window.showMoreFriends2 = showMoreFriends2;
window.sortFriends = sortFriends;
window.sortFriends2 = sortFriends2;

/**
 * Build the DOM who display user list
 * @param {{}} data
 * @returns
 */
function dataFactory(data) {
    /**
     * @type {Array<HTMLDivElement>}
     */
    let template = [],
    length = Object.values(data.results).length;

    document.querySelector('#result-count').textContent = length;
    document.querySelector('#total-result').textContent = length;
    Object.values(data.results)
        .forEach((user, index) => {
        let tabs = ``;
        if (isNull(user.state)) { //    No affiliation
            tabs =  `
                <li><a href="javascript: void(0)" id="add-friend" data-state="blank" aria-describedby="">Add as friend</a></li>
                <li><a href="javascript: void(0)" id="${user.userFactory.follow.followed ? 'unfollow' : 'follow'}-friend" data-state="${user.userFactory.follow.followed ? 'followed' : 'unfollowed'}" aria-describedby="">${user.userFactory.follow.followed ? 'Unfollow' : 'Follow'}</a></li>
            `;
        } else {
            if (user['real-state'] == 'established') { //   They are already friends
                tabs = `
                    <li><a href="javascript: void(0)" id="remove-friend" class="text-danger" data-state="established" aria-describedby="">Remove as friend</a></li>
                    <li><a href="javascript: void(0)" class="d-block" id="${user.userFactory.state.private_state == 'blocked' ? 'unblock' : 'block'}-friend" aria-describedby="">${user.userFactory.state.private_state == 'blocked' ? 'UnBlock' : 'Block'}</a></li>
                    <li><a href="javascript: void(0)" class="d-block" id="${user.userFactory.state.notification == 'unmuted' ? 'mute' : 'unmute'}-friend" aria-describedby="">${user.userFactory.state.notification == 'unmuted' ? 'Mute Notifications' : 'Unmute Notifications'}</a></li>
                    <li><a href="javascript: void(0)" class="d-block" id="${user.userFactory.state.display_state == 'show' ? 'hide' : 'show'}-friend" aria-describedby="">${user.userFactory.state.display_state == 'show' ? 'hide from friend list' : 'show from friend list'}</a></li>
                    <li><a href="javascript: void(0)" id="${user.userFactory.follow.followed ? 'unfollow' : 'follow'}-friend" data-state="${user.userFactory.follow.followed ? 'followed' : 'unfollowed'}" aria-describedby="">${user.userFactory.follow.followed ? 'Unfollow' : 'Follow'}</a></li>
                `;
            }
            if (user['real-state'] == 'pending') { //   Invitation is pending answer
                if (window.id == user.invitationInitiator) { // If I am the sender of the invitation
                    tabs = `
                        <li><a href="javascript: void(0)" class="disabled" style="cursor: auto; pointer-events: none;" aria-describedby="">Pending response</a></li>
                        <li><a href="javascript: void(0)" id="cancel-friend" class="text-danger" data-state="cancel" aria-describedby="">Cancel invitation</a></li>
                        <li><a href="javascript: void(0)" id="${user.userFactory.follow.followed ? 'unfollow' : 'follow'}-friend" data-state="${user.userFactory.follow.followed ? 'followed' : 'unfollowed'}" aria-describedby="">${user.userFactory.follow.followed ? 'Unfollow' : 'Follow'}</a></li>
                    `;
                } else { // If I am the receiver of the invitation
                    tabs = `
                        <li><a href="javascript: void(0)" id="cancel-friend" class="text-danger" data-state="cancel" aria-describedby="">Reject invitation</a></li>
                        <li><a href="javascript: void(0)" id="accept-friend" class="text-success" data-state="accept" aria-describedby="">Accept invitation</a></li>
                        <li><a href="javascript: void(0)" id="${user.userFactory.follow.followed ? 'unfollow' : 'follow'}-friend" data-state="${user.userFactory.follow.followed ? 'followed' : 'unfollowed'}" aria-describedby="">${user.userFactory.follow.followed ? 'Unfollow' : 'Follow'}</a></li>
                    `;
                }
            }
        }
        let inner = `
            <div class="col-lg-3 col-md-6 col-sm-6" id="friends-item">
                <div class="friend-block">
                    <div class="more-opotnz">
                        <i class="fa fa-ellipsis-h"></i>
                        <ul data-item="spa${user.user.id}dli">
                            ${tabs}
                        </ul>
                    </div>
                    <figure>
                        <img style="height: 84px; width: 84px"
                        src="${isNull(user.user.small_profile) ? 'https://place-hold.it/448x224': getURI() + '/banner/' + user.user.small_profile}" alt="">
                    </figure>
                    <div class="frnd-meta">
                        <div class="frnd-name">
                            <a href="${getURI()}/friend-profile-about?id=${user.user.token}" style="cursor: pointer;" title="">
                            <span class="d-block">${(user.user.last_name + ' ' + user.user.first_name).length > 10 ? ucfirst(user.user.last_name + ' ' + user.user.first_name).slice(0, 11) + ' ...' : ucfirst(user.user.last_name + ' ' + user.user.first_name)}</span>
                            <small class="d-block">${ucfirst(user.user.city)}, ${user.user.country}</small>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        `;
        template.push(DomBuilder(inner).querySelector('#friends-item'));
    });

    return template;
}

/**
 * Build the DOM who display the friend list
 * @param {{}} data
 * @returns
 */
function dataFactory2(data) {
    /**
     * @type {Array<HTMLDivElement>}
     */
    let template = [],
    length = Object.values(data.results).length;

    document.querySelector('#result-count').textContent = length;
    document.querySelector('#total-result').textContent = length;
    Object.values(data.results)
        .forEach((user, index) => {
        const date = new Date(user.info.since);
        let inner = `
            <div class="col-lg-3 col-md-6 col-sm-6">
                <form id="friend-profile" method="get" action="${getURI()}friend-profile" enctype="multipart/form-data">
                    <div class="friend-box">
                        <figure>
                            <img style="height: 149px; width: 297px"
                            src="${isNull(user.user.cover_profile) ? 'https://place-hold.it/448x224' : getURI() + '/banner/' + user.user.cover_profile}" alt="">
                            <span>Followers: ${user.follow.follower}</span>
                        </figure>
                        <div class="frnd-meta">
                            <img style="height: 84px; width: 84px"
                            src="${isNull(user.user.small_profile) ? 'https://place-hold.it/84' : getURI() + '/banner/' + user.user.small_profile}" alt="">
                            <div class="frnd-name">
                                <a href="${getURI()}/friend-profile-about?id=${user.user.token}" style="cursor: pointer;" title="">
                                    <span class="d-block">${(user.user.last_name + ' ' + user.user.first_name).length > 10 ? ucfirst(user.user.last_name + ' ' + user.user.first_name).slice(0, 11) + ' ...' : ucfirst(user.user.last_name + ' ' + user.user.first_name)}</span>
                                    <small class="d-block">${ucfirst(user.user.city)}, ${user.user.country}</small>
                                </a>
                            </div>
                            <ul class="frnd-info">
                                <li><span>Friends:</span> ${user.info.friends} (${user.info.mutualFriends} mutule friends)</li>
                                <li><span>Videos:</span> ${user.info.videos}</li>
                                <li><span>Photos:</span> ${user.info.photos}</li>
                                <li><span>Post:</span> ${user.info.posts}</li>
                                <li><span>Since:</span> ${date.getFullYear()} - ${date.getMonth() +1 < 10 ? '0' + (date.getMonth() +1).toString() : date.getMonth() +1} - ${date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate()}</li>
                            </ul>
                            <div class="more-opotnz">
                                <i class="fa fa-ellipsis-h"></i>
                                <ul data-item="spa${user.user.id}dli">
                                    <li><a href="javascript: void(0)" class="d-block" id="${user.option.private_state == 'blocked' ? 'unblock' : 'block'}-friend" aria-describedby="">${user.option.private_state == 'blocked' ? 'UnBlock' : 'Block'}</a></li>
                                    <li><a href="javascript: void(0)" class="d-block" id="${user.option.notification == 'unmuted' ? 'mute' : 'unmute'}-friend" aria-describedby="">${user.option.notification == 'unmuted' ? 'Mute Notifications' : 'Unmute Notifications'}</a></li>
                                    <li><a href="javascript: void(0)" class="d-block" id="${user.option.display_state == 'show' ? 'hide' : 'show'}-friend" aria-describedby="">${user.option.display_state == 'show' ? 'Hide from friend list' : 'Show from friend list'}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        `;
        template.push(DomBuilder(inner).querySelector('div.col-lg-3'));
    });

    return template;
}
