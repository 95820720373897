import EmojiPicker from "emoji-picker-react";
import { isEqualWith, isNull } from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import { getURI } from "../../../chat/page_actions_main";
import { getMyMediaComments, saveComment } from "../API/mediaComments";

class SliderMediaComment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            _id: 0,
            comments: [],
            comment: "",
            emoji_is_visible: false
        }

        /**
         * @type {HTMLTextAreaElement}
         */
        this.input = null
        /**
         * @type {'photo'|'video'}
         */
        this.media_type = "photo"
        this.commentUlRef = React.createRef()
    }

    componentDidMount = () => this._listenner()

    _listenner() {
        /**
         * @type {Element}
         */
        let sliderIsPhotoOrVideo = this.commentUlRef.current;
        do {
            sliderIsPhotoOrVideo = sliderIsPhotoOrVideo.parentElement
        } while (!sliderIsPhotoOrVideo.classList.contains('slider'))
        this.media_type = sliderIsPhotoOrVideo.classList.contains('photo') ? 'photo' : 'video'
        this.input = document.querySelector(`.slider.${this.media_type} #comment-input`)
        
        const photoSliderSelector = ['searchPhoto', 'searchVideo', 'photos-list', 'videos-list']
        let element = photoSliderSelector.forEach(item => {

        })
        /**
         * @type {Array<Array<HTMLLinkElement>>}
         */
        let elements = []
        photoSliderSelector.forEach(item => {
            if (item.toLowerCase().match(this.media_type)) {
                elements.push([
                    ...Array.from(document.querySelectorAll(`#${item} a[data-strip-group]`)),
                    document.querySelector(`div.slider.${this.media_type} > .slider-list a.prev`),
                    document.querySelector(`div.slider.${this.media_type} > .slider-list a.next`)
                ])
                if (elements[elements.length - 1].length == 2) elements.pop()
            }
        })
        elements.forEach(link => link.forEach(item => item.addEventListener('click', this._checkId, false)))
    }

    _checkId = () => {
        const _id = parseInt(this.commentUlRef.current.parentElement.getAttribute('data-media') || 0);

        if (_id == this.state._id || _id <= 0) return;
        this.setState({ ...this.state, ...{ _id: _id } })
        this._loadComments()
    }

    async _loadComments() {
        let comments = await getMyMediaComments(this.state._id, this.media_type)
        if (!('data' in comments)) return
        this.setState({ ...this.state, ...{ comments: comments.data.reverse() } })
    }

    /**
     * @param {Event} e 
     */
    _changeText = (e) => {
        this.setState({ ...this.state, ...{ comment: e.target.value } })
    }

    /**
     * @param {Event} e 
     */
    _changePicker = (e, emojiObject) => {
        this.setState({ ...this.state, ...{ comment: this.state.comment + emojiObject.emoji } })
        this.input.focus()
        this.input.selectionEnd
    }

    /**
     * @param {Event} e 
     */
    _toggleEmojiVisibility = (e) => {
        e.preventDefault()
        this.setState({ ...this.state, ...{ emoji_is_visible: !this.state.emoji_is_visible } })
    }

    _submitComment = async () => {
        let response = await saveComment(this.state._id, this.media_type, this.state.comment)
        if (response.code == 418) return
        this.setState({ ...this.state, ...{ comment: '' } })
        this._loadComments()
    }

    render = () => (
        <React.Fragment>
            <h4 className="text-center text-ligth">Post your comments</h4>
            <ul className="my-4 p-0" ref={this.commentUlRef}>
                {
                    this.state.comments.map((comment, index) => (
                        <li key={index} className="d-flex">
                            <div>
                                <img
                                    src={`${isNull(comment.user.small_profile) ? 'https://place-hold.it/50' : `${getURI()}/banner/${comment.user.small_profile}`}`}
                                    alt="User profile image"
                                    className="ml-3"
                                    style={{ borderRadius: '25px', width: '50px', height: '50px' }} />
                            </div>
                            <div style={{flex: 1, margin: '0px 1rem'}}>
                                <strong className="text-light d-block text-capitalize">{comment.user.last_name} {comment.user.first_name}</strong>
                                <p className="text-light m-0">{comment.media.coment}</p>
                                <small className="d-block text-right text-muted">Posted on {comment.media.created_at.split(' ')[0].split('-').reverse().join('/')} at {comment.media.created_at.split(' ')[1].split(':').slice(0, 2).join(':')}</small>
                            </div>
                        </li>
                    ))
                }
            </ul>
            <div className="container">
                <div className="row">
                    <div className={`col-12 emoji-zone ${this.state.emoji_is_visible ? 'show' : 'hide'}`}>
                        <textarea
                            placeholder="Type your commemt here ..."
                            className="form-control form-control-sm"
                            onChange={this._changeText}
                            style={{fontSize: '16px'}}
                            name="comment"
                            value={this.state.comment}
                            rows={3}
                            id="comment-input">
                        </textarea>
                        <a href="/"
                            onClick={this._toggleEmojiVisibility}
                            style={{position: 'absolute', top: '10px', right: '25px'}}>
                            <i className="fa fa-grin text-dark" style={{fontSize: '20px'}}></i>
                        </a>
                        <EmojiPicker onEmojiClick={this._changePicker}/>
                        <button className="btn btn-sm btn-warning btn-block mt-3" onClick={this._submitComment}>Post Comment</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

if (document.getElementById('slider-media-comment')) {
    document.querySelectorAll('#slider-media-comment').forEach(slider => ReactDOM.render(<SliderMediaComment />, slider))
}