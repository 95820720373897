import axios from 'axios';
import { isEmpty, isNull, isNumber, isString, isUndefined } from 'lodash';
import { goToDown, messageEventRenderer } from '.';
import { discussionList, textInputHoverView } from './credentials';
import { imageVideoInner, loaderMessage, messageDomSkeleton, otherFilesInner } from './messageTemplate';
const $ = require('jquery');

require('./page_action');

/**
 * Run soung when notify to some action
 * @param {'sms'|'AudioCall'|'VideoCall'} type (sms|AudioCall|VideoCall)
 * @param {boolean} repeat true if should be repeated
 */
export function runSoung(type, repeat) {
    type = !in_array(type, ['sms', 'AudioCall', 'VideoCall']) ? 'sms' : type;
    repeat = in_array(repeat, [true, false]) ? repeat : true;
    window[`${type.toLowerCase()}`].loop = repeat;
    window[`${type.toLowerCase()}`].play();
}
/**
 * Stop soung when notify to some action
 * @param {'sms'|'AudioCall'|'VideoCall'} type (sms|AudioCall|VideoCall)
 */
export function stopSoung(type) {
    type = !in_array(type, ['sms', 'AudioCall', 'VideoCall']) ? 'sms' : type;
    window[`${type.toLowerCase()}`].pause();
    window[`${type.toLowerCase()}`].currentTime = 0;
}
['sms', 'AudioCall', 'VideoCall'].forEach(type => {
    window[`${type.toLowerCase()}`] = document.createElement('audio');

    window[`${type.toLowerCase()}`].src = `${getURI()}/notification/${type}.mp3`;
    window[`${type.toLowerCase()}`].loop = true;
    document.body.appendChild(window[`${type.toLowerCase()}`]);
});

let discussion = {
        newuser: document.querySelector(`a[data-action="new-user"]`),
        newgroup: document.querySelector(`a[data-action="new-group"]`),
        newprivatechat: document.querySelector(`a[data-action="new-private-chat"]`)
    },
    bulle = {
        bulle: document.querySelector(`.bulle[data-action]`),
        bullecontainer: document.querySelector(`.bulle-content`),
        newuser: document.querySelector(`#new-user`),
        newgroup: document.querySelector(`#new-group`),
        newprivatechat: document.querySelector(`#new-private-chat`),
        // Actions
        // Friend Selection
        friendlist: document.querySelectorAll(`#friend-list tbody tr`),
        friendlistcheck: document.querySelectorAll(`#friend-list tbody td input[type="radio"]`),
        close: document.querySelectorAll('.bulle-closer-x'),
        content: {
            friends: `
                <table class="friend-list" id="friend-list">
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Madel admin</td>
                            <td>madeladmin@gmail.com</td>
                            <td>
                                <input type="radio" name="friend">
                            </td>
                        </tr>
                    </tbody>
                </table>`
        }
    };

// Actions
// Select friend for new discussion
if (bulle.friendlist) {
    bulle.friendlist.forEach((elem, index) => {
        elem.onclick = () => {
            bulle.friendlistcheck[index].click();
            if (bulle.friendlistcheck[index].checked) {
                bulle.friendlist.forEach((item, key) => {
                    if (key != index) {
                        item.style.backgroundColor = '#fff';
                    }
                })
                elem.style.backgroundColor = 'rgb(230, 230, 230)';
            }
        }
    })
}
// Close bulle
bulle.close.forEach((elem) => {
        elem.onclick = () => {
            if (bulle.bulle.classList.contains('bulle-show')) {
                setTimeout(() => {
                    bulle.bulle.classList.replace('bulle-show', 'bulle-hide');
                }, 500);
                switch (bulle.bullecontainer.classList.contains('bulle-hider')) {
                    case true:
                        bulle.bullecontainer.classList.replace('bulle-hider', 'bulle-shower');
                        break;
                    default:
                        bulle.bullecontainer.classList.replace('bulle-shower', 'bulle-hider');
                        break;
                }
            }
        }
    })
    // Show Add friend bulle
if (discussion.newuser) {
    discussion.newuser.onclick = () => {
        if (bulle.bulle.classList.contains('bulle-hide')) {
            setTimeout(() => {
                bulle.bulle.classList.replace('bulle-hide', 'bulle-show');
            }, 500);
            switch (bulle.bullecontainer.classList.contains('bulle-shower')) {
                case true:
                    bulle.bullecontainer.classList.replace('bulle-shower', 'bulle-hider');
                    break;
                default:
                    bulle.bullecontainer.classList.replace('bulle-hider', 'bulle-shower');
                    break;
            }
        }
    }
}

/**
 * Function display message in message list
 * @param {{ token: string; message: string; disc: number; user: string; corr: number; URI: string; file: [{ file: string | null; data: { ext: string; name: string; size: (number|string)[] }; }]; isMixed: boolean; form: { type: 'new-message'|'old-message'; discussion_id: number; message_id: number; user: number; } }} setting
 * @param {{ message: string; file_name: string[]; date: string; message_id: number; }} response
 */
export function displayData(setting, response) {
    if (setting.form.type == 'old-message') {
        document.querySelectorAll('ul#message-list-item')[getElementIndex(setting.form.discussion_id)].querySelector(`li[data-message="${setting.form.message_id}"] div.text-box > p`).textContent = setting.message;
        messageEventRenderer();
        goToDown(getElementIndex(location.href.match('/messenger') ? getElementIndex(setting.disc) : 0));
        return true;
    }

    let logoUser = setting.user == window.id ?
        document.querySelector('div#logo-user-smaller img').getAttribute('src')
        :
        isNull(window.itxyxec.discussions[setting.disc].user.small_profile) ? 'https://place-hold.it/45' : `${getURI()}/banner/${window.itxyxec.discussions[setting.disc].user.small_profile}`;

    let loader = loaderMessage(),
        /**
         * @type {HTMLDivElement}
         */
        message_dom = messageDomSkeleton(
            logoUser,
            setting.message,
            response.date,
            'you',
            response.message_id
        ),
        /**
         * @type {HTMLDivElement[]}
         */
        file_dom = [];
    if (setting.isMixed) { //   Attachments is present
        setting.file.forEach((file, index) => {
            if (in_array(file.data.ext.toLowerCase(), ['jpeg', 'jpg', 'png', 'mp4', '3gp'])) { // images | videos
                file_dom.push(imageVideoInner(file.data.ext, response.file_name[index]));
            }
            if (in_array(file.data.ext.toLowerCase(), ['mp3', 'txt', 'pdf', 'zip'])) { // documents
                const file_name = setting.file[index].data.name.replace('.' + file.data.ext, '').slice(0, 19) + '...' + file.data.ext;
                file_dom.push(otherFilesInner({
                    final_name: response.file_name[index],
                    file_name: file_name,
                    ext: file.data.ext,
                    size: file.data.size
                }));
            }
        });
    }
    let index = getElementIndex(setting.disc),
        el = document.querySelectorAll('ul#message-list-item')[location.href.match('/messenger') ? index : 0];
    document.querySelectorAll('ul#message-list-item')[location.href.match('/messenger') ? index : 0].insertBefore(message_dom, document.querySelectorAll('ul#message-list-item li#onWriting')[location.href.match('/messenger') ? index : 0]);
    file_dom.forEach(dom => message_dom.querySelector('div.text-box div.galerie').appendChild(dom));
    messageEventRenderer();
    setTimeout(() => {
        goToDown(location.href.match('/messenger') ? index : 0);
    }, 1000);
    return true;
}

require('./chat-main_page');

/**
 * Get index of any discussion
 * @param {number} discussion_id
 * @returns {number}
 */
export function getElementIndex(discussion_id) {
    let index = -1;
    discussionList.forEach((li, key) => {
        let discuc_id = 0;
        if (li.getAttribute('data-state')) {
            discuc_id = li.getAttribute('data-item').split(',').map(item => parseInt(item.match(/[0-9]+/)[0]))[0];
        } else {
            discuc_id = parseInt(li.getAttribute('data-item').match(/[0-9]+/)[0]);
        }
        if (discuc_id == discussion_id) {
            index = key;
        }
    });
    return index;
}

/**
 * Get the last item
 * @param {[]|{}} list
 * @returns {any}
 */
export function lastItem(list) {
    return Object.values(list)[Object.values(list).length - 1];
}

/**
 * Check if is in arrayList
 * @param {any} item
 * @param {[]|{}} array
 * @returns
 */
export function in_array(item, array) {
    if (isUndefined(item) || isNull(item)) return false;
    return Object.values(isUndefined(array) || isNull(array) ? [] : array).includes(item, 0);
}

/**
 * Capitilize the first letter of a string
 * @param {string} value
 * @returns {string}
 */
export function ucfirst(value) {
    return value.charAt(0).toUpperCase() + value.slice(1, value.length);
}

/**
 * Get size in Ko, Mo
 * @param {number} size
 */
export function getSize(size) {
    let Ko = size / 1024,
        Mo = size / (1024 * 1024);
    if (parseInt(Mo) > 0) {
        return ['Mo', parseFloat(Mo.toString().match(/^[0-9]{1,}\.[0-9]{2}/))];
    }
    if (parseInt(Ko) > 0) {
        return ['Kb', parseFloat(Ko.toString().match(/^[0-9]{1,}\.[0-9]{2}/))];
    }
    return ['b', size];
}

/**
 * Get Origin domain URI
 * @returns {string}
 */
export function getURI() {
    return window.location.origin;
}

/**
 * Convert string to DOM Element
 * @param {string} htmlContent
 * @returns
 */
export function DomBuilder(htmlContent) {
    return (isString(htmlContent) && !isEmpty(htmlContent)) ?
        new DOMParser().parseFromString(htmlContent, 'text/html').querySelector('body') :
        new DOMParser().parseFromString('<h3>Nothing to display</h3>', 'text/html').querySelector('body');
}

/**
 * Get the type of the file
 * @param {string} extension
 */
export function fileType(extension) {
    let documentType = '';
    switch (extension) {
        case 'mp3':
            documentType = 'Audio'
            break;
        case 'txt':
            documentType = 'Text file'
            break;
        case 'pdf':
            documentType = 'Document'
            break;
        case 'zip':
            documentType = 'Archive'
            break;

        default:
            break;
    }
    return documentType;
}

/**
 * Format number
 * @param {number} length
 * @returns
 */
export function numberFactory(length) {
    if (length < 1000) {
        return length.toString();
    } else if (length > 999 && length < 1000000) {
        return (length / 1000).toString() + 'K';
    } else {
        length = (length / 1000000).toString().split('.').map((item, index) => index > 0 ? item.slice(0, 2) : item)
        length = length.length > 1 ? length[0] + (parseInt(length[1]) > 0 ? '.' + length[1] : '') : length[0];
        return length + 'M';
    }
}

/**
 * Get date format yyyy/mm/dd hh:ii
 * @param {number | Date} time
 */
export function dateFormat(time) {
    /**
     * @type {Date}
     */
    let date;
    if (typeof time == 'number') {
        date = new Date(time);
    } else {
        date = time;
    }
    let year = date.getFullYear(),
        month = date.getHours() < 10 ? (0).toString() + date.getHours().toString() : date.getHours(),
        day = date.getDate() < 10 ? (0).toString() + date.getDate().toString() : date.getDate(),
        hour = date.getHours() < 10 ? (0).toString() + date.getHours().toString() : date.getHours(),
        minute = date.getMinutes() < 10 ? (0).toString() + date.getMinutes().toString() : date.getMinutes();

    return `${year}/${month}/${day} ${hour}:${minute}`
}

/**
 * Get the size of any array
 * @param { {} | any[] } tab
 * @returns { number } size
 */
export function getLength(tab) {
    let iter = 0;
    for (const index in tab) {
        if (index && typeof tab[index] !== 'undefined') {
            iter++;
        }
    }
    return iter
}

/**
 * @param {number} messageIndex
 * @param {number} discussion_id
 */
export function editBtn(messageIndex, discussion_id) {
    const message_id = parseInt(document.querySelectorAll('ul#message-list-item li')[messageIndex].getAttribute('data-message') || 0),
        textMessage = document.querySelectorAll('ul#message-list-item li')[messageIndex].querySelector('div.text-box > p').textContent;
    if (message_id == 0 || document.querySelectorAll('ul#message-list-item li')[messageIndex].classList.contains('you')) {
        return;
    }
    /**
     * @type {HTMLFormElement}
     */
    let form = document.querySelectorAll('form#form-msg')[getElementIndex(discussion_id)];
    form.querySelector('textarea#text-input').value = textMessage;
    textInputHoverView[getElementIndex(discussion_id)].textContent = textMessage;
    form.setAttribute('data-action', JSON.stringify({
        type: 'old-message',
        discussion_id: discussion_id,
        message_id: message_id,
        user: window.id,
    }));
}

/**
 * @param {number} messageIndex
 * @param {number} discussion_id
 * @returns {Promise<{ deleted: boolean; message_id: number; }>}
 */
export async function deleteBtn(messageIndex) {
    const message_id = parseInt(document.querySelectorAll('ul#message-list-item li')[messageIndex].getAttribute('data-message') || 0);
    try {
        const textMessage = document.querySelectorAll('ul#message-list-item li')[messageIndex].querySelector('div.text-box > p').textContent;
        if (message_id == 0 || document.querySelectorAll('ul#message-list-item li')[messageIndex].classList.contains('you')) {
            return {
                deleted: false,
                message_id: message_id
            };
        }
        return new Promise(resolve => {
            axios({
                url: getURI() + '/api/delete/message',
                method: 'POST',
                data: {
                    message_id: message_id,
                    user_id: window.id
                }
            }).then(response => {
                if (response.data.code == 200) {
                    resolve({
                        deleted: true,
                        message_id: message_id
                    });
                }
            })
        });
    } catch (error) {
        return new Promise(resolve => {
            resolve({
                deleted: false,
                message_id: message_id
            });
        });
    }
}

/**
 * Check if form data-action attribute have good value
 * @param {string} action
 * @returns { { type: 'new-message'|'old-message'; discussion_id: number; message_id: number; user: number; } }
 */
export function checkFormActionCredentials(action) {
    action = JSON.parse(action);
    let newData = {};

    newData.type = (!('type' in action) || !in_array(action.type, ['new-message', 'old-message'])) ? 'new-message' : action.type;
    newData.discussion_id = (!('discussion_id' in action) || !isNumber(action.discussion_id) || action.discussion_id <= 0) ? 0 : action.discussion_id;
    newData.message_id = (!('message_id' in action) || !isNumber(action.message_id) || action.message_id <= 0) ? 0 : action.message_id;
    newData.user = (!('user' in action) || !isNumber(action.user) || action.user <= 0) ? 0 : action.user;

    return newData;
}
