import { popupCloseBtn } from "../photo/credentials"
import { DomBuilder, getSize, getURI, in_array } from '../../chat/page_actions_main'
import { Alert, displayAlert } from '../alert'
import { isEmpty, isNull, isNumber, isString } from "lodash";
import { progressbarBuilder } from "../../chat/elementBuilder";
import { commenting } from "./coments";
import axios from "axios";

const uploadFormVideo = document.querySelector('form#postVideo')

/**
 * @param {number} time waitting timeout in millisecond
 * @returns {Promise<boolean>}
 */
export async function sleep(time) {
    return await new Promise(resolve => {
        setTimeout(() => {
            resolve(true)
        }, time);
    })
}

/**
 * Actions for video page
 */
function videoPage() {
    const videoSliderSelector = ['searchVideo', 'videos-list'];
    /**
     * @type {Array<File>}
     */
    let files = [],
    videos = [],
    totalVideo = document.querySelector(videoSliderSelector.map(selector => `#${selector}`).join(', ')).querySelectorAll('a[data-strip-group]').length,
    currentMediaIndex = 0,
    getVideoCredentials = () => {
        let image = document.querySelectorAll(videoSliderSelector.map(selector => `#${selector} a[data-strip-group]`).join(', '))[currentMediaIndex];
        return !image ? {
            title: '',
            description: '',
            id: 0
        } : {
            title: image.getAttribute('data-title') || '',
            description: image.getAttribute('data-description') || '',
            id: parseInt(image.getAttribute('id') || '0')
        }
    },
    displayMediaDetail = () => {
        totalVideo = document.querySelector(videoSliderSelector.map(selector => `#${selector}`).join(', ')).querySelectorAll('a[data-strip-group]').length;
        let link = document.querySelectorAll(videoSliderSelector.map(selector => `#${selector} a[data-strip-group]`).join(', '))[currentMediaIndex];
        let imageCredentials = getVideoCredentials(),
            /**
             * @type {Element}
             */
            slider = document.querySelector('div.slider.video');
            
        document.querySelector('.slider.video #slider-media-comment').setAttribute('data-media', imageCredentials.id);
        if (!slider) return

        let view, like, coment, share;

        if (document.querySelector(`#${videoSliderSelector[1]}`)) {
            const data = link.hasAttribute('data-media') ? JSON.parse(link.getAttribute('data-media')) : null;
            if (isNull(data)) return
            view = data['view']
            like = data['like']
            coment = data['coment']
            share = data['share']
        } else {
            view = link.parentElement.querySelector('div.over-photo i.fa-eye + sup').textContent
            like = link.parentElement.querySelector('div.over-photo i.fa-heart + sup').textContent
            coment = link.parentElement.querySelector('div.over-photo i.fa-commenting + sup').textContent
            share = link.parentElement.querySelector('div.over-photo i.fa-share + sup').textContent
        }

        if (imageCredentials.title.length == 0) {
            slider.querySelector('.slider-title .contain').classList.add('d-none')
        } else {
            slider.querySelector('.slider-title .contain').classList.remove('d-none')
            slider.querySelector('.slider-title p').textContent = imageCredentials.title
        }
        if (imageCredentials.description.length == 0) {
            slider.querySelector('.slider-description .contain').classList.add('d-none')
        } else {
            slider.querySelector('.slider-description .contain').classList.remove('d-none')
            slider.querySelector('.slider-description p').textContent = imageCredentials.description
        }

        slider.querySelector('video').setAttribute('src', link.querySelector('video').getAttribute('src'))
        
        //  Display media detail
        let footerDetail = DomBuilder(`
                <div>
                    <span><i class="fa fa-eye"></i> <sup class="text-light">${view}</sup></span>
                    <span><i class="fa fa-heart text-danger"></i> <sup class="text-light">${like}</sup></span>
                    <span><i class="fa fa-commenting"></i> <sup class="text-light">${coment}</sup></span>
                    <span><i class="fa fa-share"></i> <sup class="text-light">${share}</sup></span>
                </div>
                <div>
                    <span>${currentMediaIndex + 1} / ${totalVideo}</span>
                </div>
            `).children;
        
        slider.querySelector('div.media-data').innerHTML = ''
        slider.querySelector('div.media-data').prepend(footerDetail[1])
        slider.querySelector('div.media-data').prepend(footerDetail[0])
    };

    document.body.addEventListener('click', e => {
        if (e.target.classList.contains('slider-overlay') || e.target.classList.contains('close-btn')) {
            document.querySelector('div.slider.video').classList.add('hided')
            document.querySelector('div.slider.video').querySelector('video').setAttribute('src', null)
            setTimeout(() => {
                document.querySelector('div.slider.video').classList.add('d-none')
            }, 500);
            document.querySelector('div.slider.video').parentElement.classList.add('d-none')
        }
    }, false)
    // When we change file
    if (document.querySelector('form#postImage input#chooseFile, form#postVideo input#chooseFile')) {
        document.querySelector('form#postImage input#chooseFile, form#postVideo input#chooseFile').addEventListener('change', e => {
            if (document.querySelector('form#postImage input#chooseFile, form#postVideo input#chooseFile').getAttribute('accept') !== '.mp4,.3gp,.ogg') return
            files = Array.from(e.target.files);
            let _filesCredentials = {
                names: files.map(item => item.name),
                sizes: files.map(item => getSize(item.size)),
                extensions: files.map(item => item.name.split('.')[item.name.split('.').length - 1])
            };
    
            for (const index in _filesCredentials.extensions) { //  Check files information validity
                // Only accept ['mp4', '3gp', 'ogg'] and max size 30Mb
                if (!in_array(_filesCredentials.extensions[index], ['mp4', '3gp', 'ogg']) || (_filesCredentials.sizes[index][0].toLowerCase() == 'mo' && _filesCredentials.sizes[index][1] > 30)) {
                    Object.keys(_filesCredentials).forEach(key => {
                        _filesCredentials[key].splice(index, 1)
                    });
                    let msg = Alert('danger', `Video ${files[index].name} most be one of this format "${['mp4', '3gp', 'ogg'].join(' ')}" or less than 30Mo`);
                    document.body.appendChild(msg);
                    setTimeout(() => {
                        document.querySelector('button#alert-btn').click();
                        setTimeout(() => {
                            msg.remove();
                        }, 3000);
                    }, 15000);
                    files.splice(index, 1);
                }
            }
            e.target.value = '';
        }, false);
    
        if (uploadFormVideo.querySelector('button[type="button"]')) {
            uploadFormVideo.querySelector('button[type="button"]').addEventListener('click', async () => {
                let form = {
                    /**
                     * @type {string}
                     */
                    title: uploadFormVideo.querySelector('#title').value,
                    /**
                     * @type {string}
                     */
                    description: uploadFormVideo.querySelector('#description').value,
                    /**
                     * @type {string}
                     */
                    private_state: uploadFormVideo.querySelector('#private_state').value,
                    /**
                     * @type {string}
                     */
                    mute: uploadFormVideo.querySelector('#mute').value,
                };
    
                if (!('title' in form) || !('description' in form) || !('private_state' in form) || !('mute' in form)) {
                    displayAlert('danger', 'A form field is missing');
                    return;
                }
    
                if (form.title.length > 90) {
                    displayAlert('danger', 'The title is too long');
                    return;
                }
    
                if (form.description.length > 250) {
                    displayAlert('danger', 'The description is too long');
                    return;
                }
    
                if (isEmpty(files)) {
                    displayAlert('danger', 'No image has been selected');
                    return;
                }
    
                if (!in_array(form.private_state, ['public', 'private'])) {
                    displayAlert('danger', 'The choice of visibility is invalid');
                    return;
                }
    
                if (!in_array(form.mute, ["muted", "unmuted"])) {
                    displayAlert('danger', 'The choice of notification visibility is invalid');
                    return;
                }
    
                uploadFormVideo.querySelector('button[name="submit"]').classList.add('disabled');
                (() => {
                    const TIME_INTERVALL_SEND = 2000; //    2s to wait before sending the next request
                    return new Promise((resolve, reject) => {
                        files.forEach((file) => {
                            /**
                             * @type {Promise<string|Error>}
                             */
                            (new Promise((resolve, reject) => {
                                let xhr = new XMLHttpRequest(),
                                    data = new FormData(),
                                    progressbar = progressbarBuilder();
            
                                if ('append' in document.querySelector('#progressbar-container')) {
                                    document.querySelector('#progressbar-container').append(progressbar);
                                } else {
                                    document.querySelector('#progressbar-container').appendChild(progressbar);
                                }
                                progressbar.querySelector('#progressbar-container #progessbar-title').textContent = file.name;
            
                                data.append('user_id', window.id);
                                data.append('file', file);
                                data.append('title', form.title);
                                data.append('description', form.description);
                                data.append('private_state', form.private_state);
                                data.append('mute', form.mute);
            
                                xhr.open('POST', getURI() + '/api/postVideo');
                                xhr.responseType = 'json';
            
                                // Upload progressbar
                                xhr.upload.addEventListener('progress', e => {
                                    let data = {
                                        loaded: 0,
                                        total: 0,
                                        percent: 0 // %
                                    };
            
                                    if (e.lengthComputable) {
                                        data.loaded = e.loaded;
                                        data.total = e.total;
                                        data.percent = Math.ceil((data.loaded / data.total) * 100);
            
                                        progressbar.querySelector('#progress-inner').setAttribute('style', `width: ${data.percent}%;`);
                                        progressbar.querySelector('#progress-inner').setAttribute('data-current', data.percent);
                                        progressbar.querySelector('#progress-inner').firstElementChild.textContent = `${data.percent}%`;
            
                                        if (data.percent == 100) {
                                            progressbar.querySelector('#progress-inner').classList.value = 'progress-inner bg-success';
                                            progressbar.querySelector('#progress-inner').firstElementChild.classList.value = 'text-light';
                                        }
                                    }
                                }, false);
            
                                xhr.addEventListener('readystatechange', () => {
                                    if (xhr.DONE && xhr.readyState == 4) {
                                        const response = xhr.response;
                                        try {
                                            if ('response' in response) {
                                                videos.push(response.response.file_image) // Video information
                                                resolve(`File: ${file.name} uploaded successfully`);
                                            } else {
                                                reject({
                                                    file_name: file.name,
                                                    errors: response.errors
                                                });
                                            }
                                        } catch (error) {
                                            reject({
                                                file_name: file.name,
                                                errors: ['Server code error 500. This error come from server side']
                                            });
                                        }
                                    }
                                }, false);
                                xhr.onerror = (e) => {
                                    reject({
                                        file_name: file.name,
                                        errors: ['Error occured whe sending data']
                                    });
                                }
                                setTimeout(() => {
                                    xhr.send(data);
                                }, TIME_INTERVALL_SEND);
                            })).then(() => {
                                //    success
                                /**
                                 * @type {HTMLDivElement[]}
                                 */
                                let container = document.querySelector('#searchVideo'), //  Video container
                                video = videos[videos.length - 1],
                                children = container.children,
                                newDomChild = DomBuilder(`
                                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-6 d-flex align-items-center">
                                        <div class="item-box">
                                            <a href="javascript: void(0)"
                                                data-href="${ getURI() + '/banner/uploads/video/' + video.name }"
                                                id="${ video.id }"
                                                data-title="${ isNull(video.title) ? '' : video.title }"
                                                data-description="${ isNull(video.description) ? '' : video.description }"
                                                data-strip-group="mygroup"
                                                data-strip-options="width: 700,height: 450,youtube: { autoplay: 0 }">
                                                <video src="${ getURI() + '/banner/uploads/video/' + video.name }" class="w-100 h-100" style="margin: 0px; border-radius: .4rem;"></video>
                                                <i>
                                                    <svg version="1.1" class="play" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="50px" width="50px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                                                        <path class="stroke-solid" fill="none" stroke="" d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
                                                            C97.3,23.7,75.7,2.3,49.9,2.5"></path>
                                                        <path class="icon" fill="" d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"></path>
                                                    </svg>
                                                </i>
                                            </a>
                                            <div class="over-photo d-flex justify-content-around align-items-center">
                                                <div class="more-opotnz" style="position: absolute; right: 10px; bottom: 31px;">
                                                    <i class="fa fa-ellipsis-h text-primary"></i>
                                                    <ul data-item="spa2dli" style="backdrop-filter: blur(1px); background: #ffffffab none repeat scroll 0 0;">
                                                        <li><a href="javascript: void(0)" class="d-block text-dark" id="mute-video" aria-describedby="">${ video.mute ? 'Unmute Notifications' : 'Mute Notifications' }</a></li>
                                                        <li><a href="javascript: void(0)" class="d-block text-dark" id="hide-video" aria-describedby="">${ video.private_state == 'public' ? 'Hide video' : 'Show video' }</a></li>
                                                    </ul>
                                                </div>
                                                <span><i class="fa fa-eye"></i> <sup class="text-light">0</sup></span>
                                                <span><i class="fa fa-heart text-danger"></i> <sup class="text-light">0</sup></span>
                                                <span><i class="fa fa-commenting"></i> <sup class="text-light">0</sup></span>
                                                <span><i class="fa fa-share"></i> <sup class="text-light">0</sup></span>
                                            </div>
                                        </div>
                                    </div>
                                `).children[0];
                                
                                if (children.length > 1) {
                                    container.insertBefore(newDomChild, children[1])
                                } else {
                                    if ('append' in container) {
                                        container.append(newDomChild)
                                    } else {
                                        container.appendChild(newDomChild)
                                    }
                                }
                                if (files.indexOf(file) == (files.length - 1)) {
                                    resolve(200)
                                }
                            }).catch(err => {
                                reject({
                                    err,
                                    file
                                })
                            });
                        });
                    })
                })().then(() => {
                    displayAlert('success', `Your image${files.length > 1 ? 's' : ''} uploaded successfully !!!`);
                    popupCloseBtn.click();
                    Array.from(document.querySelector('#progressbar-container').children).forEach(progress => progress.remove());
                    files = [];
                    videos = [];
                    uploadFormVideo.reset();
                    uploadFormVideo.querySelector('button[name="submit"]').classList.remove('disabled')
                    videoPage();
                    document.querySelector('span#title-max').textContent = ''
                    document.querySelector('span#description-max').textContent = ''
                    document.querySelector('div#private_state_chosen > .chosen-single > span').textContent = 'Make your choice'
                    document.querySelector('div#mute_chosen > .chosen-single > span').textContent = 'Make your choice'
                }).catch(state => {
                    displayAlert('danger', `Something when wrong during uploading ${'file' in state ? state.file.name : ''}. ${'errors' in state.err ? state.err.errors[0] : state.err.message}`);
                    uploadFormVideo.querySelector('button[name="submit"]').classList.remove('disabled')
                })
            }, false);
        }
        
        // Mute notification
        document.querySelectorAll('#searchVideo > div #mute-video').forEach((link, index) => {
            link.addEventListener('click', async () => {
                const id = parseInt(document.querySelectorAll('#searchVideo > div a[data-strip-group]')[index].getAttribute('id') || '0');
                if (id <= 0) return;
                /**
                 * @type {{ code: 200|404; message: string; current_state: 'mute'|'unmute' }}
                 */
                let response = await axios({
                    url: getURI() + '/api/toggle-media-state',
                    method: 'POST',
                    data: {
                        user_id: window.id,
                        id: id,
                        media_type: 'video',
                    }
                }).then(response => response.data)
                if (response.code == 200) {
                    response.current_state = in_array(response.current_state, ['mute', 'unmute']) ? response.current_state : 'unmute'
                    link.textContent = response.current_state == 'mute' ? 'Mute Notifications' : 'Unmute Notifications'
                }
            }, false);
        });
    
        // Toggle media visibility
        document.querySelectorAll('#searchVideo > div #hide-video').forEach((link, index) => {
            link.addEventListener('click', async () => {
                const id = parseInt(document.querySelectorAll('#searchVideo > div a[data-strip-group]')[index].getAttribute('id') || 0);
                if (id <= 0) return;
                /**
                 * @type {{ code: 200|404; message: string; current_state: 'public'|'private' }}
                 */
                let response = await axios({
                    url: getURI() + '/api/toggle-media-visibility',
                    method: 'POST',
                    data: {
                        user_id: window.id,
                        id: id,
                        media_type: 'video',
                    }
                }).then(response => response.data)
                if (response.code == 200) {
                    response.current_state = in_array(response.current_state, ['public', 'private']) ? response.current_state : 'public'
                    link.textContent = response.current_state == 'public' ? 'Show video' : 'Hide video'
                }
            }, false);
        });
    }

    // Add media to view list
    document.querySelectorAll(videoSliderSelector.map(selector => `#${selector} a[data-strip-group]`).join(', ')).forEach((media, index) => {
        media.addEventListener('click', async () => {
            const id = parseInt(media.getAttribute('id') || '0');
            if (id <= 0) return;
            /**
             * @type {{ code: 200|404; message: string }}
             */
            axios({
                url: getURI() + '/api/setMediaView',
                method: 'POST',
                data: {
                    user_id: window.id,
                    id: id,
                    media_type: 'video',
                }
            }).then(response => {
                if (response.data.code == 200) {
                    let selector = ['#searchVideo > div div.over-photo .fa-eye + .text-light', `#${videoSliderSelector[1]}`],
                    eye = document.querySelectorAll(selector.join(', '));
                    if (isNull(eye)) return
                    eye[index].textContent = parseInt(eye[index].textContent) + 1;
                }
            })
            currentMediaIndex = index
            displayMediaDetail()
            document.querySelector('div.slider.video').classList.remove('d-none', 'hided')
            document.querySelector('div.slider.video').parentElement.classList.remove('d-none')
        }, false);
    });

    // Go preview media
    document.querySelector('div.slider.video > .slider-list a.prev').addEventListener('click', () => {
        currentMediaIndex--
        if (currentMediaIndex < 0) currentMediaIndex = (totalVideo - 1)
        displayMediaDetail();
    }, false);

    // Go next media
    document.querySelector('div.slider.video > .slider-list a.next').addEventListener('click', () => {
        currentMediaIndex++
        if (currentMediaIndex > (totalVideo - 1)) currentMediaIndex = 0
        displayMediaDetail();
    }, false);
}
if ((uploadFormVideo && uploadFormVideo.id == 'postVideo') || document.querySelector('#videos-list')) {
    videoPage();
    require('./coments')
}
if (document.querySelector('form#coment-form-video')) {
    require('./coments')
}

// Sort

let pagination = {
    page: 1,
    searchType: 'text',
    by: 'none',
    value: '',
    perPage: 10,
    user_id: window.zipusr,
};

/**
 * @param {string} by
 * @param {string} value
 * @param {string} type
 * @returns
 */
async function sortVideo(by, value, type) {
    let total_photo = parseInt(document.querySelector('#total-result').textContent);

    type = !in_array(type, ['text', 'sort_by', 'function', 'more']) ? 'text' : type
    by = !in_array(by, ['a_to_z', 'see_all', 'newest', 'oldest', 'hided', 'public', 'private', 'muted', 'unmuted', 'none']) ? 'none' : by
    value = !isString(value) || isEmpty(value) ? '' : value
    if (!isNumber(total_photo)) return;

    if (type !== 'more') {
        pagination = {
            ...pagination,
            ...{
                searchType: type,
                by: by,
                value: value,
                page: 1
            }
        }
    } else {
        pagination.page++;
    }

    let params = new URLSearchParams();
    params.append('filter', pagination.by);
    params.append('searchText', pagination.value);
    params.append('searchType', pagination.searchType);
    params.append('user_id', pagination.user_id);
    params.append('length', pagination.perPage);
    params.append('page', pagination.page);

    let data = await axios({
        method: 'GET',
        url:  `${getURI()}/api/sortVideo?${params.toString()}`
    }).then(data => data.data)
    
    document.querySelector('#total-result').textContent = data.response.pagination.total;
    if (data.code == 200) {
        if (type !== 'more') {
            Array.from(document.querySelector('#searchVideo').children).forEach((item, key) => {
                if (key > 0) item.remove()
            });
        }
        if (isNull(data.response.results)) return
        let users = dataFactory(data.response);
        data = data.response;
        pagination.page = data.pagination.current_page;


        users.forEach(user => document.querySelector('#searchVideo').append(user));
        document.querySelector('#result-count').textContent = data.pagination.to;
        document.querySelector('#slice-result').textContent = `1 - ${data.pagination.to}`
        videoPage()
    } else {
        document.querySelector('#result-count').textContent = 0;
        document.querySelector('#slice-result').textContent = `1 - 10`
        Array.from(document.querySelector('#searchVideo').children).forEach((item, key) => {
            item.remove();
        });
        videoPage()
    }
}

window.sortVideo = sortVideo;

/**
 * Build the DOM who display group list
 * @param {{}} data
 * @returns
 */
 function dataFactory(data) {
    /**
     * @type {Array<HTMLDivElement>}
     */
    let template = [];

    data.results.forEach((video) => {
        let inner = `
            <div class="col-lg-4 col-md-3 col-sm-6 col-xs-6 d-flex align-items-center">
                <div class="item-box">
                    <a href="javascript: void(0)"
                        id="${ video.video.id }"
                        data-href="${ getURI() + '/banner/uploads/video/' + video.video.name }" title=""
                        data-strip-group="mygroup"
                        data-title="${isNull(video.video.title) ? '' : video.video.title}"
                        data-description="${isNull(video.video.description) ? '' : video.video.description}"
                        data-strip-options="width: 700,height: 450,youtube: { autoplay: 0 }">
                        <video class="w-100 h-100" style="margin: 0px; border-radius: .4rem;">
                            <source src="${ getURI() + '/banner/uploads/video/' + video.video.name }" type="">
                        </video>
                        <i>
                            <svg version="1.1" class="play" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="50px" width="50px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                          <path class="stroke-solid" fill="none" stroke="" d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
                            C97.3,23.7,75.7,2.3,49.9,2.5"></path>
                          <path class="icon" fill="" d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"></path>
                            </svg>
                        </i>
                    </a>
                    <div class="over-photo d-flex justify-content-around align-items-center">
                        <div class="more-opotnz" style="position: absolute; right: 10px; bottom: 31px;">
                            <i class="fa fa-ellipsis-h text-primary"></i>
                            <ul data-item="spa2dli" style="backdrop-filter: blur(1px); background: #ffffffab none repeat scroll 0 0;">
                                <li><a href="javascript: void(0)" class="d-block text-dark" id="mute-video" aria-describedby="">${ video.video.mute == 'muted' ? 'Unmute Notifications' : 'Mute Notifications' }</a></li>
                                <li><a href="javascript: void(0)" class="d-block text-dark" id="hide-video" aria-describedby="">${ video.video.private_state == 'public' ? 'Hide photo' : 'Show photo' }</a></li>
                            </ul>
                        </div>
                        <span><i class="fa fa-eye"></i> <sup class="text-light">${video.option.view}</sup></span>
                        <span><i class="fa fa-heart text-danger"></i> <sup class="text-light">${video.option.like}</sup></span>
                        <a href="javascript: void(0)" class="btn rounded-circle p-0 comment-btn">
                            <span><i class="fa fa-commenting"></i> <sup class="text-light">${video.option.coment}</sup></span>
                        </a>
                        <span><i class="fa fa-share"></i> <sup class="text-light">${video.option.share}</sup></span>
                    </div>
                </div>
            </div>
        `;
        template.push(DomBuilder(inner).firstElementChild);
    });

    return template;
}