import { isArray, isString } from "lodash";


/**
* Apply components in all references
* @param {[]} nodes index list
* @param {string} component component
*/
export function render(nodes, component) {
    if (isArray(nodes) && isString(component)) {
        nodes.forEach(node => {
            if (document.querySelector(node)) document.querySelector(node).innerHTML = component;
        })
    }
}