import { MEDIA_URI, POST_MEDIA_URI } from '../constants/url'

/**
 * @param {number} media_id
 * @param {'photo'|'video'} media_type
 * @returns {Promise<{ code: 200|418; response?: []; data?: [] }>}  
 */
export async function getMyMediaComments(media_id, media_type) {
    let params = new URLSearchParams();
    params.append('user_id', window.id)
    params.append('media_id', media_id)
    params.append('media_type', media_type)

    const data = await fetch(`${MEDIA_URI + params.toString()}`, { method: 'POST' });
    return await data.json();
}

/**
 * @param {number} media_id 
 * @param {'photo'|'video'} media_type 
 * @param {string} comment 
 * @returns {{ code: 200|418, response: []|string }}
 */
export async function saveComment(media_id, media_type, comment) {
    let params = new URLSearchParams();
    params.append('user_id', window.id)
    params.append('id', media_id)
    params.append('media_type', media_type)
    params.append('contain', comment)
    
    const data = await fetch(`${POST_MEDIA_URI + params.toString()}`, { method: 'POST' });
    return await data.json();
}