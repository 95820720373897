const { default: axios } = require("axios"),
    $ = require("jquery"),
    Croppie = require("croppie");

$(function () {
    let bulle_content = document.querySelector('div.bulle-content#bulle-content');
    if (bulle_content) {
        bulle_content.classList.remove('d-none');
        bulle_content.parentElement.classList.remove('d-none');
    }

    $(".cover-picture").hide();
    let state = 0;
    $(".inputImage_personal").on("click", function () {
        $(".inputImage_personal").on("change", function (e) {
            $(".cover-picture").show();
            /**
             *  An error ocured after our first change image .
             * So we should reload page for second time
             * */
            if (state == 1) {
                window.location.reload();
            }
            window.imageCoppie = new Croppie(
                document.querySelector("#image_demo_personal"),
                {
                    enableExit: true,
                    viewport: {
                        width: 250,
                        height: 270,
                        type: "square", // circle
                    },
                    boundary: {
                        width: 300,
                        height: 300,
                    },
                }
            );
            // We keep image in global space
            window.imageCoppie.cover_picture = e.target.files[0];
            state = 1;

            var reader = new FileReader();
            reader.onload = function (event) {
                window.imageCoppie
                    .bind({
                        url: event.target.result,
                    })
                    .then(function () {
                        console.log("jQuery bind complete event");
                    });
            };
            reader.readAsDataURL(e.target.files[0]);
        });
    });
    
    let input = document.getElementById("change-picture"),  //  Profil image
        input2 = document.getElementById("inputImage_personalTwo"); //  Cover image
    try {
        if (input) {
            // Profil
            input.addEventListener(
                "click",
                (e) => {
                    console.log("System is running ...");
                    window.imageCoppie
                        .result({
                            type: "canvas",
                            size: "viewport",
                        })
                        /**
                         * @param {string} response image cropped
                         */
                        .then(function (response) {
                            axios({
                                method: "POST",
                                data: {
                                    user_id: window.id,
                                    image_profile: response,
                                },
                                url: window.personnalImage,
                            }).then((resp) => {
                                let data = resp.data,
                                    small =
                                    document.getElementById("small-pic");
                                if (data.response == "success") {
                                    $(".cover-picture").hide();
                                    window.localStorage.setItem(
                                        "profil",
                                        JSON.stringify(data)
                                    );
                                    small.src = `${window.banner}/${data.profil_image}`;
                                    document
                                        .querySelectorAll(".profile")
                                        .forEach((elem, index) => {
                                            elem.src = `${window.banner}/${data.profil_image}`;
                                            console.log(elem);
                                        });
                                        // We also change in header, the profil image
                                        let userLogo = document.querySelector('div.user-img > img');
                                        if (userLogo) {
                                            userLogo.src = `${window.banner}/${data.profil_image}`;
                                        }
                                } else {
                                    console.log(data);
                                }
                            });
                        }, false);
                },
                false
            );
        }
        if (input2) {
            // Cover
            input2.addEventListener(
                "change",
                (e) => {
                    let forms = new FormData();
                    forms.append("user_id", window.id);
                    forms.append("cover-picture", e.target.files[0]);
                    axios({
                        method: 'POST',
                        url: window.personnalImageTwo,
                        data: forms
                    }).then(resp => {
                        let data = resp.data,
                            cover = document.getElementById("cover-pic");
                        if (data.response == "success") {
                            $(".cover-picture").hide();
                            cover.src = `${window.banner}/${data.cover_image}`;
                        } else {
                            // console.error(data);
                        }
                    })
                },
                false
            );
        }
    } catch (error) {
        console.error(error);
    }
});
