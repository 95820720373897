

const data = {
    like: document.querySelector('ul#like-dislike a#like'),
    toggleLikeBtn: document.querySelectorAll('#photos-list .item-box .likes'),
    toggleLikeInner: document.querySelectorAll('#photos-list .item-box .likes > span'),
    toggleVideoLikeBtn: document.querySelectorAll('#videos-list .item-box .likes'),
    toggleVideoLikeInner: document.querySelectorAll('#videos-list .item-box .likes > span'),
    dislike: document.querySelector('ul#like-dislike a#dislike'),
    like_inner: document.querySelector('#like-inner'),
    like_inner_global: document.querySelector('#like-inner-global'),
    dislike_inner: document.querySelector('#dislike-inner'),
};

export default data;
