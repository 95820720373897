
const API_URL = window.location.origin + '/api'

export function travelRequest(url = '', method = 'GET', body = {}) {
    const baseUrl = `${API_URL}${url}`

    if (method === 'GET') {
        const urlParams = new URLSearchParams()
        Object.keys(body).forEach(key => urlParams.append(key, body[key]))
        return fetch(`${baseUrl}?${urlParams.toString()}`, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    } else {
        return fetch(baseUrl, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
    }
}

async function main() {
    // const response = await travelRequest('/airports', 'GET')
    // const data = await response.json()
    // console.log(data)
}

main()
