const { default: axios } = require('axios');
import { getURI } from './page_actions_main';


/**
 * Get messages
 * @returns {Promise<{ code: 200 | 404; discussions: { custom: {}, original: any[] }; messages: any[] } | { code: 404; messages: "" }>}
 */
export async function data() {
    let id = parseInt(window.id);

    if (!id || typeof id != 'number' || id <= 0) {
        return {
            code: 404,
            messages: "Identifiant introuvable"
        };
    }
    return await axios({
        url: getURI() + '/api/get-my-discussions',
        method: 'POST',
        data: {
            user_id: window.id
        }
    }).then(resp => resp.data)
}