import { DomBuilder, fileType, getURI, in_array } from "./page_actions_main";

/**
 * Message Template
 */


/**
 * Displays the appearance of image and video media
 * @param {string} ext File extension
 * @param {string} media Video / Image name
 * @returns {HTMLDivElement}
 */
export function imageVideoInner(ext, media) {
    return DomBuilder(`
        <div class="picture">
            <div class="pending">
                    <img src="${getURI()}/images/Eclipse.gif" alt="loading">
            </div>
            ${
                in_array(ext.toLowerCase(), ['jpeg', 'jpg', 'png']) ?
                    `<img src="${getURI()}/banner/discussions/${media}" class="d-none" onload="this.classList.remove('d-none');" alt="image attachment">`:
                    `<video src="${getURI()}/banner/discussions/${media}"></video>`
            }
        </div>
    `).querySelector('div.picture')
}

/**
 * Show other file types
 * @param { { final_name: string; file_name: string; ext: string; size: any[]; } } file File datas
 * @returns { HTMLDivElement }
 */
export function otherFilesInner(file) {
        return DomBuilder(
            file.ext.toLowerCase() == 'mp3' ?`
                <div class="file">
                    <div class="file-content h-100 w-100 position-relative audio">
                        <div class="file-title">${file.file_name}</div>
                        <div class="file-cred h-100 audio">
                            <iframe src="${getURI()}/voice-template/${file.final_name}" class="position-absolute border-0"></iframe>
                        </div>
                    </div>
                </div>
                ` : `
                <div class="file">
                    <div class="file-content h-100 w-100">
                        <div class="file-title">${file.file_name}</div>
                        <div class="file-cred">
                            <div class="file-img">
                                <img src="${getURI()}/file_icon/${file.ext}.png" alt="file icon">
                            </div>
                            <div class="file-cred-data">
                                <span>
                                    <strong>Size: </strong><span>${file.size[1] + file.size[0]}</span>
                                </span>
                                <span>
                                    <strong>Type: </strong><span>${fileType(file.ext)}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
        `).querySelector('div.file');
}
/**
 * Show call Story
 * @param {string} datetime
 * @param {'me'|'you'} owner
 * @returns {HTMLDivElement}
 */
export function callStory(datetime, owner) {
    let date = new Date(datetime);
    return DomBuilder(`
        <div class="d-flex mb-4 justify-content-center align-items-center">
            <div class="call-divider w-100 rounded-lg pt-1 bg-info"></div>
            <small class="bg-light position-absolute px-3">${owner == 'me' ? 'Call' : 'You received call'} at ${ date.getHours() < 10 ? '0'+date.getHours() : date.getHours() }:${ date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes() }</small>
        </div>
    `).querySelector('body > div');
}

/**
 *  Get the skeleton of a message
 * @param {string} user_logo User small_profil
 * @param {string} mesage_text Message text
 * @param {string} posted_at The time of post
 * @param {'me'|'you'} user For whom should the skeleton be displayed?
 * @param {number} message_id Message ID
 * @returns { HTMLLIElement }
 */
export function messageDomSkeleton(user_logo, mesage_text, posted_at, user, message_id) {
    return DomBuilder(`
        <li class="${in_array(user, ['me', 'you']) ? user : 'me'}" data-message="${message_id}">
            <figure><img src="${user_logo}" alt="User Logo"></figure>
            <div class="text-box">
                <div class="touch-btn">
                    <span class="edit"></span>
                    <span class="delete"></span>
                </div>
                <div class="galerie"></div>
                <p class="text-center">${mesage_text}</p>
                <span id="befxtx">${posted_at}</span>
            </div>
        </li>
    `).querySelector(`li.${in_array(user, ['me', 'you']) ? user : 'me'}`);
}

/**
 * Sending message loader
 * @returns {HTMLDivElement}
 */
export function loaderMessage() {
    return DomBuilder(`
        <div class="picture">
            <div class="pending">
                <img src="${getURI()}/images/Eclipse.gif" alt="loading">
                <span id="loader">0%</span>
            </div>
        </div>
    `).querySelector('div.picture');
}
