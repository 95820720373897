/**
 * @type {HTMLInputElement}
 */
const groupAvatar = document.querySelector('input#group-avatar'),
/**
 * @type {HTMLImageElement}
 */
    displayAvatar = document.querySelector('img#display-avatar');


const _groupAvatar = groupAvatar;
export { _groupAvatar as groupAvatar };
const _displayAvatar = displayAvatar;
export { _displayAvatar as displayAvatar };
