import { in_array } from "../chat/page_actions_main";

/**
 * Display alert message
 * @param {string} type (danger|success|warning) default "warning"
 * @param {string} message
 * @returns {HTMLDivElement}
 */
export function Alert(type, message) {
  type = !in_array(type, ['danger', 'success', 'warning']) ? 'warning' : type;
      return new DOMParser().parseFromString(`
      <div class="d-flex position-fixed w-25" style="top: 120px; right: 40px; z-index: 999999;" id="alert-container">
        <div class="alert alert-${type} alert-dismissible fade show" role="alert" style="padding-right: 38px; overflow: hidden;">
          <small>${message.trim()}</small>
          <button type="button" class="close" style="padding: 5px;" id="alert-btn" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      `, 'text/html').children[0].children[1].children[0];
}

/**
 * Display alert message
 * @param {string} type (danger|success|warning) default "warning"
 * @param {string} message
 */
export function displayAlert(type, message) {
    type = !in_array(type, ['danger', 'success', 'warning']) ? 'warning' : type;
    let msg = Alert(type, message);
    document.body.appendChild(msg);
    setTimeout(() => {
        document.querySelector('button#alert-btn').click();
        setTimeout(() => {
            msg.remove();
        }, 3000);
    }, 15000);
}
