import {
    filterSelection,
    popupCloseBtn,
    uploadFormPhoto
} from "./credentials"
import {
    DomBuilder,
    getSize,
    getURI,
    in_array
} from '../../chat/page_actions_main'
import {
    Alert,
    displayAlert
} from '../alert'
import {
    showAllPublicPhoto,
    showFriendsPhoto
} from "./worker";
import {
    isEmpty,
    isNull,
    isNumber,
    isString
} from "lodash";
import {
    progressbarBuilder
} from "../../chat/elementBuilder";
import axios from "axios";


/**
 * Actions for photo page
 */
function photoPage() {
    const photoSliderSelector = ['searchPhoto', 'photos-list'];
    /**
     * @type {Array<File>}
     */
    let files = [],
        photos = [],
        totalPhoto = document.querySelector(photoSliderSelector.map(selector => `#${selector}`).join(', ')).querySelectorAll('a[data-strip-group]').length,
        currentMediaIndex = 0,
        getImageCredentials = () => {
            let image = document.querySelectorAll(photoSliderSelector.map(selector => `#${selector} a[data-strip-group]`).join(', '))[currentMediaIndex];
            return !image ? {
                title: '',
                description: '',
                id: 0
            } : {
                title: image.getAttribute('data-title') || '',
                description: image.getAttribute('data-description') || '',
                id: parseInt(image.getAttribute('id') || '0')
            }
        },
        displayMediaDetail = () => {
            totalPhoto = document.querySelector(photoSliderSelector.map(selector => `#${selector}`).join(', ')).querySelectorAll('a[data-strip-group]').length;
            let link = document.querySelectorAll(photoSliderSelector.map(selector => `#${selector} a[data-strip-group]`).join(', '))[currentMediaIndex];
            let imageCredentials = getImageCredentials(),
                /**
                 * @type {Element}
                 */
                slider = document.querySelector('div.slider.photo');

            document.querySelector('#slider-media-comment').setAttribute('data-media', imageCredentials.id);
            if (!slider) return

            let view, like, coment, share;

            if (document.querySelector(`#${photoSliderSelector[1]}`)) {
                const data = link.hasAttribute('data-media') ? JSON.parse(link.getAttribute('data-media')) : null;
                if (isNull(data)) return
                view = data['view']
                like = data['like']
                coment = data['coment']
                share = data['share']
            } else {
                view = link.parentElement.querySelector('div.over-photo i.fa-eye + sup').textContent
                like = link.parentElement.querySelector('div.over-photo i.fa-heart + sup').textContent
                coment = link.parentElement.querySelector('div.over-photo i.fa-commenting + sup').textContent
                share = link.parentElement.querySelector('div.over-photo i.fa-share + sup').textContent
            }

            if (imageCredentials.title.length == 0) {
                slider.querySelector('.slider-title .contain').classList.add('d-none')
            } else {
                slider.querySelector('.slider-title .contain').classList.remove('d-none')
                slider.querySelector('.slider-title p').textContent = imageCredentials.title
            }
            if (imageCredentials.description.length == 0) {
                slider.querySelector('.slider-description .contain').classList.add('d-none')
            } else {
                slider.querySelector('.slider-description .contain').classList.remove('d-none')
                slider.querySelector('.slider-description p').textContent = imageCredentials.description
            }

            slider.querySelector('img').setAttribute('src', link.querySelector('img').getAttribute('src'))
            
            //  Display media detail
            let footerDetail = DomBuilder(`
                    <div>
                        <span><i class="fa fa-eye"></i> <sup class="text-light">${view}</sup></span>
                        <span><i class="fa fa-heart text-danger"></i> <sup class="text-light">${like}</sup></span>
                        <span><i class="fa fa-commenting"></i> <sup class="text-light">${coment}</sup></span>
                        <span><i class="fa fa-share"></i> <sup class="text-light">${share}</sup></span>
                    </div>
                    <div>
                        <span>${currentMediaIndex + 1} / ${totalPhoto}</span>
                    </div>
                `).children;
            
            slider.querySelector('div.media-data').innerHTML = ''
            slider.querySelector('div.media-data').prepend(footerDetail[1])
            slider.querySelector('div.media-data').prepend(footerDetail[0])
        };

    document.body.addEventListener('click', e => {
        if (e.target.classList.contains('slider-overlay') || e.target.classList.contains('close-btn')) {
            document.querySelector('div.slider').classList.add('hided')
            setTimeout(() => {
                document.querySelector('div.slider').classList.add('d-none')
            }, 500);
            document.querySelector('div.slider').parentElement.classList.add('d-none')
        }
    }, false)
    
    // Add media to view list
    document.querySelectorAll(photoSliderSelector.map(selector => `#${selector} a[data-strip-group]`).join(', ')).forEach((media, index) => {
        media.addEventListener('click', async () => {
            const id = parseInt(media.getAttribute('id') || '0'),
                data = {
                    user_id: window.id,
                    id: id,
                    media_type: 'photo',
                };
            if (id <= 0) return;
            axios({
                url: getURI() + '/api/setMediaView',
                method: 'POST',
                data
            }).then(response => {
                if (response.data.code == 200) {
                    let selector = ['#searchPhoto > div div.over-photo .fa-eye + .text-light', `#${photoSliderSelector[1]} .vue`],
                    eye = document.querySelectorAll(selector.join(', '));
                    if (isNull(eye)) return
                    eye[index].textContent = parseInt(eye[index].textContent) + 1;
                }
            })
            currentMediaIndex = index
            displayMediaDetail()
            document.querySelector('div.slider').classList.remove('d-none', 'hided')
            document.querySelector('div.slider').parentElement.classList.remove('d-none')
        }, false);
    });

    // When we change file
    if (document.querySelector('form#postImage input#chooseFile, form#postVideo input#chooseFile')) {
        document.querySelector('form#postImage input#chooseFile, form#postVideo input#chooseFile').addEventListener('change', e => {
            if (document.querySelector('form#postImage input#chooseFile, form#postVideo input#chooseFile').getAttribute('accept') !== '.jpeg,.jpg,.png') return
            files = Array.from(e.target.files);
            let _filesCredentials = {
                names: files.map(item => item.name),
                sizes: files.map(item => getSize(item.size)),
                extensions: files.map(item => item.name.split('.')[item.name.split('.').length - 1])
            };
    
            for (const index in _filesCredentials.extensions) { //  Check files information validity
                // Only accept ['jpeg', 'jpg', 'png'] and max size 1.5Mb
                if (!in_array(_filesCredentials.extensions[index].toLowerCase(), ['jpeg', 'jpg', 'png']) || (_filesCredentials.sizes[index][0].toLowerCase() == 'mo' && _filesCredentials.sizes[index][1] > 1.5)) {
                    Object.keys(_filesCredentials).forEach(key => {
                        _filesCredentials[key].splice(index, 1)
                    });
                    let msg = Alert('danger', `Photo ${files[index].name} most be one of this format "${['jpeg', 'jpg', 'png'].join(' ')}" or less than 1.5Mo`);
                    document.body.appendChild(msg);
                    setTimeout(() => {
                        document.querySelector('button#alert-btn').click();
                        setTimeout(() => {
                            msg.remove();
                        }, 3000);
                    }, 15000);
                    files.splice(index, 1);
                }
            }
            e.target.value = '';
        }, false);

        if (uploadFormPhoto.querySelector('button[type="button"]')) {
            uploadFormPhoto.querySelector('button[type="button"]').addEventListener('click', async () => {
                let form = {
                    /**
                     * @type {string}
                     */
                    title: uploadFormPhoto.querySelector('#title').value,
                    /**
                     * @type {string}
                     */
                    description: uploadFormPhoto.querySelector('#description').value,
                    /**
                     * @type {string}
                     */
                    private_state: uploadFormPhoto.querySelector('#private_state').value,
                    /**
                     * @type {string}
                     */
                    mute: uploadFormPhoto.querySelector('#mute').value,
                };
    
                if (!('title' in form) || !('description' in form) || !('private_state' in form) || !('mute' in form)) {
                    displayAlert('danger', 'A form field is missing');
                    return;
                }
    
                if (form.title.length > 90) {
                    displayAlert('danger', 'The title is too long');
                    return;
                }
    
                if (form.description.length > 250) {
                    displayAlert('danger', 'The description is too long');
                    return;
                }
    
                if (files.length == 0) {
                    displayAlert('danger', 'No image has been selected');
                    return;
                }
    
                if (!in_array(form.private_state, ['public', 'private'])) {
                    displayAlert('danger', 'The choice of visibility is invalid');
                    return;
                }
    
                if (!in_array(form.mute, ["muted", "unmuted"])) {
                    displayAlert('danger', 'The choice of notification visibility is invalid');
                    return;
                }
    
                uploadFormPhoto.querySelector('button[name="submit"]').classList.add('disabled');
                (() => {
                    return new Promise((resolve, reject) => {
                        const TIME_INTERVALL_SEND = 2000; //    2s to wait before sending the next request
                        files.forEach((file, file_index) => {
                            /**
                             * Upload process
                             * @type {Promise<string|Error>}
                             */
                            (new Promise((resolve, reject) => {
                                let xhr = new XMLHttpRequest(),
                                    data = new FormData(),
                                    progressbar = progressbarBuilder();
            
                                if ('append' in document.querySelector('#progressbar-container')) {
                                    document.querySelector('#progressbar-container').append(progressbar);
                                } else {
                                    document.querySelector('#progressbar-container').appendChild(progressbar);
                                }
                                progressbar.querySelector('#progressbar-container #progessbar-title').textContent = file.name;
                                
                                data.append('user_id', window.id);
                                data.append('file', file);
                                data.append('title', form.title);
                                data.append('description', form.description);
                                data.append('private_state', form.private_state);
                                data.append('mute', form.mute);
            
                                xhr.open('POST', getURI() + '/api/postImage');
                                xhr.responseType = 'json';
            
                                // Upload progressbar
                                xhr.upload.addEventListener('progress', e => {
                                    let data = {
                                        loaded: 0,
                                        total: 0,
                                        percent: 0 // %
                                    };
            
                                    if (e.lengthComputable) {
                                        data.loaded = e.loaded;
                                        data.total = e.total;
                                        data.percent = Math.ceil((data.loaded / data.total) * 100);
            
                                        progressbar.querySelector('#progress-inner').setAttribute('style', `width: ${data.percent}%;`);
                                        progressbar.querySelector('#progress-inner').setAttribute('data-current', data.percent);
                                        progressbar.querySelector('#progress-inner').firstElementChild.textContent = `${data.percent}%`;
            
                                        if (data.percent == 100) {
                                            progressbar.querySelector('#progress-inner').classList.value = 'progress-inner bg-success';
                                            progressbar.querySelector('#progress-inner').firstElementChild.classList.value = 'text-light';
                                        }
                                    }
                                }, false);
            
                                xhr.addEventListener('readystatechange', () => {
                                    if (xhr.DONE && xhr.readyState == 4) {
                                        const response = xhr.response;
                                        try {
                                            if ('response' in response) {
                                                photos.push(response.response.file_image) // Photo information
                                                resolve(`File: ${file.name} uploaded successfully`);
                                            } else {
                                                reject({
                                                    file_name: file.name,
                                                    errors: response.errors
                                                });
                                            }
                                        } catch (error) {
                                            reject({
                                                file_image: file.name,
                                                errors: ['Server code error 500. This error come from server side']
                                            });
                                        }
                                    }
                                }, false);
                                xhr.onerror = (e) => {
                                    reject({
                                        file_name: file.name,
                                        errors: ['Error occured whe sending data']
                                    });
                                }
                                setTimeout(() => {
                                    xhr.send(data);
                                }, TIME_INTERVALL_SEND);
                            })).then(() => {
                                //    success
                                let container = document.querySelector('div.central-meta > div.row.merged5'), //  Photo container
                                photo = photos[photos.length - 1],
                                children = container.children,
                                newDomChild = DomBuilder(`
                                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                        <div class="item-box h-100"
                                            style="width:100%; border-radius: 5px; max-height: 185px; display: inline-block;">
                                            <a class="h-100" id="${ photo.id }"
                                                href="javascript: void(0)"
                                                data-href="${ getURI() + '/banner/uploads/photo/' + photo.name }" title=""
                                                data-title="${form.title.trim()}"
                                                data-description="${form.description.trim()}"
                                                data-strip-group="mygroup"
                                                data-strip-group-options="loop: false">
                                                <img src="${ getURI() + '/banner/uploads/photo/' + photo.name }"
                                                    alt="${ files[files.indexOf(file)].name }" class="h-100">
                                            </a>
                                            <div class="over-photo d-flex justify-content-around align-items-center">
                                                <div class="more-opotnz" style="position: absolute; right: 10px; bottom: 31px;">
                                                    <i class="fa fa-ellipsis-h text-primary"></i>
                                                    <ul data-item="spa2dli" style="backdrop-filter: blur(1px); background: #ffffffab none repeat scroll 0 0;">
                                                        <li><a href="javascript: void(0)" class="d-block text-dark" id="mute-photo" aria-describedby="">${ photo.mute ? 'Unmute Notifications' : 'Mute Notifications' }</a></li>
                                                        <li><a href="javascript: void(0)" class="d-block text-dark" id="hide-photo" aria-describedby="">${ photo.private_state == 'public' ? 'Hide photo' : 'Show photo' }</a></li>
                                                    </ul>
                                                </div>
                                                <span><i class="fa fa-eye"></i> <sup class="text-light">0</sup></span>
                                                <span><i class="fa fa-heart text-danger"></i> <sup class="text-light">0</sup></span>
                                                <span><i class="fa fa-commenting"></i> <sup class="text-light">0</sup></span>
                                                <span><i class="fa fa-share"></i> <sup class="text-light">0</sup></span>
                                            </div>
                                        </div>
                                    </div>
                                `).children[0];
                                
                                if (children.length > 1) {
                                    container.insertBefore(newDomChild, children[1])
                                } else {
                                    if ('append' in container) {
                                        container.append(newDomChild)
                                    } else {
                                        container.appendChild(newDomChild)
                                    }
                                }
                                if (files.length == (file_index + 1)) {
                                    resolve(200)
                                }
                            }).catch(err => {
                                reject({
                                    err,
                                    file
                                })
                            });
                        });
                    })
                })().then(() => {
                    displayAlert('success', `Your image${files.length > 1 ? 's' : ''} uploaded successfully !!!`);
                    popupCloseBtn.click();
                    Array.from(document.querySelector('#progressbar-container').children).forEach(progress => progress.remove());
                    files = [];
                    photos = [];
                    uploadFormPhoto.reset();
                    uploadFormPhoto.querySelector('button[name="submit"]').classList.remove('disabled')
                    photoPage();
                    document.querySelector('span#title-max').textContent = ''
                    document.querySelector('span#description-max').textContent = ''
                    document.querySelector('div#private_state_chosen > .chosen-single > span').textContent = 'Make your choice'
                    document.querySelector('div#mute_chosen > .chosen-single > span').textContent = 'Make your choice'
                }).catch(state => {
                    console.log(state);
                    displayAlert('danger', `Something when wrong during uploading ${'file' in state ? state.file.name : ''}`);
                    uploadFormPhoto.querySelector('button[name="submit"]').classList.remove('disabled')
                })
            }, false);
        }

        // Filter (Show public | Show only friends | Hide ...)
        filterSelection.forEach(link => {
            link.addEventListener('click', () => {
                const action = link.getAttribute('id');
                switch (action) {
                    case 'show-public':
                        showAllPublicPhoto();
                        break;
                    case 'show-only-friends':
                        showFriendsPhoto();
                        break;
                    case 'hide-all':
                        break;
                    case 'mute-notification':
                        break;
    
                    default:
                        let alert = Alert('warning', 'Unknow action. Please reload page and try again.');
                        document.body.appendChild(alert);
                        setTimeout(() => {
                            document.querySelector('#alert-btn').click();
                            setTimeout(() => {
                                alert.remove();
                            }, 2000);
                        }, 10000);
                        break;
                }
            }, false);
        });
    
        // Mute notification
        document.querySelectorAll('#searchPhoto > div #mute-photo').forEach((link, index) => {
            link.addEventListener('click', async () => {
                const id = parseInt(document.querySelectorAll('#searchPhoto > div a[data-strip-group]')[index].getAttribute('id') || 0);
                if (id <= 0) return;
                /**
                 * @type {{ code: 200|404; message: string; current_state: 'mute'|'unmute' }}
                 */
                let response = await axios({
                    url: getURI() + '/api/toggle-media-state',
                    method: 'POST',
                    data: {
                        user_id: window.id,
                        id: id,
                        media_type: 'photo',
                    }
                }).then(response => response.data)
                if (response.code == 200) {
                    response.current_state = in_array(response.current_state, ['mute', 'unmute']) ? response.current_state : 'unmute'
                    link.textContent = response.current_state == 'mute' ? 'Mute Notifications' : 'Unmute Notifications'
                }
            }, false);
        });
    
        // Toggle media visibility
        document.querySelectorAll('#searchPhoto > div #hide-photo').forEach((link, index) => {
            link.addEventListener('click', async () => {
                const id = parseInt(document.querySelectorAll('#searchPhoto > div a[data-strip-group]')[index].getAttribute('id') || 0);
                if (id <= 0) return;
                /**
                 * @type {{ code: 200|404; message: string; current_state: 'public'|'private' }}
                 */
                let response = await axios({
                    url: getURI() + '/api/toggle-media-visibility',
                    method: 'POST',
                    data: {
                        user_id: window.id,
                        id: id,
                        media_type: 'photo',
                    }
                }).then(response => response.data)
                if (response.code == 200) {
                    response.current_state = in_array(response.current_state, ['public', 'private']) ? response.current_state : 'public'
                    link.textContent = response.current_state == 'public' ? 'Show photo' : 'Hide photo'
                }
            }, false);
        });
    }

    // Go preview media
    document.querySelector('div.slider > .slider-list a.prev').addEventListener('click', () => {
        currentMediaIndex--
        if (currentMediaIndex < 0) currentMediaIndex = (totalPhoto - 1)
        displayMediaDetail();
    }, false);

    // Go next media
    document.querySelector('div.slider > .slider-list a.next').addEventListener('click', () => {
        currentMediaIndex++
        if (currentMediaIndex > (totalPhoto - 1)) currentMediaIndex = 0
        displayMediaDetail();
    }, false);
}

if ((uploadFormPhoto && uploadFormPhoto.id == "postImage") || document.querySelector('#photos-list')) {
    photoPage();
    window.photoPage = photoPage;
    require('./coments')
}
if (document.querySelector('form#coment-form-photo')) {
    require('./coments')
}

// Sort

let pagination = {
    page: 1,
    searchType: 'text',
    by: 'none',
    value: '',
    perPage: 10,
    user_id: window.zipusr,
};

/**
 * @param {string} by
 * @param {string} value
 * @param {string} type
 * @returns
 */
async function sortPhoto(by, value, type) {
    let total_photo = parseInt(document.querySelector('#total-result').textContent);

    type = !in_array(type, ['text', 'sort_by', 'function', 'more']) ? 'text' : type
    by = !in_array(by, ['a_to_z', 'see_all', 'newest', 'oldest', 'hided', 'public', 'private', 'muted', 'unmuted', 'none']) ? 'none' : by
    value = !isString(value) || isEmpty(value) ? '' : value
    if (!isNumber(total_photo)) return;

    if (type !== 'more') {
        pagination = {
            ...pagination,
            ...{
                searchType: type,
                by: by,
                value: value,
                page: 1
            }
        }
    } else {
        pagination.page++;
    }

    let params = new URLSearchParams();
    params.append('filter', pagination.by);
    params.append('searchText', pagination.value);
    params.append('searchType', pagination.searchType);
    params.append('user_id', pagination.user_id);
    params.append('length', pagination.perPage);
    params.append('page', pagination.page);

    let data = await axios({
        method: 'GET',
        url: `${getURI()}/api/sortPhoto?${params.toString()}`
    }).then(data => data.data)
    document.querySelector('#total-result').textContent = data.response.pagination.total;
    if (data.code == 200) {
        if (type !== 'more') {
            Array.from(document.querySelector('#searchPhoto').children).forEach((item, key) => {
                if (key > 0) item.remove()
            });
        }
        if (isNull(data.response.results)) return
        let users = await dataFactory(data.response);
        data = data.response;
        pagination.page = data.pagination.current_page;


        users.forEach(user => document.querySelector('#searchPhoto').append(user));
        document.querySelector('#result-count').textContent = data.pagination.to;
        document.querySelector('#slice-result').textContent = `1 - ${data.pagination.to}`
        photoPage()
    } else {
        document.querySelector('#result-count').textContent = 0;
        document.querySelector('#slice-result').textContent = `1 - 10`
        Array.from(document.querySelector('#searchPhoto').children).forEach((item, key) => {
            item.remove();
        });
    }
}

window.sortPhoto = sortPhoto;

/**
 * Build the DOM who display group list
 * @param {{}} data
 * @returns
 */
async function dataFactory(data) {
    /**
     * @type {Array<HTMLDivElement>}
     */
    let template = [];

    data.results.forEach((picture, index) => {
        let inner = `
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
            <div class="item-box h-100"
                    style="width:100%; border-radius: 5px; max-height: 185px; display: inline-block;">
                    <a class="h-100" id="${ picture.photo.id }"
                        href="javascript: void(0)"
                        data-href="${ getURI() + '/banner/uploads/photo/' + picture.photo.name }" title=""
                        data-strip-group="mygroup"
                        data-title="${isNull(picture.photo.title) ? '' : picture.photo.title}"
                        data-description="${isNull(picture.photo.description) ? '' : picture.photo.description}"
                        data-strip-group-options="loop: false">
                        <img src="${ getURI() + '/banner/uploads/photo/' + picture.photo.name }"
                            alt="${ picture.photo.name }" class="h-100">
                    </a>
                    <div class="over-photo d-flex justify-content-around align-items-center">
                        <div class="more-opotnz" style="position: absolute; right: 10px; bottom: 31px;">
                            <i class="fa fa-ellipsis-h text-primary"></i>
                            <ul data-item="spa2dli" style="backdrop-filter: blur(1px); background: #ffffffab none repeat scroll 0 0;">
                                <li><a href="javascript: void(0)" class="d-block text-dark" id="mute-photo" aria-describedby="">${ picture.photo.mute == 'muted' ? 'Unmute Notifications' : 'Mute Notifications' }</a></li>
                                <li><a href="javascript: void(0)" class="d-block text-dark" id="hide-photo" aria-describedby="">${ picture.photo.private_state == 'public' ? 'Hide photo' : 'Show photo' }</a></li>
                            </ul>
                        </div>
                        <span><i class="fa fa-eye"></i> <sup class="text-light">${picture.option.view}</sup></span>
                        <span><i class="fa fa-heart text-danger"></i> <sup class="text-light">${picture.option.like}</sup></span>
                        <span><i class="fa fa-commenting"></i> <sup class="text-light">${picture.option.coment}</sup></span>
                        <span><i class="fa fa-share"></i> <sup class="text-light">${picture.option.share}</sup></span>
                    </div>
                </div>
            </div>
        `;
        template.push(DomBuilder(inner).firstElementChild);
    });

    return template;
}
