import axios from "axios";
import { isString } from "lodash";
import { getURI } from "../../chat/page_actions_main";

const start = `<div id="searchPhoto" class="row merged5">`,
    end = '</div>';

export function showAllPublicPhoto() {
    let content = document.querySelector('div#searchPhoto');

    axios({
        method: 'POST',
        url: `${getURI()}/api/showAllPublicPhoto`,
        data: {
            user_id: window.id
        }
    }).then(response => {
        if (response.data.code == 200) {
            content.innerHTML = '';
            /**
             * @type {[]}
             */
            let items = response.data.content.map(item => photoElement(item['name'])) || [],
                html = convertStringToHtml(`${start}${items.reverse().join('')}${end}`);

            for (let index = 0; index < items.length; index++) {
                content.appendChild(convertStringToHtml(items[index]));
            }
        }
    });
}
export function showFriendsPhoto() {
    let content = document.querySelector('div#searchPhoto');

    axios({
        method: 'POST',
        url: `${getURI()}/api/showFriendsPhoto`,
        data: {
            _token: window.tk,
            user_id: window.id
        }
    }).then(response => {
        console.log(response.data.content);
        if (response.data.code == 200) {
            content.innerHTML = '';
            /**
             * @type {[]}
             */
            let items = response.data.content.map(item => photoElement(item['name'])) || [],
                html = convertStringToHtml(`${start}${items.reverse().join('')}${end}`);

            for (let index = 0; index < items.length; index++) {
                content.appendChild(convertStringToHtml(items[index]));
            }
        }
    });
}

/**
 * Building Phototemplate
 * @param {string} name name of image
 * @returns  {string}
 */
const photoElement = (name) => (
    `<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
        <div class="item-box" style="width:100%; border-radius: 5px; max-height: 185px; display: inline-block;">
            <a class="strip" id="1" href="http://localhost:8000/uploads/photo/${name}" title="" data-strip-group="mygroup" data-strip-group-options="loop: false">
                <img src="http://localhost:8000/uploads/photo/${name}" alt="${name}">
            </a>
            <div class="over-photo">
                <form method="get" action="${getURI()}/photo-comments" enctype="multipart/form-data">
                    <input hidden="" type="text" name="id" value="1">
                    <button type="submit"><i class="fa fa-commenting"></i></button>
                </form>
            </div>
        </div>
    </div>`
);

/**
 * Convert all string to html
 * @param {string} content date to convert
 */
export function convertStringToHtml(content) {
    if (!isString(content)) {
        return new DOMParser().parseFromString('', 'text/html').children[0].children[1].children[0];
    }
    return new DOMParser().parseFromString(content, 'text/html').children[0].children[1].children[0];
}