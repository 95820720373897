import { isEmpty, isNull, isNumber, isSet, isString } from "lodash";
import { displayAvatar, groupAvatar } from "./credentials";
import { DomBuilder, getURI, in_array } from "../../chat/page_actions_main";

if (!isNull(groupAvatar)) {
    groupAvatar.addEventListener('change', e => {
        /**
         * @type {Array<File>}
         */
        const files = Array.from(e.target.files);
        if (files.length > 0) {
            const file = new Blob([files[0]], { type: 'image/png' });
            displayAvatar.setAttribute('src', window.URL.createObjectURL(file));
        }
    }, false);
}

// Sort

let pagination = {
    page: 1,
    searchType: 'text',
    by: 'none',
    value: '',
    perPage: 10,
    user_id: window.zipusr,
};

/**
 * @param {string} by
 * @param {string} value
 * @param {string} type
 * @returns
 */
async function sortGroups(by, value, type) {
    let total_user = parseInt(document.querySelector('#total-result').textContent);

    type = !in_array(type, ['text', 'sort_by', 'function', 'more']) ? 'text' : type
    by = !in_array(by, ['a_to_z', 'see_all', 'newest', 'oldest', 'hided', 'public', 'private', 'muted', 'unmuted', 'none']) ? 'none' : by
    value = !isString(value) || isEmpty(value) ? '' : value
    if (!isNumber(total_user)) return;

    if (type !== 'more') {
        pagination = {
            ...pagination,
            ...{
                searchType: type,
                by: by,
                value: value,
                page: 1
            }
        }
    } else {
        pagination.page++;
    }

    let params = new URLSearchParams();
    params.append('filter', pagination.by);
    params.append('searchText', pagination.value);
    params.append('searchType', pagination.searchType);
    params.append('user_id', pagination.user_id);
    params.append('length', pagination.perPage);
    params.append('page', pagination.page);

    let data = await axios({
        method: 'GET',
        url:  `${getURI()}/api/sortGroup?${params.toString()}`
    }).then(data => data.data)
    if (data.code == 200) {
        Array.from(document.querySelector('#group-item').children).forEach((item, key) => {
            if (key > 0) item.remove();
        });
        let users = dataFactory(data.response);
        data = data.response;
        pagination.page = data.pagination.current_page;

        if (pagination.searchType == 'more') {
            document.querySelector('#slice-result').textContent = `1 - ${((paginate) => {
                if (paginate.currentPage == 1) {
                    return 10
                }
                if (paginate.currentPage > 1) {
                    return ((paginate.currentPage - 1) * pagination.perPage) + data.results.length
                }
            })(data.pagination)}`
        } else {
            Array.from(document.querySelector('#group-item').children).forEach((item, key) => {
                if (key > 0) item.remove();
            });
        }

        users.forEach(user => document.querySelector('#group-item').append(user));
    } else {
        document.querySelector('#result-count').textContent = 0;
        Array.from(document.querySelector('#group-item').children).forEach((item, key) => {
            if (key > 0) item.remove();
        });
    }
}

window.sortGroups = sortGroups;

/**
 * Build the DOM who display group list
 * @param {{}} data
 * @returns
 */
 function dataFactory(data) {
    /**
     * @type {Array<HTMLDivElement>}
     */
    let template = [],
    length = data.results.length;

    document.querySelector('#result-count').textContent = length;
    document.querySelector('#total-result').textContent = length;
    data.results.forEach((group, index) => {
        let inner = `
            <div class="col-lg-2 col-md-4 col-sm-4">
                <div class="group-box">
                    <figure><img src="${isNull(group.group.avatar) ? getURI() + '/images/resources/group1.jpg' : getURI() + '/banner/groups/' + group.group.avatar}"></figure>
                    <a href="javascript: void(0)" data-group="${group.group.id}" title="">${group.group.name}</a>
                    <span>${group.group_participant.length} Member${ group.group_participant.length > 1 ? 's' : '' }</span>
                    <button>join group</button>
                </div>
            </div>
        `;
        template.push(DomBuilder(inner).firstElementChild);
    });

    return template;
}
