import { isEmpty } from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import { getMySocialNetwork } from "../API/socialNetwork";
import SOCIAL_NETWORK from "../constants/social_network"

export default class SocialNetwork extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            social_input_is_visible: false,
            social_network_list: SOCIAL_NETWORK,
            /**
             * @type {Array<{ name: string; link: string }>}
             */
            social_network: [],
            addBtnIcon: 'fa fa-plus text-light'
        }
        this.social_input_text = ""
        this.social_name = ""
    }

    async componentDidMount() {
        /**
         * @type {code: 200|418; data: Array<{ name: string; link: string }>}
         */
        let response = await getMySocialNetwork(),
        /**
         * @type {Array<{ name: string; link: string }>}
         */
        socialList = response.code == 200 ? response.data : []
        this.setState({
            ...this.state,
            ...{
                social_network: socialList,
                social_network_list: this.state.social_network_list.filter(({name}) => socialList.map(social => social.name).indexOf(name) == -1)
            }
        })
    }

    _addLink() {
        if (!isEmpty(this.social_input_text) && !isEmpty(this.social_name)) {
            const currentSocialNetwork = [
                ...this.state.social_network,
                ...[
                    {
                        name: this.social_name,
                        link: this.social_input_text
                    }
                ]
            ], newState = {
                ...this.state,
                ...{
                    social_network: currentSocialNetwork,
                    social_input_is_visible: false,
                    social_network_list: this.state.social_network_list.filter(({name}) => currentSocialNetwork.map(social => social.name).indexOf(name) == -1),
                    addBtnIcon: 'fa fa-plus text-light'
                }
            };

            this.setState(newState)
        }
    }

    /**
     * @param {{  name: string; link: string; }} social
     */
    _removeSocialNetwork(social) {
        const newState = {
            ...this.state,
            ...{
                social_network: this.state.social_network.filter(({name}) => name !== social.name),
                social_network_list: [
                    ...[ social ],
                    ...this.state.social_network_list
                ]
            }
        }

        this.setState(newState)
    }

    render() {
        return (
            <div className="text-left">
                <input type="hidden" name="social_network" value={JSON.stringify(this.state.social_network)}/>
                <button
                type="button"
                onClick={() => this.setState({ ...this.state, ...{ social_input_is_visible: !this.state.social_input_is_visible ? true : false, addBtnIcon: this.state.addBtnIcon.match('fa-plus') ? 'fa fa-minus text-light' : 'fa fa-plus text-light' } })}
                className="py-1 add-social-network overflow-hidden">
                    Add <i className={this.state.addBtnIcon}></i>
                </button>
                {
                    this.state.social_input_is_visible ?
                    <section className="mb-2" style={{backgroundColor: 'white'}}>
                        <div className="row">
                            <div className="col-4 d-flex align-items-center">
                                <select name="social_name" onChange={e => this.social_name = e.target.value} className="form-control form-control-sm" style={{backgroundColor: '#edf2f6'}}>
                                    <option value="blood">Social Name</option>
                                    { this.state.social_network_list.map((social) => <option key={social.key} value={social.name}>{social.name}</option>) }
                                </select>
                            </div>
                            <div className="col-8">
                                <input type="text" name="social_network_input" onKeyUp={e => this.social_input_text = e.target.value} placeholder="Type a link here" className="my-2 py-2"/>
                            </div>
                        </div>
                        <button
                        type="button"
                        onClick={() => this._addLink()}
                        className="py-1 overflow-hidden">
                            add link <i className="fa fa-cloud-download text-light"></i>
                        </button>
                    </section> : null
                }
                <hr />
                <ul className="my-3 p-0 list-style">
                    {this.state.social_network.map((social, index) => (
                        <li key={index.toString()} className="my-2">
                            <div className="row">
                                <div className="col-8 d-flex align-items-center" style={{ height: '40px' }}>
                                    <input type="text" className="py-1" disabled value={social.link} />
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-center">
                                    <a href="/" className="p-4 rounded-circle text-decoration-none" onClick={(e) => { e.preventDefault(); this._removeSocialNetwork(social); }}><i className="fa fa-close text-danger"></i></a>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}

if (document.getElementById('social-network')) ReactDOM.render(<SocialNetwork />, document.getElementById('social-network'))