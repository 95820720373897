import './jquery-init';
import './bootstrap';

const initialize = () => {
    const searchCountriesElement = document.getElementById('search-countries');
    if (searchCountriesElement) {
        import('../Components/search-countries');
    }

    console.clear();

    // Chat page
    require('../chat/page_actions_main');
    // Websocket App
    require('../chat/index');
    // Uploads profil and cover photo
    require('../jobs/profil_picture');
    // Profil Utils
    const { getMetaData } = require('../jobs/settings');
    window.localZone = getMetaData();
    // Privacy manager toggle
    require('../jobs/settings');
    // timelines actions
    require('../timelines/index');

    // Display timezones and metadates
    const country = document.querySelector('.chosen-results');

    if (country) {
        country.innerHTML = '<option value="country">Select country</option>';
    }

    // Components
    require('../Components/Weather');

    /**
     * Next, we will create a fresh React component instance and attach it to
     * the page. Then, you may begin adding components to this application
     * or customize the JavaScript scaffolding to fit your unique needs.
     */

    require('./components');
    require('./requests');
};

window.addEventListener('load', initialize);
