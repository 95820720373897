import axios from "axios";
import {
    isEmpty,
    isNull,
    isUndefined
} from "lodash";
import {
    discussionsLoader,
    messageEventRenderer
} from ".";
// import Vue from "vue";
import {
    chat_close_message,
    chat_name,
    chat_status,
    messageListItem
} from "./credentials";
import { callStory, imageVideoInner, messageDomSkeleton, otherFilesInner } from "./messageTemplate";
import {
    DomBuilder,
    getURI,
    in_array
} from "./page_actions_main";

const formMsg = document.querySelectorAll('form#form-msg');
let message_list = document.querySelector('ul#chat-users') || undefined;

const mainTchat = (() => {
    message_list = document.querySelector('ul#chat-users') || undefined;
    if (document.querySelector('ul.chat-users#chat-users')) { //  If we are at home page
        chat_close_message.addEventListener('click', () => {
            for (const item of document.querySelector('ul.chat-users#chat-users').children) {
                if (item instanceof HTMLLIElement) {
                    item.setAttribute('data-state', 'closed');
                }
            }
        });
        for (const link of document.querySelector('ul.chat-users#chat-users').children) {
            if (link instanceof HTMLLIElement) {
                link.addEventListener('click', async() => {
                    window.currentDiscussion = parseInt(link.getAttribute('data-item').split(',')[0].match(/[0-9]+/)[0]);
                    loadDiscussion(link);
                }, false);
            }
        }
    }
});

/**
 * @param {HTMLLIElement} item
 */
async function loadDiscussion(item) {
    for (const items of document.querySelector('ul.chat-users#chat-users').children) { //   Close all discussions how is'nt different betteen current discussion
        if (items instanceof HTMLLIElement && item !== items) {
            items.setAttribute('data-state', 'closed');
        }
    }
    item.setAttribute('data-state', 'open');
    const discus = await discussionsLoader().then(data => data);
    if (discus.code == 200) {
        let data = item.getAttribute('data-item').match(/[0-9,]+/)[0].split(',').map(item => parseInt(item)),
            discussion_id = data[0],
            discussion = window.itxyxec.discussions[discussion_id],
            messages = Object.values(window.itxyxec.messages[discussion_id]);

        chat_name.textContent = discussion['user']['first_name'].charAt(0).toUpperCase() + discussion['user']['first_name'].slice(1);
        chat_status.setAttribute('class', `status f-${discussion['user']['state']}`);
        formMsg[0].setAttribute('data-item', 'it' + data.join(',') + 'em');
        messageListItem[0].innerHTML = '';
        let content = '',
            /**
             * @type {HTMLLIElement[]}
             */
            li = [];
        for (const message of messages) {
            // settings before display datas
            const isMixed = message['attachments'].length > 0 ? true : false,
                sender_id = message['user_id'],
                dates = message['created_at'].replace('.000000Z', '').replace('T', ' '),
                date = new Date(dates);
            let setOne,
                img = '';
            if (sender_id == window.tkinter) {
                setOne = `${(message['state'] == 'readed') ? '<i class="ti-check"></i><i class="ti-check"></i>' : ''} ${date.getHours() < 10 ? '0'+date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()}`;
                img = window.tkinter_img == 'https://place-hold.it/160' ? '' : window.tkinter_img;
            } else {
                setOne = `${date.getHours() < 10 ? '0'+date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()}`;
                img = isNull(discussion['user']['small_profile']) || isEmpty(discussion['user']['small_profile']) ? '' : discussion['user']['small_profile'];
            }
            let URI = getURI(),
                message_dom = messageDomSkeleton(
                    `${img.length <= 0 ? 'https://place-hold.it/45' : URI + '/banner/' + img}`,
                    message.message,
                    setOne,
                    (sender_id == window.tkinter ? 'me' : 'you'),
                    message.id
                );

            img = img.length > 0 ? img : '';
            /**
             * @type {HTMLDivElement[]}
             */
            let file_dom = [];
            // creating content
            if (isMixed) {
                let files = message['attachments'][0]['content'];
                for (const file of files) {
                    if (in_array(file.ext.toLowerCase(), ['jpeg', 'jpg', 'png', 'mp4', '3gp'])) { // images | videos
                        file_dom.push(imageVideoInner(file.ext, file.name));
                    } else if (in_array(file.ext.toLowerCase(), ['mp3', 'txt', 'pdf', 'zip'])) { // documents
                        let file_name = file.name.replace('.' + file.ext, '').slice(0, 10) + '...' + file.ext;
                        file_dom.push(otherFilesInner({
                            final_name: file.name,
                            file_name: file_name,
                            ext: file.ext,
                            size: file.size
                        }));
                    } else if (in_array(file.type, ['audio', 'video'])) {
                        file_dom.push(callStory(message.created_at, (message.user_id == message.owner.user_id) ? 'me' : 'you'));
                    }
                }
            }
            file_dom.forEach(dom => {
                if (dom.classList.contains('file') || dom.classList.contains('picture')) {
                    message_dom.querySelector('div.text-box div.galerie').appendChild(dom);
                }
            });
            if (file_dom.length > 0) {
                if (file_dom[0].classList.contains('file') || file_dom[0].classList.contains('picture')) {
                    li.push(message_dom);
                } else {
                    li.push(file_dom[0]);
                }
            } else {
                li.push(message_dom);
            }
        }
        li.forEach(li_item => document.querySelectorAll('ul#message-list-item')[0].appendChild(li_item));
        messageListItem[0].scrollTo(0, messageListItem[0].scrollHeight);
        window.preloader.launcheImage();
        messageEventRenderer();
    }
}

mainTchat();
window.mainTchat = mainTchat;

if (!isNull(document.querySelectorAll('#send-mesg'))) {
    document.querySelectorAll('#send-mesg').forEach(msgBtn => {
        msgBtn.addEventListener('click', () => {
            const id = parseInt(msgBtn.getAttribute('data-us').match(/[0-9]+/)[0]);

            if (discussionExists(id)) {
                openMessage(id);
            } else {
                createDiscussion(id)
            }
        }, false);
    });
}

/**
 * Get if discussion is ready exists
 * @param {number} us_id
 * @returns
 */
function discussionExists(us_id) {
    let exists = false;
    Object.values(itxyxec.discussions).forEach(discussion => {
        if (discussion.user.id == us_id) {
            exists = true;
        }
    });

    return exists;
}

function openMessage(us_id) {
    if (!isUndefined(message_list)) {
        Array.from(message_list.children).forEach(async message => {
            if (message instanceof HTMLLIElement && message.getAttribute('data-item')) {
                let credentials = message.getAttribute('data-item').split(',').map(item => parseInt(item.match(/[0-9]+/)[0]));
                if (credentials[1] == us_id) { //   Open message box if discussion exists
                    message.click();
                    document.querySelector('div#chat-box').classList.value = 'chat-box show';
                }
            }
        });
    }
}

/**
 * Create another user
 * @param {number} us_id
 * @returns {Promise<boolean>}
 */
async function createDiscussion(us_id) {
    let response = await axios({
        url: `${getURI()}/api/initialize/new-discussion`,
        method: 'POST',
        data: {
            user_id: window.id,
            owner: us_id
        }
    }).then(response => response.data);

    return new Promise(async resolve => {
        if (response.code == 200) {
            response = response.response;
            let messages = await discussionsLoader().then(data => data),
            message_item = '';
            if (messages.code == 200) {
                message_item = `
                    <li data-item="it${response.discussion.id},${response.user.id}em" data-state="closed">
                        <div class="author-thmb">
                            <img src="${isNull(response.user.small_profile) ? 'https://place-hold.it/45' : getURI() + '/banner/' + response.user.small_profile}" style="width: 34px;" alt="">
                            <span class="status f-offline"></span>
                        </div>
                    </li>
                `;
                if ('append' in message_list) {
                    message_list.append(DomBuilder(message_item).children[0]);
                } else {
                    message_list.appendChild(DomBuilder(message_item).children[0]);
                }
                mainTchat();
                loadDiscussion(DomBuilder(message_item).children[0]);
                document.querySelector('div#chat-box').classList.value = 'chat-box show';
            }
        }
    });
}
