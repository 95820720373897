import axios from "axios";
import { isEmpty, isNull } from "lodash";
import { getURI } from '../../../chat/page_actions_main'
const { like, like_inner, like_inner_global, dislike, dislike_inner, toggleVideoLikeBtn, toggleVideoLikeInner } = require('../../photo/coments/credentials').default;

if (like) {
    like.addEventListener('click', e => {
        const id = parseInt(like.getAttribute('data-name').match(/[0-9]+/)[0]),
            media_type = like.getAttribute('data-type');
        axios({
            url: getURI() + `/api/likePhoto`,
            method: 'POST',
            data: {
                id: id,
                user_id: window.id,
                media_type: 'video',
            }
        }).then(response => {
            if (response.data.code == 200) {
                if (response.data.was_disliked) {
                    dislike_inner.textContent = parseInt(dislike_inner.textContent) - 1;
                }
                like_inner.textContent = parseInt(like_inner.textContent) + 1;
                like_inner_global.textContent =parseInt(like_inner_global.textContent) + 1;
            }
        });
    }, false);
}

if (!isNull(toggleVideoLikeBtn)) {
    toggleVideoLikeBtn.forEach((like, key) => {
        like.addEventListener('click', () => {
            const id = parseInt(like.getAttribute('data-name').match(/[0-9]+/)[0]),
                media_type = like.getAttribute('data-type'),
                ready_liked= like.hasAttribute('data-liked') ? like.getAttribute('data-liked') : null;
            if (isNull(ready_liked)) return
            axios({
                url: getURI() + `/api/${ready_liked == 'true' ? 'unlikePhoto' : 'likePhoto'}`,
                method: 'POST',
                data: {
                    id: id,
                    user_id: window.id,
                    media_type: media_type,
                }
            }).then(response => {
                if (response.data.code == 200) {
                    toggleVideoLikeInner[key].textContent = response.data.current_len
                    like.setAttribute('data-liked', ready_liked == 'true' ? 'false' : 'true')
                    if (ready_liked == 'false') {
                        like.classList.replace('broken', 'happy')
                    } else {
                        like.classList.replace('happy', 'broken')
                    }
                }
            });
        }, false)
    })
}

if (dislike) {
    dislike.addEventListener('click', e => {
        const id = parseInt(dislike.getAttribute('data-name').match(/[0-9]+/)[0]),
            media_type = dislike.getAttribute('data-type');
        axios({
            url: getURI() + `/api/unlikePhoto`,
            method: 'POST',
            data: {
                user_id: window.id,
                id: id,
                media_type: 'video',
            }
        }).then(response => {
            if (response.data.code == 200) {
                if (response.data.was_liked) {
                    like_inner.textContent = parseInt(like_inner.textContent) - 1;
                    like_inner_global.textContent =parseInt(like_inner_global.textContent) - 1;
                }
                dislike_inner.textContent = parseInt(dislike_inner.textContent) + 1;
            }
        })
    }, false);
}
