import axios from "axios";
import { isEmpty, isNull } from "lodash";
import { DomBuilder, getURI, ucfirst } from "./page_actions_main";


/**
 * @type {HTMLDivElement}
 */
let resultContaine = document.querySelector('#top-search-result'),
/**
 * @type {HTMLInputElement}
 */
searchInput = document.querySelector('#top-search input');

/**
 * @param {string} searshText 
 */
async function search(searshText) {
    if (isEmpty(searshText)) return
    resultContaine.querySelector('#spinner-loader').classList.remove('d-none')

    /**
     * @type {{ code: 200; response: { people: Array<{ first_name: string; last_name: string; small_profile: string | null; id: number }>; group: Array<{ avatar: string; name: string; category: string; }>; total: number; }; }}
     */
    let response = await axios({
        method: 'POST',
        url: `${getURI()}/api/ppg-search-bar`,
        data: {
            user_id: window.id,
            search_text: searshText
        }
    }).then(response => response.data)

    if (response.code == 200) {
        let notResult = true,
        total = 0
        Array.from(['people', 'group']).forEach((result) => {
            if (response.response[result].length > 0) notResult = false
        })
        
        if (!notResult) {
            resultContaine.querySelector('#result-inner').innerHTML = ''
            resultContaine.querySelector('#spinner-loader').classList.add('d-none')
            for (const index in response.response)  {
                const resp = response.response
                resp[index].forEach(item => {
                    total++
                    if ('append' in resultContaine.querySelector('#result-inner')) {
                        resultContaine.querySelector('#result-inner').append(build(
                            index,
                            index == 'group' ? item.avatar : item.small_profile,
                            item.id,
                            index == 'group' ? item.name : ucfirst([item.first_name, item.last_name].join(' ')),
                            index == 'group' ? '' : item.description,
                            index == 'group' ? item.id : item.token
                        ))
                    } else {
                        resultContaine.querySelector('#result-inner').appendChild(build(
                            index,
                            index == 'group' ? item.avatar : item.small_profile,
                            item.id,
                            index == 'group' ? item.name : ucfirst([item.first_name, item.last_name].join(' ')),
                            index == 'group' ? '' : item.description,
                            index == 'group' ? item.id : item.token
                        ))
                    }
                })
            }
            resultContaine.querySelector('#result-len').textContent = total < 10 ? '0'+total.toString() : total
        } else {
            resultContaine.querySelector('#spinner-loader').classList.add('d-none')
            resultContaine.querySelector('#result-inner').innerHTML = ''
            resultContaine.querySelector('#result-len').textContent = '0'
        }
    }
}

/**
 * @param {'people'|'group'} type 
 * @param {string|null} logo 
 * @param {number} id 
 * @param {string} full_name 
 * @param {string} description 
 * @param {string} token 
 * @returns {HTMLLIElement}
 */
function build(type, logo, id, full_name, description, token) {
    let url = '',
    default_url = 'https://place-hold.it/50'
    switch (type) {
        case 'group':
            url = `${getURI()}/banner/groups/`
            break;
        case 'people':
            url = `${getURI()}/banner/`
            break;
            
            default:
            url = null
            break;
    }
    logo = logo == null ? default_url : url == null ? default_url : `${url + logo}`
    
    return DomBuilder(`
        <li>
            <div>
                <figure>
                    <img src="${logo}" alt="${full_name}" style="width: 35px;height: 35px;">
                </figure>
                <div class="mesg-meta">
                    <h6><a href="${type == 'people' ? getURI() + '/friend-profile-about?id=' + token : 'javascript: void(0)'}" data-usr="${id}" title="${full_name}">${ucfirst(full_name)}</a></h6>
                    <span>${!isNull(description) ? description : ''}</span>
                </div>
                <div class="add-del-friends h-100 d-flex align-items-center" style="top: 0;">
                    <a href="${type == 'people' ? getURI() + '/friend-profile-about?id=' + token : 'javascript: void(0)'}" data-usr="${id}" title="${full_name}"><i class="fas fa-plus"></i></a>
                </div>
            </div>
        </li>
    `).children[0]
}

try {
    document.querySelector('#top-search').querySelector('form').addEventListener('submit', () => search(searchInput.value), false)
    searchInput.addEventListener('keyup', () => search(searchInput.value), false)
} catch (error) {}