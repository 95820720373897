

const SOCIAL_NETWORK = `YouTube,
Facebook,
WhatsApp,
FB Messenger,
WeChat,
Instagram,
TikTok,
QQ,
Douyin,
Sina Weibo,
Snapchat,
Reddit,
Pinterest,
Telegram,
Twitter,
Quora,
LinkedIn,
Imgur,
Line,
imo,
Brainly,
douban.com,
Yy.com,
Twitch,
VK,
Babytree.com,
Discord,
Likee,
Slack,
Wattpad.com,
csdn.net,
zhanqi.tv,
tianya.cn,
ok.ru,
zalo.me,
Stack Overflow,
DeviantArt,
VSCO,
NextDoor,
aparat.com,
medium.com,
Pixnet.net,
cnblogs.com,
Tumblr,
6.cn,
Bilibili.com,
stackexchange.com,
Tradingview.com,
slideshare.net,
zhihu.com,
Behance.net,
nicovideo.jp,
Steamcommunity.com,
kakao.com,
ameblo.jp ,
9gag.com,
dcard.tw,
namasha.com,
LiveJournal,
ninisite.com,
4chan,
flickr.com,
ptt.cc,
kizlarsoruyor.com,
5ch.net,
hatenablog.com,
renren.com,
plurk.com,
eyny.com,
lihkg.com,
xing.com,
miaopai.com,
dxy.cn,
FetLife,
yammer.com,
weheartit.com,
Parler-Tech,
letterboxd.com,
Omegle,
wykop.pl,
workplace.com,
ask.fm,
Taringa!,
Mixi,
DLive,
MeWe-Tech,
exblog.jp,
2chan.net,
skyrock.com,
mydigit.cn,
NewGrounds,
nnmclub.to,
Hacker News,
Fark,
fuliba2020.net,
computerbase.de,
instiz.net,
Gab-Tech,
skoob.com.br,
Blind`.split(',').map((social, index) => ({ key: index, name: social }))

export default SOCIAL_NETWORK