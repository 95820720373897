import { render } from "./views";

function Weather() {

    return `
        <div class="weather-widget low-opacity bluesh">
            <div class="loader" id="loader">
                <span>Loading ...</span>
            </div>
            <div class="bg-image"
                style="background-image: url(images/resources/weather.jpg)"></div>
            <span class="refresh-content"><i class="fa fa-refresh"></i></span>
            <div class="weather-week">
                <div class="icon sun-shower">
                    <div class="cloud"></div>
                    <div class="sun">
                        <div class="rays"></div>
                    </div>
                    <div class="rain"></div>
                </div>
            </div>
            <div class="weather-infos" style="padding: 0 10px;">
                <span class="weather-tem">25</span>
                <h3>Cloudy Skyes<i>Sicklervilte, New Jersey</i></h3>
                <div class="weather-date skyblue-bg">
                    <span>MAY<strong>21</strong></span>
                </div>
            </div>
            <div class="monthly-weather">
                <ul>
                    <li>
                        <span>Sun</span>
                        <a href="javascript: void(0)" title=""><i class="wi wi-day-sunny"></i></a>
                        <em>40°</em>
                    </li>
                    <li>
                        <span>Mon</span>
                        <a href="javascript: void(0)" title=""><i class="wi wi-day-cloudy"></i></a>
                        <em>10°</em>
                    </li>
                    <li>
                        <span>Tue</span>
                        <a href="javascript: void(0)" title=""><i class="wi wi-day-hail"></i></a>
                        <em>20°</em>
                    </li>
                    <li>
                        <span>Wed</span>
                        <a href="javascript: void(0)" title=""><i class="wi wi-day-lightning"></i></a>
                        <em>34°</em>
                    </li>
                    <li>
                        <span>Thu</span>
                        <a href="javascript: void(0)" title=""><i class="wi wi-day-showers"></i></a>
                        <em>22°</em>
                    </li>
                    <li>
                        <span>Fri</span>
                        <a href="javascript: void(0)" title=""><i class="wi wi-day-windy"></i></a>
                        <em>26°</em>
                    </li>
                    <li>
                        <span>Sat</span>
                        <a href="javascript: void(0)" title=""><i
                                class="wi wi-day-sunny-overcast"></i></a>
                        <em>30°</em>
                    </li>
                </ul>
            </div>
        </div>
    `;
}

render(['div#weather.widget'], Weather());