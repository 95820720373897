// preload image for message list [START]

import axios from "axios";
import {
    isEmpty,
    isEqualWith,
    isNumber
} from "lodash";
import {
    discussionsLoader,
    goToDown,
    messageEventRenderer
} from ".";
import {
    attachment,
    attachmentCloser,
    attachmentContent,
    attachment_options,
    btnArchive,
    btnDelete,
    btnMute,
    btnUnreaded,
    discussionList,
    fileAllowed,
    textInputHoverView
} from "./credentials";
import { imageVideoInner, loaderMessage, messageDomSkeleton, otherFilesInner } from "./messageTemplate";
import {
    dateFormat,
    DomBuilder,
    fileType,
    getElementIndex,
    getLength,
    getSize,
    getURI,
    in_array
} from "./page_actions_main";
const EventEmitter = require('events'),
    event = new EventEmitter();

class Preloader {

    // Initialization
    constructor() {
        this.downloadBtnPressed = false;
        this.loaddata();
        this.currentDiscussion = 0;
        /**
         * @type {Array<HTMLImageElement>}
         */
        this.images = [];
        /**
         * @type {HTMLImageElement|HTMLVideoElement}
         */
        this.currentImage = null;

        this.getter();
    }

    async loaddata() {
        this.discussions = document.querySelectorAll('div.tab-pane.fade.discussion-message');
        this.messageList = document.querySelector('div.tab-pane.fade.discussion-message ul#message-list-item');
        /**
         * @type {HTMLDivElement}
         */
        this.preloaderInner = document.querySelector('div.preloader-inner');
        this.preloaderCloser = document.querySelector('button.preloader-close');
        this.nextBtn = document.querySelectorAll('div.preloader > a')[1];
        this.prevBtn = document.querySelectorAll('div.preloader > a')[0];
        this.width = this.preloaderInner.offsetWidth;
        return true;
    }

    // main method
    async preloader() {
        await this.loaddata();
        // Get current discussion index
        document.querySelectorAll('ul#discussion-list > li, .chat-users > li').forEach((discussion, index) => {
            discussion.addEventListener('click', e => {
                if (discussion.getAttribute('data-state')) {
                    this.currentDiscussion = discussion.getAttribute('data-item').split(',').map(item => parseInt(item.match(/[0-9]+/)[0]))[0];
                } else {
                    this.currentDiscussion = parseInt(discussion.getAttribute('data-item').match(/[0-9]/)[0]);
                }

                this.getImgListByDiscussionId(this.currentDiscussion).forEach(image => {
                    image.addEventListener('click', () => {
                        this.currentImage = image;
                        this.togglePreloader();
                        this.loadPreloadInner(image);
                    }, false);
                });
            }, false);
        });

        // close preloader
        document.querySelector('div.preloader').addEventListener('click', e => {
            let isImage = e.target instanceof HTMLImageElement || e.target instanceof HTMLVideoElement,
                isLink = (Array.from(document.querySelectorAll('div.preloader > a')).filter(link => link === e.target)).length > 0 ? true : false,
                isSpan = (Array.from(document.querySelectorAll('div.preloader > span')).filter(span => span === e.target)).length > 0 ? true : false;

            if (!isImage && !isLink && !isSpan) {
                this.togglePreloader();
            }
        }, false);

        // Go to next image
        this.nextBtn.addEventListener('click', () => {
            this.next();
        }, false);

        // Go to preview image
        this.prevBtn.addEventListener('click', () => {
            this.preview();
        }, false);
    }

    launcheImage() {
        this.getImgListByDiscussionId(this.currentDiscussion).forEach(image => {
            image.addEventListener('click', () => {
                this.currentImage = image;
                this.togglePreloader();
                this.loadPreloadInner(image);
            }, false);
        });
    }

    /**
     * Checks each time what is the id of the currently selected chat
     */
    getter() {
        setInterval(() => {
            document.querySelectorAll('ul#discussion-list > li, .chat-users > li').forEach((discussion, index) => {
                if (discussion.getAttribute('data-state')) {
                    this.currentDiscussion = discussion.getAttribute('data-state') == 'open' ? discussion.getAttribute('data-item').split(',').map(item => parseInt(item.match(/[0-9]+/)[0]))[0] : this.currentDiscussion;
                } else {
                    this.currentDiscussion = discussion.firstElementChild.classList.contains('active') ? parseInt(discussion.getAttribute('data-item').match(/[0-9]/)[0]) : this.currentDiscussion;
                }
            });
            this.downloadFile();
        }, 1000);
    }

    /**
     * Getting all discussions images
     * @param {number} discussion_id
     * @returns {Array<HTMLImageElement|HTMLVideoElement>}
     */
    getImgListByDiscussionId(discussion_id) {
        const discussion_index = getElementIndex(discussion_id),
            /**
             * @type {Array<HTMLImageElement|HTMLVideoElement>}
             */
            images = Array.from(document.querySelectorAll(`ul#message-list-item`)[location.href.match('/messenger') ? discussion_index : 0].querySelectorAll('div.text-box .picture > img, div.text-box .picture > video'));
        this.images = images;
        return this.images;
    }

    /**
     * load all images into preload inner zone
     * @param {HTMLDivElement} preloaderInner
     * @param {HTMLImageElement|HTMLVideoElement} image
     */
    loadPreloadInner(image) {
        this.preloaderInner.innerHTML = ""; //   Wipe content before
        let preloaderType = image instanceof HTMLImageElement ? `
            <img src="${this.currentImage.src}" alt="image attachment" class="mw-100 mh-100">
        ` : `
            <video src="${this.currentImage.src}" controls autoplay class="mw-100 mh-100"></video>
        `,
            preloaderInner = DomBuilder(`
            <div class="preloader-item w-100 h-100 d-flex justify-content-center align-items-center">
                ${preloaderType}
            </div>
        `).querySelector('.preloader-item');

        'append' in this.preloaderInner ? this.preloaderInner.append(preloaderInner) : this.preloaderInner.appendChild(preloaderInner);

        if (this.images.indexOf(this.currentImage) >= (this.images.length - 1)) this.nextBtn.classList.add('disabled');
        if (this.images.indexOf(this.currentImage) == 0) this.prevBtn.classList.add('disabled');
    }

    /**
     * Hide or show preloader container
     */
    togglePreloader() {
        document.querySelector('div.preloader-inner').innerHTML = '';
        if (document.querySelector('div.preloader').classList.contains('d-none')) {
            document.querySelector('div.preloader').classList.remove('d-none');
            this.nextBtn.classList.remove('disabled');
            this.prevBtn.classList.remove('disabled');
        } else {
            document.querySelector('div.preloader').classList.add('d-none');
        }
    }

    /**
     * Next image
     */
    next() {
        if (this.images.indexOf(this.currentImage) >= (this.images.length - 1)) {
            this.nextBtn.classList.add('disabled');
        } else {
            this.nextBtn.classList.remove('disabled');
            this.prevBtn.classList.remove('disabled');

            // Display next image
            this.preloaderInner.firstElementChild.remove();
            this.currentImage = this.images[this.images.indexOf(this.currentImage) + 1];
            let preloaderType = this.currentImage instanceof HTMLImageElement ? `
                <img src="${this.currentImage.src}" alt="image attachment" class="mw-100 mh-100">
            ` : `
                <video src="${this.currentImage.src}" controls class="mw-100 mh-100"></video>
            `,
                preloaderInner = DomBuilder(`
                <div class="preloader-item w-100 h-100 d-flex justify-content-center align-items-center">
                    ${preloaderType}
                </div>
            `).querySelector('.preloader-item');
            if ('append' in this.preloaderInner) {
                this.preloaderInner.append(preloaderInner);
            } else {
                this.preloaderInner.appendChild(preloaderInner);
            }
        }
    }

    /**
     * Preview image
     */
    preview() {
        if (this.images.indexOf(this.currentImage) <= 0) {
            this.prevBtn.classList.add('disabled')
        } else {
            this.nextBtn.classList.remove('disabled');
            this.prevBtn.classList.remove('disabled');

            // Display next image
            this.preloaderInner.firstElementChild.remove();
            this.currentImage = this.images[this.images.indexOf(this.currentImage) - 1];
            let preloaderType = this.currentImage instanceof HTMLImageElement ? `
                <img src="${this.currentImage.src}" alt="image attachment" class="mw-100 mh-100">
            ` : `
                <video src="${this.currentImage.src}" controls class="mw-100 mh-100"></video>
            `,
                preloaderInner = DomBuilder(`
                <div class="preloader-item w-100 h-100 d-flex justify-content-center align-items-center">
                    ${preloaderType}
                </div>
            `).querySelector('.preloader-item');
            if ('append' in this.preloaderInner) {
                this.preloaderInner.append(preloaderInner);
            } else {
                this.preloaderInner.appendChild(preloaderInner);
            }
        }
    }

    downloadFile() {
        // Get current discussion index
        document.querySelectorAll('ul#discussion-list > li').forEach((discussion, index) => {
            document.querySelectorAll('ul#message-list-item')[index].querySelectorAll('li[data-message]').forEach(li => {
                if (!(li instanceof HTMLLIElement) || !isNumber(parseInt(li.getAttribute('data-message'))) || parseInt(li.getAttribute('data-message')) <= 0) return;
                li.querySelectorAll('div.galerie > *').forEach(file => {
                    if (file.innerHTML.match('file-img')) {
                        file.addEventListener('click', () => {
                            if (this.downloadBtnPressed) return;
                            this.downloadBtnPressed = true;
                            setTimeout(() => {
                                this.downloadBtnPressed = false;
                            }, 2000);
                            let files = Array.from(li.querySelectorAll('div.galerie > *')).filter(item => item.innerHTML.match('file-img'));
                            let link = document.createElement('a'),
                                message_data = this.extractDataToDownlod(
                                    Object.values(window.itxyxec.messages[this.currentDiscussion]).filter(message => message.id == parseInt(li.getAttribute('data-message')))[0]
                                )[files.indexOf(file)];
                            link.setAttribute('href', `${getURI()}/banner/discussions/${message_data.name}`);
                            link.setAttribute('download', message_data.original_name);
                            link.click(); //    Run download process ...
                        }, false);
                    }
                });
            });
        });
    }

    extractDataToDownlod(message) {
        return message.attachments[0]['content'].filter(file => {
            if (in_array(file.ext, ['txt', 'pdf', 'zip'])) {
                return file;
            }
        });
    }
}

if (document.querySelector('div.preloader')) {
    let preloader = new Preloader();
    preloader.preloader();
    window.preloader = preloader;
}

// preload image for message list [END]

// Button Actions

const App = (() => {
    btnUnreaded.forEach((btn, key) => {
        setInterval(() => {
            if (in_array('unread', discussionList[key].classList.value.split(' '))) {
                discussionList[key].classList.remove('mute');
            }
            if (discussionList[key].classList.value.split(' ').length == 1) {
                discussionList[key].classList.add('read');
            }
        }, 500);
        btn.addEventListener('click', () => {
            let discussion_id = discussionList[key].getAttribute('data-item').match(/[0-9]/)[0],
                currentState = discussionList[key].classList.contains('unread') ? 'readed' : 'unreaded';
            console.log(currentState);
            axios({
                url: `${getURI()}/api/messenger/toggle-mark-state`,
                method: 'post',
                data: {
                    id: parseInt(discussion_id),
                    user_id: window.id,
                    action: currentState
                }
            }).then(data => {
                console.log(data.data);
                if (data.data.code == 200) {
                    setTimeout(() => {
                        let changed = false;
                        if (isEqualWith(currentState, 'readed') && !changed) {
                            discussionList[key].classList.replace('unread', 'read');
                            btn.innerHTML = `<i class="fa fa-envelope"></i> Mark Unread`;
                            changed = true;
                        }
                        if (isEqualWith(currentState, 'unreaded') && !changed) {
                            discussionList[key].classList.replace('read', 'unread');
                            btn.innerHTML = `<i class="fa fa-envelope"></i> Mark read`;
                            changed = true;
                        }
                        changed = false;
                    }, 1000);
                }
            })
        }, false);
    });

    btnMute.forEach((btn, key) => {
        btn.addEventListener('click', () => {
            let discussion_id = parseInt(discussionList[key].getAttribute('data-item').match(/[0-9]/)[0]),
                currentState = window.itxyxec.discussions[discussion_id].state;

            console.log(currentState);
            axios({
                url: `${getURI()}/api/messenger/discussion-mute`,
                method: 'post',
                data: {
                    id: discussion_id,
                    user_id: window.id,
                    action: currentState == 'enabled' ? 'muted' : 'enabled'
                }
            }).then(data => {
                if (data.data.code == 200) {
                    const disc = discussionsLoader();
                    if (!isNumber(disc)) {
                        disc.then((response) => {
                            let changed = false;
                            if (isEqualWith(currentState, 'enabled') && !changed) {
                                btn.innerHTML = `<i class="fa fa-bell-slash-o"></i> UnMute`;
                                discussionList[getElementIndex(discussion_id)].classList.remove('read');
                                discussionList[getElementIndex(discussion_id)].classList.remove('unread');
                                discussionList[getElementIndex(discussion_id)].classList.add('mute');
                                changed = true;
                            }
                            if (isEqualWith(currentState, 'muted') && !changed) {
                                discussionList[getElementIndex(discussion_id)].classList.remove('mute');
                                discussionList[getElementIndex(discussion_id)].classList.add('read');
                                btn.innerHTML = `<i class="fa fa-bell-slash-o"></i> Mute`;
                                changed = true;
                            }
                        });
                    }
                }
            })
        }, false);
    });

    btnDelete.forEach((btn, key) => {
        btn.addEventListener('click', () => {
            let discussion_id = parseInt(discussionList[key].getAttribute('data-item').match(/[0-9]/)[0]),
                currentState = window.itxyxec.discussions[discussion_id].state;
            if (!confirm('Are you shure that you wan to delete it ?')) {
                return false;
            }
            axios({
                url: `${getURI()}/api/messenger/discussion-delete`,
                method: 'post',
                data: {
                    id: discussion_id,
                    user_id: window.id
                }
            }).then(data => {
                if (data.data.code == 200) {
                    const disc = discussionsLoader();
                    if (!isNumber(disc)) {
                        disc.then((response) => {
                            discussionList[getElementIndex(discussion_id)].remove();
                            document.querySelector(`div.tab-pane#link${discussion_id}`).remove();
                        });
                    }
                }
            })
        }, false);
    });

    btnArchive.forEach((btn, key) => {
        btn.addEventListener('click', () => {
            let discussion_id = parseInt(discussionList[key].getAttribute('data-item').match(/[0-9]/)[0]),
                currentState = window.itxyxec.discussions[discussion_id].state;
            console.log(currentState);
            axios({
                url: `${getURI()}/api/messenger/discussion-archive`,
                method: 'post',
                data: {
                    id: discussion_id,
                    user_id: window.id
                }
            }).then(data => {
                console.log(data.data);
                if (data.data.code == 200) {
                    const disc = discussionsLoader();
                    if (!isNumber(disc)) {
                        disc.then((response) => {
                            let index = getElementIndex(discussion_id),
                                image = document.querySelectorAll(`ul#discussion-list #figure-cap img`)[index].getAttribute('src'),
                                name = document.querySelectorAll(`ul#discussion-list #user-name-discussion`)[index].textContent,
                                description = document.querySelectorAll(`ul#discussion-list #user-hoverview-discussion`)[index].textContent.slice(0, 9),
                                date = document.querySelectorAll(`ul#discussion-list #user-date-discussion`)[index].textContent.replace('.000000Z', '').replace('T', ' '),
                                time = new Date(date),
                                content = `
                                <li data-discussion="it${discussion_id}em">
                                    <div class="row w-75 mx-auto">
                                        <div class="col-md-8">
                                            <div class="comet-avatar">
                                                <img alt="Archive image" src="${image}">
                                            </div>
                                            <div class="we-comment">
                                                <h5>${name}</h5><br>
                                                <p>${description}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="inline-itms">
                                                <span>${time.getDate() < 10 ? '0'+time.getDate() : time.getDate()}/${time.getMonth() < 10 ? '0'+time.getMonth() : time.getMonth()}/${time.getFullYear()}</span> <br>
                                                <button onclick="unarchive(${discussion_id})" type="submit" class="btn btn-sm btn-outline-secondary" data-ripple="">Unarchive</button>
                                            </div>
                                        </div>
                                    </div>
                                </li>`;
                            document.querySelector('ul#archive-list').innerHTML += content;
                            discussionList[getElementIndex(discussion_id)].remove();
                            document.querySelector(`div.tab-pane#link${discussion_id}`).remove();
                        });
                    }
                }
            })
        }, false);
    });

    window.unarchive = (discussion_id) => {
        axios({
            url: `${getURI()}/api/messenger/discussion-unarchive`,
            method: 'post',
            data: {
                id: discussion_id,
                user_id: window.id
            }
        }).then(data => {
            console.log(data.data);
            if (data.data.code == 200) {
                window.location.reload();
            }
        })
    }

    document.querySelector('li#post-mute-all').addEventListener('click', () => {
        discussionList.forEach((btn, key) => {
            let discussion_id = parseInt(discussionList[key].getAttribute('data-item').match(/[0-9]/)[0]),
                currentState = window.itxyxec.discussions[discussion_id].state;
            axios({
                url: `${getURI()}/api/messenger/discussion-mute`,
                method: 'post',
                data: {
                    id: discussion_id,
                    user_id: window.id,
                    action: currentState == 'enabled' ? 'muted' : 'enabled'
                }
            }).then(data => {
                console.log(data.data);
                if (data.data.code == 200) {
                    setTimeout(() => {
                        const disc = discussionsLoader();
                        if (!isNumber(disc)) {
                            disc.then((response) => {
                                let changed = false;
                                if (isEqualWith(currentState, 'enabled') && !changed) {
                                    btnMute[key].innerHTML = `<i class="fa fa-bell-slash-o"></i> UnMute`;
                                    discussionList[getElementIndex(discussion_id)].classList.remove('read');
                                    discussionList[getElementIndex(discussion_id)].classList.remove('unread');
                                    discussionList[getElementIndex(discussion_id)].classList.add('mute');
                                    changed = true;
                                }
                                if (isEqualWith(currentState, 'muted') && !changed) {
                                    discussionList[getElementIndex(discussion_id)].classList.remove('mute');
                                    discussionList[getElementIndex(discussion_id)].classList.add('read');
                                    btnMute[key].innerHTML = `<i class="fa fa-bell-slash-o"></i> Mute`;
                                    changed = true;
                                }
                            });
                        }
                    }, 1000);
                }
            })
        });
    }, false);
});

if (btnArchive.length > 0) {
    App();
}

// Tchat page actions
// Toggle options
attachment_options.toggleBtn.forEach((option, index) => { //    Open and and close attachment option tab option
    const initial_state = 'option-content d-flex align-items-center justify-content-center'
    option.addEventListener('click', () => {
        if (option.querySelector('span.fa').classList.contains('fa-plus')) {
            option.querySelector('span.fa').classList.replace('fa-plus', 'fa-close');
            if (attachment_options["option-content"][index].classList.contains('close-option')) {
                attachment_options["option-content"][index].classList.replace('close-option', 'open-option')
            } else {
                attachment_options["option-content"][index].classList.value = 'option-content d-flex align-items-center justify-content-center open-option'
            }
        } else if (option.querySelector('span.fa').classList.contains('fa-close')) {
            option.querySelector('span.fa').classList.replace('fa-close', 'fa-plus')
            if (attachment_options["option-content"][index].classList.contains('open-option')) {
                attachment_options["option-content"][index].classList.replace('open-option', 'close-option')
                attachment_options["option-content"][index].addEventListener('animationend', (e) => {
                    if (attachment_options["option-content"][index].classList.contains('close-option')) {
                        attachment_options["option-content"][index].classList.value = initial_state
                    }
                }, false)
            } else {
                attachment_options["option-content"][index].classList.value = 'option-content d-flex align-items-center justify-content-center close-option'
            }
        } else {
            option.querySelector('span.fa').classList.value = 'fa fa-plus'
        }
    }, false)
});

/**
 * Call when load picture file
 * @param {Event} e
 * @param {number} key
 * @returns { Promise<{ fileAtt: Array<File>; tmpFile: Array<{ file: string; token: string; data: { ext: string; name: string; size: Array<string | number> | string } }> }> }
 */
export function pictureChangeAction(e, key) {
    /**
     * @type {Array<File>}
     */
    let files = Array.from(e.target.files),
        ext,
        global = {
            /**
             * @type { Array<File> }
             */
            fileAtt: [],
            /**
             * @type { Array<{ file: string; token: string; data: { ext: string; name: string; size: Array<string | number> | string } }> }
             */
            tmpFile: []
        },
        progress = [];

    return new Promise(function(resolve, reject) {
        files.forEach((file, index) => {
            event.on(`event${index}`, () => {
                progress.push(index);
                if (files.length == progress.length) {
                    if (displayFile(global.tmpFile, key)) {
                        resolve(global);
                    }
                }
            })
        })

        if (isEmpty(files) || files.length <= 0) {
            reject(new Error('Not file'))
        }
        e.target.value = "";
        files.forEach((file, index) => { // Check files one by one
            // Get file type
            ext = file['name'].split('.')[file['name'].split('.').length - 1];
            if (in_array(ext, ['jpeg', 'jpg', 'png', 'JPEG', 'JPG', 'PNG']) && (getSize(file['size'])[0] == 'Kb' || (getSize(file['size'])[0] == 'Mo' && getSize(file['size'])[1] <= 1))) {
                global.fileAtt.push(file); // Save file in his raw state
                let fileReader = new FileReader();
                fileReader.addEventListener('load', e => {
                    let tmpFile = {
                        file: e.target.result,
                        token: Math.random(3000).toString(),
                        data: {
                            ext: ext,
                            name: file['name'],
                            size: getSize(file['size'])
                        }
                    };
                    global.tmpFile.push(tmpFile); //  Save file in his modified state
                    event.emit(`event${index}`);
                }, false);
                fileReader.readAsDataURL(file);
            } else {
                event.emit(`event${index}`)
            }
        });
    });
}

/**
 * Call when load file
 * @param {Event} e
 * @param {number} key
 * @returns { Promise<{ fileAtt: Array<File>; tmpFile: Array<{ file: string; token: string; data: { ext: string; name: string; size: Array<string | number> | string } }> }> }
 */
export function fileChangeAction(e, key) {
    /**
     * @type {Array<File>}
     */
    let files = Array.from(e.target.files),
        ext,
        global = {
            /**
             * @type { Array<File> }
             */
            fileAtt: [],
            /**
             * @type { Array<{ file: string; data: { ext: string; name: string; size: Array<string | number> | string } }> }
             */
            tmpFile: []
        },
        progress = [];

    return new Promise(function(resolve, reject) {
        files.forEach((file, index) => {
            event.on(`event${index}`, () => {
                progress.push(index);
                if (files.length == progress.length) {
                    if (displayFile(global.tmpFile, key)) {
                        resolve(global);
                    }
                }
            })
        })

        if (isEmpty(files) || files.length <= 0) {
            reject(new Error('Not file'))
        }
        e.target.value = "";
        files.forEach((file, index) => { // Check files one by one
            // Get file type
            ext = file['name'].split('.')[file['name'].split('.').length - 1];
            if (in_array(ext, ['mp3', 'mp4', '3gp', 'txt', 'pdf', 'zip']) && (getSize(file['size'])[0] == 'b' || getSize(file['size'])[0] == 'Kb' || (getSize(file['size'])[0] == 'Mo' && getSize(file['size'])[1] <= 30))) {
                global.fileAtt.push(file) // Save file in his raw state
                let tmpFile = {
                    file: file,
                    token: Math.random(3000).toString(),
                    data: {
                        ext: ext,
                        name: file['name'],
                        size: getSize(file['size'])
                    }
                };
                global.tmpFile.push(tmpFile); //  Save file in his modified state
                event.emit(`event${index}`)
            } else {
                event.emit(`event${index}`)
            }
        });
    });
}

/**
 * Get if we are in messages page
 * @returns {boolean}
 */
export function hasMessagePage() {
    return document.querySelector('div#chat-box') ? false : true;
}

/**
 * Display attachment files
 * @param { Array<{ file: string; token: string; data: { ext: string; name: string; size: Array<string | number> | string } }> } files
 * @param {number} target
 */
export function displayFile(files, target) {
    let contents = [],
        messagePage = hasMessagePage() ? 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6' : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12';
    files.forEach((file, index) => {
        if (in_array(file.data.ext.toLowerCase(), ['jpeg', 'jpg', 'png'])) { // Build image badge DOM
            contents.push(DomBuilder(`
                <div class="${messagePage} file-item position-relative" data-item="${file.token}">
                    <a href="javascript: void(0)" class="attachment-closer" id="close-item">x</a>
                    <div class="w-100 h-100 img_att">
                        <img src="${file.file}" alt="Image attachment file">
                    </div>
                </div>
            `).querySelector('div.position-relative'))
        }
        if (in_array(file.data.ext.toLowerCase(), ['mp3', 'txt', 'pdf', 'zip'])) { // Build document badge DOM
            contents.push(DomBuilder(`
                <div class="${messagePage} file-item position-relative" data-item="${file.token}">
                    <a href="javascript: void(0)" class="attachment-closer" id="close-item">x</a>
                    <div class="w-100 h-100 p-2 file_att">
                        <h5 class="text-muted">${file.data.name}</h5>
                        <div class="d-flex">
                            <div>
                                <img src="${getURI()}/file_icon/${file.data.ext}.png" width="60%" alt="File icon">
                            </div>
                            <div>
                                <strong>Size: </strong> ${file.data.size[1]}${file.data.size[0]} <br>
                                <strong>Type: </strong> ${fileType(file.data.ext)} <br>
                                <strong>Last modification: </strong> ${dateFormat(file.file.lastModified | file.data.time)} <br>
                            </div>
                        </div>
                    </div>
                </div>
            `).querySelector('div.position-relative'))
        }
        if (in_array(file.data.ext.toLowerCase(), fileAllowed.slice(4, 5))) { // Build video badge DOM
            contents.push(DomBuilder(`
                <div class="${messagePage} file-item position-relative" data-item="${file.token}">
                    <a href="javascript: void(0)" class="attachment-closer" id="close-item">x</a>
                    <div class="w-100 h-100 movie_att">
                        <video controls>
                            <source src="${window.URL.createObjectURL(file.file)}" type="${file.file.type}">
                        </video>
                    </div>
                </div>
            `).querySelector('div.position-relative'))
        }
        attachmentContent["attachment-inner"](target).appendChild(contents[index]); //  Push element to badge list attachment
    });
    formatTmpFiles();

    event.on('ready', () => {
        document.querySelectorAll('a.attachment-closer#close-item').forEach((closer, child_item) => {
            closer.addEventListener('click', () => { // Close every attachment badge
                // Remove it to the attachment list
                delete window.fileAtt[child_item];
                delete window.tmpFile[child_item];
                closer.parentElement.remove();
                window.fileAtt = getLength(window.fileAtt) == 0 ? [] : window.fileAtt
                window.tmpFile = getLength(window.tmpFile) == 0 ? [] : window.tmpFile
                formatTmpFiles();
            }, false)
        });
        attachmentCloser.forEach((closer, index) => {
            closer.addEventListener('click', () => { // Close attachment panel view
                attachment[index].classList.value = "attachment-content d-none";
                window.tmpFile = [];
                window.fileAtt = [];
                Array.from(attachmentContent['attachment-inner'](index).children).forEach(item => {
                    item.remove();
                })
            }, false)
        });
    });
    return true;
}

export function formatTmpFiles() {
    window.tmpFile = window.tmpFile.filter(item => typeof item !== 'undefined' || item !== null);
    window.fileAtt = window.fileAtt.filter(item => typeof item !== 'undefined' || item !== null);
}

/**
 * @param { Promise<{ fileAtt: File[]; tmpFile: { file: string; token: string; data: { ext: string; name: string; size: string | (string | number)[]; }; }[]; }> } func
 * @returns {void} Nothing
 */
export async function changeFileState(func) { //    Add attachment to files list
    let data = await func.then(response => response);
    window.fileAtt = [...window.fileAtt, ...data.fileAtt];
    window.tmpFile = [...window.tmpFile, ...data.tmpFile];
    delete data.fileAtt;
    delete data.tmpFile;
    event.emit('ready');
}

attachment_options['option-content'].forEach((option, index) => {
    option.querySelectorAll('input[type="file"]').forEach(input => {
        input.addEventListener('change', (e) => { //    Display attachment panel view when some file has been selected
            if (e.target.files.length > 0 && attachment[index].classList.contains('d-none')) {
                attachment[index].classList.remove('d-none');
            }
        })
    })
    option.querySelectorAll('a[data-type]').forEach((recorderBtn, key) => {
        recorderBtn.addEventListener('click', (e) => {
            attachment_options.toggleBtn[index].click(); // Close options tabs panel
            switch (recorderBtn.getAttribute('data-type')) {
                case 'video':
                    runRecorderView(recorderBtn.getAttribute('data-type'), index);
                    break;
                case 'audio':
                    runRecorderView(recorderBtn.getAttribute('data-type'), index);
                    break;

                default:
                    break;
            }
        })
    })
});

/**
 * run Media Screen Recorder
 * @param { 'audio' | 'video' } type type of recorder to run
 * @param { number } index Index of the discussion in which we are
 */
function runRecorderView(type, index) {
    if (!in_array(type, ['video', 'audio'])) {
        return;
    }
    attachmentContent['video-close-content'][index].parentElement.parentElement.classList.value = 'recorder-container'; //  Display recorder panel
    const constraints = {
        audio: true,
        video: type == 'video' ? true : false,
        facingMode: {
            exact: "user"
        }
    };
    if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
    }

    if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {

            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            if (!getUserMedia) { // Decline call if is not present in browser
                const alert = DomBuilder(`
                    <div class="alert alert-warning alert-message alert-dismissible fade show" role="alert">
                        Your browser does not support Voice and Video recorder.
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                `).querySelector('div.alert');
                document.body.appendChild(alert);
                return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
            }

            return new Promise(function(resolve, reject) {
                getUserMedia.call(navigator, constraints, resolve, reject);
            });
        }
    }
    /**
     * @type {HTMLVideoElement[]}
     */
    let video = document.querySelectorAll('video#video-view'),
        timer = document.querySelectorAll('span#video-timer'),
        stopBtn = document.querySelectorAll('a#video-stop'),
        parts = [],
        stopBtnUsed = true,
        recorder_ready_used = false,
        /**
         * @type { Blob }
         */
        media;
    navigator.mediaDevices.getUserMedia(constraints)
        .then(function(stream) {
            let recorder = new MediaRecorder(stream);
            recorder.addEventListener('dataavailable', e => {
                parts.push(e.data);
            }, false);
            video[index].srcObject = stream;
            recorder.start(1000); //    start recorder
            video[index].play(); // play video
            video[index].controls = false;
            video[index].volume = 0;

            // Timer
            let hours = 0,
                minutes = 0,
                seconds = 0,
                timerTime = setInterval(() => {
                    seconds++;
                    if (seconds > 59) {
                        seconds = 0;
                        minutes++;
                    }
                    if (minutes > 59) {
                        minutes = 0;
                        hours++;
                    }
                    let tmpHours = hours < 10 ? '0' + hours.toString() : hours,
                        tmpMinutes = minutes < 10 ? '0' + minutes.toString() : minutes,
                        tmpSeconds = seconds < 10 ? '0' + seconds.toString() : seconds;

                    timer[index].textContent = `${tmpHours}:${tmpMinutes}:${tmpSeconds}`;
                }, 1000);

            stopBtn[index].addEventListener('click', () => { // Stop recording
                if (recorder_ready_used) {
                    return;
                }
                recorder_ready_used = true;
                if (stopBtnUsed == false) { //  If we are not used a stop button to stop recording, we reset all things
                    stopBtnUsed = true;
                    try {
                        recorder.stop(); // Stop recorder
                        clearInterval(timerTime);
                        timer[index].textContent = `00:00:00`;
                        parts = [];
                        stream.getTracks().forEach((track) => {
                            track.enabled = false;
                            track.stop();
                            video[index].srcObject = null;
                        });
                    } catch (err) {}
                    return;
                }
                media = new Blob(parts, {
                    type: (type == 'audio' ? 'audio/mpeg' : 'video/webm')
                }); // save media
                parts = [];
                let token = Math.random(3000).toString(),
                    tmpFile = [{
                        file: media,
                        token: token,
                        data: {
                            ext: type == 'audio' ? 'mp3' : 'mp4',
                            name: `spadli_${token.toUpperCase()}.${type == 'audio' ? 'mp3' : 'mp4'}`,
                            size: getSize(media.size),
                            time: new Date()
                        }
                    }],
                    fileAtt = [media];
                attachment[index].classList.value = 'attachment-content';
                attachmentContent["video-close-content"][index].parentElement.parentElement.classList.value = 'recorder-container d-none';
                displayFile(tmpFile, index);
                event.emit('ready');
                window.tmpFile.push(tmpFile[0]);
                window.fileAtt.push(fileAtt[0]);
                try {
                    recorder.stop(); // Stop recorder
                    clearInterval(timerTime);
                    timer[index].textContent = `00:00:00`;
                    stream.getTracks().forEach((track) => {
                        track.enabled = false;
                        track.stop();
                        video[index].srcObject = null;
                    });
                } catch (err) {}
            }, false);
        })
        .catch(function(err) {
            console.error(err.name + ": " + err.message);
        });

    attachmentContent["video-close-content"].forEach((closer, index) => {
        closer.addEventListener('click', () => {
            stopBtnUsed = false;
            stopBtn[index].click();
            closer.parentElement.parentElement.classList.value = 'recorder-container d-none';
        }, false)
    })
}

/**
 * Submit datas
 * @param {HTMLFormElement} form Form
 * @param {number} key Index of form
 * @returns { Promise<[{ token: string; message: string; disc: number; user: string; corr: number; URI: string; file: [{ file: string | null; data: { ext: string; name: string; size: string | (string | number)[]; }; }], isMixed: boolean; }] | []> } Promise
 */
export function sendMessage(form, key) {
    return new Promise(function(resolve) {
        /**
         * @type {HTMLTextAreaElement}
         */
        let textInput = document.querySelectorAll('form#form-msg textarea#text-input')[key] || document.createElement('textarea'),
            message = isEmpty(textInput.value) ? '' : textInput.value.trim(),
            discussion = form.getAttribute('data-item')
            .match(/[0-9,]+/)[0]
            .split(',')
            .map(item => parseInt(item)),
            correspondent = discussion[1],
            discussion_id = discussion[0],
            isMixed = false, // True if message is associated by some attachment file
            /**
             * @type {Array<File>}
             */
            files = [],
            tk = window.tk, //  My user ID
            me = window.tkinter; // Csrf token

        if (isEmpty(message) && getLength(window.fileAtt) == 0) {
            resolve([]);
        }
        if (getLength(window.fileAtt) !== 0) {
            window.tmpFile.forEach(file => {
                if (in_array(file.data.ext, ['png', 'jpg', 'jpeg'])) {
                    files.push(file);
                } else {
                    files.push({
                        ...file,
                        ... {
                            file: null
                        }
                    });
                }
            })
            isMixed = true;
        }

        textInput.value = '';
        textInputHoverView[key].innerHTML = '';
        textInputHoverView[key].focus();
        resolve({
            token: tk,
            message: message,
            disc: discussion_id,
            user: me,
            corr: correspondent,
            URI: getURI(),
            file: files,
            isMixed: isMixed
        });
    });
}

/**
 * Save messages
 * @param {{ token: string; message: string; disc: number; user: string; corr: number; URI: string; file: [{ file: string | null; data: { ext: string; name: string; size: string | (string | number)[]; }; }], isMixed: boolean; form: { type: 'new-message'|'old-message'; discussion_id: number; message_id: number; user: number; } }} setting
 * @param {number} key
 * @returns {Promise<{ message: string; file_name: string[]; date: string; message_id: number; form: { type: 'new-message'|'old-message'; discussion_id: number; message_id: number; user: number; } }>}
 */
export const saveMessage = async(setting, key) => {
    return new Promise(async resolve => {
        /**
         * Response events
         */
        const wipe = () => {
            window.fileAtt = [];
            window.tmpFile = [];
            Array.from(attachmentContent["attachment-inner"](key).children).forEach(item => item.remove());
        };
        /**
         * @param {string[]} data
         * @returns { { message: string; file_name: string[]; message_id: number; } }
         */
        function answer(data) {
            return {
                message: setting.message,
                ...data
            };
        }
        event.on('all_files_uploaded', (data) => {
            wipe();
            resolve(answer({...data, ... { form: setting.form } }));
        });
        event.on('something_when_wrong', (data) => {
            wipe();
            resolve(answer(data));
        });
        // End
        attachmentCloser[key].parentElement.parentElement.classList.value = 'attachment-content d-none'; // Hide attachment panel view
        if (setting.form.type == 'new-message') {
            if (setting.isMixed) { //  If message content attachment
                let response = await uploadFile(setting, key).then(response => response)
                if (response.state) {
                    event.emit('all_files_uploaded', response);
                } else {
                    event.emit('something_when_wrong', []);
                }
            } else { //  If only message sent
                axios({
                    method: 'POST',
                    url: `${setting.URI}/api/send/message`,
                    data: {
                        message: setting.message,
                        disc: setting.disc,
                        user_id: setting.user,
                        date: new Date().toString().split(' ').slice(0, 5).join(' ')
                    }
                }).then((data) => {
                    if (data.data.code == 200) {
                        let date = new Date(),
                            message_dom = messageDomSkeleton(
                                document.querySelector('div#logo-user-smaller img').getAttribute('src'),
                                setting.message,
                                `${date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes()}`,
                                'me',
                                data.data.message_id
                            );
                        document.querySelectorAll('ul#message-list-item')[key].insertBefore(message_dom, document.querySelectorAll('ul#message-list-item li#onWriting')[key]);
                        event.emit('all_files_uploaded', {
                            state: true,
                            /**
                             * @type {string[]}
                             */
                            file_name: [],
                            message_id: data.data.message_id,
                            date: `${date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes()}`
                        });
                        messageEventRenderer();
                    } else {
                        event.emit('something_when_wrong', []);
                    }
                });
            }
        } else {
            axios({
                method: 'POST',
                url: `${setting.URI}/api/update/message`,
                data: {
                    message: setting.message,
                    discussion_id: setting.form.discussion_id,
                    message_id: setting.form.message_id,
                    user_id: setting.user
                }
            }).then((data) => {
                if (data.data.code == 200) {
                    let date = new Date();
                    // Update message un message list
                    document.querySelectorAll('ul#message-list-item')[getElementIndex(setting.form.discussion_id)].querySelector(`li[data-message="${setting.form.message_id}"] div.text-box > p`).textContent = setting.message;
                    event.emit('all_files_uploaded', {
                        state: true,
                        /**
                         * @type {string[]}
                         */
                        file_name: [],
                        message_id: data.data.message_id,
                        date: `${date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes()}`
                    });
                    messageEventRenderer();
                } else {
                    event.emit('something_when_wrong', []);
                }
            });
        }
    })
}

/**
 * Upload attachment files and save message text
 * @param {Array<File | Blob>} files
 * @param {{ token: string; message: string; disc: number; user: number; corr: number; URI: string; file: [{ file: string | null; data: { ext: string; name: string; size: string | (string | number)[]; }; }], isMixed: boolean; }} setting
 * @param {number} index
 * @param {number} key
 * @returns {Promise<{ state: boolean; file_name: string[]; date: string; message_id: number; }>}
 */
export async function uploadFile(setting, key) {
    return new Promise(resolve => {
        /**
         * Prepare data to upload
         */
        let form = new FormData(),
            date = new Date(),
            finalName = (name, extension) => {
                /**
                 * @type {string} single_name
                 */
                let single_name = name.split(extension)[0];
                single_name = single_name.replace(/( |\.)+/g, '');
                return `spadli_${Math.random().toString().slice(2, 10) + '-' + single_name}.${extension}`;
            },
            data = {
                ...setting,
                ... {
                    // Math.random(50).toString().slice(2, 10)
                    file: setting.file.map(file => {
                        return {
                            ...file.data,
                            ... {
                                final_name: finalName(file.data.name, file.data.ext)
                            }
                        }
                    })
                }
            };
        window.fileAtt.forEach((file, _index) => {
            form.append(`file${_index}`, convertBlobToFile(file, window.tmpFile[_index].data.name));
        });
        form.append('user_id', setting.user);
        form.append('data', JSON.stringify(data));
        form.append('date', date.toString().split(' ').slice(0, 5).join(' '));

        /**
         * Prepare DOM and display it
         */
        let loader = loaderMessage(),
            message_dom = messageDomSkeleton(
                document.querySelector('div#logo-user-smaller img').getAttribute('src'),
                setting.message,
                `${date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes()}`,
                'me',
                0
            ),
            /**
             * @type {HTMLDivElement[]}
             */
            file_dom = [];
        setting.file.forEach((file, _index) => {
            let file_name = file.data.name.replace('.' + file.data.ext, '').slice(0, 19) + '...' + file.data.ext,
                video = new Blob([window.fileAtt[_index]], { type: window.fileAtt[_index].type }),
                image = new Blob([window.fileAtt[_index]], { type: window.fileAtt[_index].type });
            if (in_array(file.data.ext.toLowerCase(), ['jpeg', 'jpg', 'png', 'mp4', '3gp'])) { // images | videos
                file_dom.push(imageVideoInner(file.data.ext, data.file[_index].final_name));
            } else if (in_array(file.data.ext.toLowerCase(), ['mp3', 'txt', 'pdf', 'zip'])) { // documents
                file_dom.push(otherFilesInner({
                    final_name: data.file[_index].final_name,
                    file_name: file_name,
                    ext: file.data.ext,
                    size: file.data.size
                }));
            } else {
                file_dom.push(DomBuilder(`<i class="position-absolute invisible"></i>`).querySelector('i'));
            }
        });
        message_dom.querySelector('div.text-box div.galerie').appendChild(loader);
        document.querySelectorAll('ul#message-list-item')[key].insertBefore(message_dom, document.querySelectorAll('ul#message-list-item li#onWriting')[key]);
        messageEventRenderer();
        goToDown();

        /**
         * Prepare request
         */
        let xhr = new XMLHttpRequest(),
            ready_display = false;
        xhr.open('POST', `${getURI()}/api/messenger/upload-file`);
        xhr.withCredentials = true;
        xhr.responseType = 'json'

        xhr.upload.addEventListener('progress', e => {
            if (e.lengthComputable) {
                let percent = Math.ceil((e.loaded / e.total) * 100);
                message_dom.querySelector('div.pending span#loader').textContent = `${percent}%`;
                if (percent == 100) {
                    if (!ready_display) {
                        ready_display = true;
                    }
                }
            }
        });
        xhr.addEventListener('readystatechange', e => {
            if (xhr.DONE && xhr.readyState == 4) {
                /**
                 * @type { { code: 200|400; file_name: { final_name: string; ext: string; name: string; size: string | (string | number)[]; }[]; message_id: number } }
                 */
                const response = xhr.response;
                if (response.code == 200) {
                    message_dom.setAttribute('data-message', response.message_id);
                    message_dom.querySelector('div.pending').classList.add('d-none');
                    message_dom.querySelector('div.text-box div.galerie').children[0].remove();
                    file_dom.forEach(dom => message_dom.querySelector('div.text-box div.galerie').appendChild(dom));
                    resolve({
                        state: true,
                        file_name: response.file_name.map(name => name.final_name),
                        message_id: response.message_id,
                        date: `${date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes()}`
                    });
                } else {
                    message_dom.querySelector('div.text-box div.galerie').children[0].remove();
                    resolve({
                        state: false,
                        /**
                         * @type {string[]}
                         */
                        file_name: {},
                        date: ''
                    });
                }
            }
        }, false);
        xhr.send(form);
    });
}

/**
 * Convert Blob to File
 * @param {Blob|File} blob
 * @param {string} file_name
 * @returns {File}
 */
export function convertBlobToFile(blob, file_name) {
    if (blob instanceof File) {
        return blob;
    }

    const date = new Date()
    blob.lastModifie = date.getTime();
    blob.lastModifieDate = date;
    blob.name = file_name;
    return blob;
}
