// cover_profile
let call_audio = document.querySelectorAll('li[class="audio-call"]'),
    call_audio_option = document.querySelectorAll('li[class="audio-call-option"]'),
    call_video_option = document.querySelectorAll('li[class="video-call-option"]'),
    call_video = document.querySelectorAll('li[class="video-call"]#video-call'),
    init_user_logo = document.querySelectorAll('img#user-logo'),
    init_user_logo_video = document.querySelectorAll('span#cover_profile'),
    init_user_name = document.querySelectorAll('h6#user-name'),
    new_user_logo = document.querySelector('img#corr-img'),
    new_user_name = document.querySelector('h6#corr-user'),
    new_user_logo_video_call = document.querySelector('img#corr-img-banner-call'),
    new_user_video_call = document.querySelectorAll('img#corr-img-video-call'),
    new_user_name_video_call = document.querySelector('h6#corr-user-video-call'),
    acceptCall = document.querySelector('div.yesorno > a.accept-call'),
    toggleMuteCall = document.querySelector('div.yesorno > a.mute-call'),
    declineCall = document.querySelector('div.yesorno > a.bg-red.decline-call'),
    audio_call_souce = document.querySelector('#audio-call-souce-place'),
    /**
     * @type {HTMLVideoElement}
     */
    video_call_souce = document.querySelector('#corr-video-banner-call'),
    incoming_call_audio = document.querySelector('#incoming-call-audio'),
    incoming_call_audio_state = document.querySelector('#incoming-call-audio-state'),
    incoming_call_video = document.querySelector('#incoming-call-video'),
    incoming_call_video_state = document.querySelector('#incoming-call-video-state'),
    acceptVideoCall = document.querySelector('#control-point > a.accept-call'),
    toggleMuteVideoCall = document.querySelector('#control-point > a.mute-call'),
    toggleMuteVideoTrackCall = document.querySelector('div.yesorno > a.mute-video-call'),
    declineVideoCall = document.querySelector('#control-point > a.bg-red.decline-call'),
    
    audioCallBulle = document.querySelector('#audio-call-bulle'),
    videoCallBulle = document.querySelector('#video-call-bulle');



const _call_audio = call_audio;
export { _call_audio as call_audio };
const _call_audio_option = call_audio_option;
export { _call_audio_option as call_audio_option };
const _call_video = call_video;
export { _call_video as call_video };
const _call_video_option = call_video_option;
export { _call_video_option as call_video_option };
const _init_user_logo = init_user_logo;
export { _init_user_logo as init_user_logo };
const _init_user_logo_video = init_user_logo_video;
export { _init_user_logo_video as init_user_logo_video };
const _init_user_name = init_user_name;
export { _init_user_name as init_user_name };
const _new_user_logo = new_user_logo;
export { _new_user_logo as new_user_logo };
const _new_user_name = new_user_name;
export { _new_user_name as new_user_name };
const _acceptCall = acceptCall;
export { _acceptCall as acceptCall };
const _declineCall = declineCall;
export { _declineCall as declineCall };
const _audio_call_souce = audio_call_souce;
export { _audio_call_souce as audio_call_souce };
const _video_call_souce = video_call_souce;
export { _video_call_souce as video_call_souce };
const _incoming_call_audio = incoming_call_audio;
export { _incoming_call_audio as incoming_call_audio };
const _incoming_call_audio_state = incoming_call_audio_state;
export { _incoming_call_audio_state as incoming_call_audio_state };
const _incoming_call_video = incoming_call_video;
export { _incoming_call_video as incoming_call_video };
const _incoming_call_video_state = incoming_call_video_state;
export { _incoming_call_video_state as incoming_call_video_state };
const _new_user_logo_video_call = new_user_logo_video_call;
export { _new_user_logo_video_call as new_user_logo_video_call };
const _new_user_video_call = new_user_video_call;
export { _new_user_video_call as new_user_video_call };
const _new_user_name_video_call = new_user_name_video_call;
export { _new_user_name_video_call as new_user_name_video_call };
const _acceptVideoCall = acceptVideoCall;
export { _acceptVideoCall as acceptVideoCall };
const _declineVideoCall = declineVideoCall;
export { _declineVideoCall as declineVideoCall };
const _toggleMuteCall = toggleMuteCall;
export { _toggleMuteCall as toggleMuteCall };
const _toggleMuteVideoCall = toggleMuteVideoCall;
export { _toggleMuteVideoCall as toggleMuteVideoCall };
const _toggleMuteVideoTrackCall = toggleMuteVideoTrackCall;
export { _toggleMuteVideoTrackCall as toggleMuteVideoTrackCall };
const _audioCallBulle = audioCallBulle;
export { _audioCallBulle as audioCallBulle };
const _videoCallBulle = videoCallBulle;
export { _videoCallBulle as videoCallBulle };
