const { default: axios } = require("axios");


let host = window.location.origin,
    uri = '/get-my-state';

// ##################################

let getMyDevice = (() => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', `${host + uri}?param=hasConnected`);
    xhr.onload = (e) => {
        let data = JSON.parse(xhr.response);
        console.log(data);
    }
    xhr.send(null);
});

// getMyDevice();

/**
 * Privacys ckecher
 */

(() => {
    let privacy = {
        profile: document.getElementById('switch0001'),
        internal: document.getElementById('switch0002'),
        partners: document.getElementById('switch0003')
    };

    for (const item in privacy) {
        if (privacy[item]) {
            privacy[item].addEventListener('change', (e) => {
                let form = new FormData();
                form.append('_token', window.tkinter);
                if (e.target.checked) {
                    form.append(item, 'on');
                } else {
                    form.append(item, 'off');
                }
                let link = window.location.origin;
                if (/[a-zA-Z]+\.[a-zA-Z]+\.com/.test(link)) {
                    link = `${link}/public`;
                }
                axios({
                    method: 'POST',
                    url: `${window.location.origin}/privacy-${item}`,
                    data: form
                }).then(response => {
                    let data = response.data;
                    if (data.code == 200) {
                        console.log('success');
                    } else {
                        privacy[item].click();
                        console.error(data);
                    }
                });
            }, false);
        }
    }
})();

export function getMetaData() {
    return {
        countries: require('./weather/countries.json'),
        timezones: require('./weather/timezones.json'),
    };
}