// import { io } from "socket.io-client";
import {
    attachment,
    attachment_options,
    btnBlock,
    discussionList,
    formMsg,
    messageListItem,
    onWriting,
    textInput,
    textInputHoverView
} from "./credentials";
import {
    acceptCall,
    acceptVideoCall,
    audioCallBulle,
    call_audio,
    call_audio_option,
    call_video,
    call_video_option,
    declineCall,
    declineVideoCall,
    incoming_call_audio,
    incoming_call_audio_state,
    incoming_call_video,
    incoming_call_video_state,
    new_user_logo,
    new_user_logo_video_call,
    new_user_name,
    new_user_name_video_call,
    toggleMuteVideoTrackCall,
    videoCallBulle,
    video_call_souce
} from "./call_credentials";
import {
    data as disc
} from "./discussions_loader";
import {
    isEqualWith,
    isNull,
    parseInt
} from "lodash";
import {
    checkFormActionCredentials,
    deleteBtn,
    displayData,
    DomBuilder,
    editBtn,
    getElementIndex,
    getURI,
    in_array,
    lastItem,
    runSoung,
    stopSoung,
    ucfirst
} from "./page_actions_main";
import axios from "axios";
import {
    changeFileState,
    fileChangeAction,
    pictureChangeAction,
    saveMessage,
    sendMessage
} from "./page_action";
require('./header-search-bar')
import { Socket, io } from "socket.io-client";
const {
    v4: uuidV4
} = require('uuid'),
    EventEmitter = require('events'),
    event = new EventEmitter();

let options = {
        transports: ["websocket"],
    },
    // links = "https://spadliapp.newbody.spadli.com",
    getLinks = () => {
        if (getURI().match('192.168.1.20')) {
            return "http://192.168.1.20:5000";
        } else if (getURI().match('localhost')) {
            return "http://localhost:5000";
        } else if (getURI().match('.test')) {
            return "http://localhost:5000";
        } else if (getURI().match('.io')) {
            return "http://localhost:5000";
        } else {
            return "https://peercall-client.spadli.com";
        }
    },
    links = getLinks(),
    // links = "http://localhost:5000",
    /**
     * @type { Socket }
     */
    socket;
window.call_state = false; //   true When call is pending
window.socketId = null;
/**
 * @type {string}
 */
let callIsRunning = false,
    myPeer = null;
let tmpCall = 0;
/**
 * Data loader
 */
let itxyxec = {
    discussions: [],
    messages: []
};
window.currentDiscussion = 0;
// End
// Load file
/**
 * @type { Array<{ file: string; token: string; data: { ext: string; name: string; size: Array<string | number> | string } }> }
 */
window.tmpFile = [];
/**
 * @type { Array<File> }
 */
window.fileAtt = [];

discussionsLoader().then((data) => { // Load discussion before running Socket session
    if (data.code == 200) {
        connectSocket(data);
    }
});
/**
 * Run socket connection
 * @param {{ code: 200 | 404; discussions: { custom: {}, original: any[] }; messages: any[]; } | { code: 404; messages: ""; }} discus
 */
function connectSocket(discus) {
    try {
        socket = io(links);
        socket.on("connect", async() => {
            messageEventRenderer();
            let discussions_id = [];
            for (const i in window.itxyxec.discussions) {
                discussions_id.push(window.itxyxec.discussions[i].token);
            }
            socket.emit("user-connected", window.id, discussions_id, socket.id);
            if (attachment) {
                messageListItem.forEach(item => {
                    item.scrollTo(0, item.scrollHeight);
                });
            }
            // Event went messages sent successfully [Receiver side]
            let received_new_message = false;
            socket.on(`message-received`, messageReceived);
            /**
             * @param {{ response: { message: string; file_name: string[]; date: string; state: boolean; message_id: number; }; setting: { token: string; message: string; disc: number; user: string; corr: number; URI: string; file: [{ file: string | null; data: { ext: string; name: string; size: string | (string | number)[]; }; }], isMixed: boolean; form: { type: 'new-message'|'old-message'; discussion_id: number; message_id: number; user: number; } }}} credential
             */
            async function messageReceived(credential) {
                const discus = await discussionsLoader().then(data => data);
                if (discus.code == 200) {
                    if (received_new_message) {
                        return;
                    }
                    received_new_message = true;
                    itxyxec = discus;
                    received_new_message = !displayData(
                        credential.setting,
                        credential.response
                    );
                    if (credential.setting.form.type == 'old-message') {
                        return;
                    }
                    if (window.currentDiscussion == credential.setting.disc) {
                        discussionList[getElementIndex(window.currentDiscussion)].click();
                        runSoung('sms', false);
                    } else {
                        let index = getElementIndex(credential.setting.disc),
                            elem = discussionList[index];
                        if (in_array(window.itxyxec.discussions[credential.setting.disc].state, ['enabled', 'muted'])) {
                            if (window.itxyxec.discussions[credential.setting.disc].state == 'enabled') {
                                runSoung('sms', false);
                                elem.classList.remove('read');
                                elem.classList.remove('mute');
                                elem.classList.add('unread');
                            } else {
                                elem.classList.remove('unread');
                                elem.classList.remove('read');
                                elem.classList.add('mute');
                            }
                        }
                    }
                }
            }

            // onFileChange Event
            attachment_options["option-content"].forEach((options, key) => {
                options.querySelector('input#att_pict').addEventListener('change', e => {
                    changeFileState(pictureChangeAction(e, key))
                }, false);
                options.querySelector('input#att_file').addEventListener('change', e => {
                    changeFileState(fileChangeAction(e, key))
                }, false);
            });

            // Submit message
            if (formMsg.length > 0) {
                formMsg.forEach((formSubmitBtn, key) => {
                    formSubmitBtn.addEventListener('click', () => {
                        buildDataBeforeSendMessage(key);
                    }, false);
                });
            }
            if (document.querySelectorAll('form#form-msg textarea#text-input').length > 0) {
                document.querySelectorAll('form#form-msg textarea#text-input').forEach((textarea, key) => {
                    if (textInputHoverView[key]) {
                        textInputHoverView[key].addEventListener('keyup', e => {
                            if (e.key.toLowerCase() == 'enter') {
                                textInputHoverView[key].blur();
                                buildDataBeforeSendMessage(key);
                            }
                        }, false);
                    }
                });
            }
            // End submitting
            /**
             * Prepare data before submit for save
             * @param {number} key
             * @returns
             */
            async function buildDataBeforeSendMessage(key) {
                /**
                 * @type {HTMLFormElement}
                 */
                let form = formMsg[key].parentElement.parentElement,
                    credential = await sendMessage(form, key)
                    .then(resp => resp),
                    formActionCredentials = checkFormActionCredentials(form.getAttribute('data-action') || JSON.stringify({}));
                form.setAttribute('data-action', JSON.stringify({
                    type: 'new-message',
                    discussion_id: 0,
                    message_id: 0,
                    user: 0,
                }));
                if (credential.length == 0) {
                    return;
                }
                stack1 = false;
                credential = {...credential, ... { form: formActionCredentials } };
                runSavingProcess(credential, key);
            }

            /**
             * Initialize saving messages
             * @param {{ token: string; message: string; disc: number; user: string; corr: number; URI: string; file: [{ file: string | null; data: { ext: string; name: string; size: string | (string | number)[]; }; }], isMixed: boolean; form: { type: 'new-message'|'old-message'; discussion_id: number; message_id: number; user: number; } }} setting
             * @param {number} index
             */
            async function runSavingProcess(setting, index) {
                let response = await saveMessage(setting, index).then(response => response);
                window.preloader.launcheImage();
                socket.emit('message-sent', {
                    setting: setting,
                    response: response
                });
                setTimeout(() => {
                    try {
                        messageListItem[key].scrollTo(0, messageListItem[getElementIndex(setting.disc)].scrollHeight);
                    } catch (error) {
                        messageListItem[0].scrollTo(0, messageListItem[0].scrollHeight);
                    }
                }, 1000);
            }

            // When you write somme thing
            if (textInput.length > 0) { //  Next solution with eventEmitter
                textInput.forEach((item, index) => {
                    item.addEventListener('keyup', (e) => {
                        let discussion = formMsg[index].getAttribute('data-item')
                            .match(/[0-9]{1,},[0-9]{1,}/)[0]
                            .split(',')
                            .map((items) => parseInt(items)),
                            discussion_id,
                            corr;
                        if (discussion.length != 2) {
                            return false;
                        }
                        discussion_id = discussion[0];
                        corr = discussion[1];
                        socket.emit('write', {
                            on: discussion_id,
                            to: corr,
                            index: index
                        });
                    }, false);
                })
            }

            // Listen writing event
            let onWrite = false,
                itemWrite = 0,
                user_img = 'https://place-hold.it/45';
            try {
                let tmp1 = setInterval(() => {
                    if (onWrite) {
                        onWriting[itemWrite].style.display = 'block';
                        if (user_img !== 'https://place-hold.it/45') {
                            onWriting[itemWrite].firstElementChild.firstElementChild.src = `${getURI()}/banner/${user_img}`;
                        } else {
                            onWriting[itemWrite].firstElementChild.firstElementChild.src = user_img;
                        }
                    } else {
                        if (onWriting[itemWrite]) {
                            onWriting[itemWrite].style.display = 'none';
                        }
                    }
                }, 500);
                setInterval(() => {
                    if (onWrite) {
                        onWrite = false;
                        // itemWrite = 0;
                        user_img = 'https://place-hold.it/45';
                    }
                }, 1600);
            } catch (error) {
                console.log('...');
            }
            socket.on('writing', data => {
                if (window.currentDiscussion == data.on) {
                    itemWrite = getElementIndex(data.on);
                    let user_imgTmp = window.itxyxec.discussions[data.on]['user']['small_profile'];
                    if (!isNull(user_imgTmp)) {
                        user_img = user_imgTmp;
                    }
                    onWrite = true;
                }
            });

            // Change current discussion id
            discussionList.forEach((discussion, key) => {
                discussion.addEventListener('click', e => {
                    let discussion_id = getDiscussionId(discussion);
                    window.currentDiscussion = discussion_id;
                    axios({
                        url: `${getURI()}/api/messenger/mark-readed`,
                        method: 'post',
                        data: {
                            id: discussion_id,
                            user_id: window.id
                        }
                    }).then(data => {
                        socket.emit('message-readed', {
                            disc: discussion_id,
                            user: {
                                id: window.tkinter,
                                token: socket.id
                            }
                        });
                    })
                    setTimeout(() => {
                        messageEventRenderer();
                        goToDown(key);
                    }, 1000);
                });
            });

            // Event that occurs to mark a read message
            socket.on('message-readed-led', credential => {
                let index = 0,
                    /**
                     * @type { HTMLLIElement[] }
                     */
                    li;
                try {
                    index = getElementIndex(credential.disc);
                    li = document.querySelectorAll('#message-list-item')[index].children;
                } catch (error) {
                    li = document.querySelectorAll('ul#message-list-item')[0].children;
                }
                for (let key = 0; key < li.length; key++) {
                    if (li[key].classList.contains('me')) {
                        let led = li[key].querySelector('.text-box > span');

                        if (led.children.length == 0) {
                            let hour = led.textContent;
                            led.innerHTML = '<i class="ti-check"></i><i class="ti-check"></i>' + hour;
                        }
                    }
                }
            });

            // Whe we receive call
            socket.on('call-request', datas => {
                if (!window.call_state) {
                    window.discussion_id = datas.discussion_id;
                    let user = window.itxyxec.discussions[datas.discussion_id].user,
                    logo = isNull(user.small_profile) ? `${getURI()}/images/resources/author.jpg` : `${getURI()}/banner/${user.small_profile}`;
                    runCall(false, datas.call_id, datas.audio_mode, datas.video_mode, {
                        username: [user.last_name, user.first_name].map(item => ucfirst(item)).join(' '),
                        photo: logo,
                        disc: datas.discussion_id
                    }, datas.signal);
                }
            })

            window.socketId = socket.id;
            if (call_audio || call_audio_option) { //   Run audio call
                const call_audio_btns = [ ...Array.from(call_audio), ...Array.from(call_audio_option) ];
                call_audio_btns.forEach(function(btn, key) {
                    btn.addEventListener('click', async function(e) {
                        document.querySelector('.call-wraper').classList.value = 'call-wraper active';
                        if (window.call_state) {
                            return;
                        }
                        // Request to call

                        let discussion_id = await (() => {
                            return new Promise(resolve => {
                                setInterval(() => {
                                    if (window.currentDiscussion > 0) {
                                        resolve(window.currentDiscussion)
                                    }
                                }, 1000);
                                setTimeout(() => {
                                    resolve(0)
                                }, (1000*60));
                            })
                        })().then(id => id);
                        if (discussion_id == 0) return

                        let call_id = uuidV4(),
                            user = window.itxyxec.discussions[discussion_id].user,
                            logo = isNull(user.small_profile) ? `${getURI()}/images/resources/author.jpg` : `${getURI()}/banner/${user.small_profile}`;
                        runCall(true, call_id, true, false, {
                            username: [user.last_name, user.first_name].map(item => ucfirst(item)).join(' '),
                            photo: logo,
                            disc: discussion_id
                        }, null);
                    }, false);
                });
            }
            if (call_video || call_video_option) { //   Run video call
                const call_video_btns = [ ...Array.from(call_video), ...Array.from(call_video_option) ];
                call_video_btns.forEach(function(btn, key) {
                    btn.addEventListener('click', async (e) => {
                        document.querySelector('.vid-call-wraper').classList.value = 'vid-call-wraper active';
                        if (window.call_state) {
                            return;
                        }
                        // Request to call

                        let discussion_id = await (() => {
                            return new Promise(resolve => {
                                setInterval(() => {
                                    if (window.currentDiscussion > 0) {
                                        resolve(window.currentDiscussion)
                                    }
                                }, 1000);
                                setTimeout(() => {
                                    resolve(0)
                                }, (1000*60));
                            })
                        })().then(id => id);
                        if (discussion_id == 0) return

                        let call_id = uuidV4(),
                            user = window.itxyxec.discussions[discussion_id].user,
                            logo = isNull(user.small_profile) ? `${getURI()}/images/resources/author.jpg` : `${getURI()}/banner/${user.small_profile}`;
                        runCall(true, call_id, true, true, {
                            username: [user.last_name, user.first_name].map(item => ucfirst(item)).join(' '),
                            photo: logo,
                            disc: discussion_id
                        }, null);
                    }, false);
                });
            }

            btnBlock.forEach((btn, key) => {
                btn.addEventListener('click', () => {
                    let discussion_id = parseInt(discussionList[key].getAttribute('data-item').match(/[0-9]/)[0]),
                        currentState = window.itxyxec.discussions[discussion_id]['state-side'],
                        sent = false;

                    if (window.itxyxec.discussions[discussion_id].count !== 2) { // Dont block group discussion
                        return false;
                    }
                    if (sent) {
                        return false;
                    }
                    sent = true;
                    axios({
                        url: `${getURI()}/api/messenger/discussion-block`,
                        method: 'post',
                        data: {
                            id: discussion_id,
                            user_id: window.id,
                            action: currentState == 'enabled' ? 'blocked' : 'enabled',
                            count: window.itxyxec.discussions[discussion_id].count
                        }
                    }).then(async data => {
                        if (data.data.code == 200) {
                            const disc = await discussionsLoader().then(data => data);
                            if (disc.code == 200) {
                                let changed = false;
                                if (isEqualWith(currentState, 'enabled') && !changed) {
                                    btn.innerHTML = `<i class="fa fa-ban"></i> Unlock`;
                                    changed = true;
                                }
                                if (isEqualWith(currentState, 'blocked') && !changed) {
                                    btn.innerHTML = `<i class="fa fa-ban"></i> Block`;
                                    changed = true;
                                }
                                socket.emit('discussion_state_blocked', {
                                    discussion_id: discussion_id,
                                    currentState: currentState
                                });
                            }
                        }
                    })
                }, false);
            });
            socket.on('discussion_blocked', data => {
                if (stack3) {
                    return false;
                }
                stack3 = true;
                const index = getElementIndex(data.discussion_id);
                formMsg[index].classList.toggle('d-none');
                setTimeout(() => {
                    stack3 = false;
                }, 2000);
            });
            event.on('message-deleted', data => {
                socket.emit('message-deleted', data);
                discussionsLoader().then(discussion => discussion);
                messageEventRenderer();
                goToDown(getElementIndex(data.discussion_id));
            });
            socket.on('message-deleted-acknowledge', (data) => {
                let message =document.querySelectorAll('ul#message-list-item')[getElementIndex(data.discussion_id)].querySelector(`li[data-message="${data.message_id}"]`);
                if (!isNull(message)) {
                    message.remove();
                }
                discussionsLoader().then(discussion => discussion);
                messageEventRenderer();
                goToDown(getElementIndex(data.discussion_id));
            });
        });
        socket.on('call answer received', _signal => {
            myPeer.signal(JSON.parse(_signal))
        })
        // End off websocket workflow
    } catch (error) {
        setInterval(() => {
            alert('La connexion a été perdu avec le serveur distant');
            window.location.reload();
        }, (1000 * 60) * 2);
    }
}

/**
 * When the recipient of the message is not logged in, the sms tend to be displayed twice.
 * So this stack is used to block duplicate messages.
 * @type {boolean} true if message sent and false if not
 */
let stack1 = false,
    stack3 = false;

setInterval(() => {
    try {
        window.preloader.launcheImage();
        document.querySelectorAll('ul#discussion-list > li > a').forEach((link, index) => {
            if (link.classList.contains('active')) {
                let tmp_discussion_id = parseInt(link.parentElement.getAttribute('data-item').match(/[0-9]+/)[0] || '0')
                window.currentDiscussion = tmp_discussion_id
            }
        })
    } catch (error) {}
}, 1000);

export function messageEventRenderer() { // Delete and Update message text actions
    if (document.querySelectorAll('ul#message-list-item') && document.querySelectorAll('ul#message-list-item').length > 0) {
        document.querySelectorAll('ul#message-list-item').forEach((messageList, discussion_index) => {

            messageList.querySelectorAll('li div.touch-btn').forEach((touchBtn, key) => {
                let discussion = document.querySelectorAll('#discussion-list > li, .chat-users > li')[discussion_index],
                    discussion_id = 0;
                if (discussion.getAttribute('data-state')) {
                    discussion_id = window.preloader.currentDiscussion;
                } else {
                    discussion_id = getDiscussionId(document.querySelectorAll('#discussion-list > li, .chat-users > li')[discussion_index]);
                }

                touchBtn.querySelector('span.edit').addEventListener('click', () => { editBtn(key, discussion_id) }, false); // Run edit message
                touchBtn.querySelector('span.delete').addEventListener('click', async() => { // Delete message
                    let response = await deleteBtn(key).then(response => response);
                    if (response.deleted) {
                        document.querySelectorAll('ul#message-list-item')[getElementIndex(discussion_id)].querySelector(`li[data-message="${response.message_id}"]`).remove();
                        event.emit('message-deleted', {
                            discussion_id: discussion_id,
                            message_id: response.message_id
                        });
                    }
                }, false);
            });

        });
    }
}

/**
 * Get all my discussions
 * @returns {Promise<{ code: 200 | 404; discussions: { custom: {}, original: any[] }; messages: any[]; } | { code: 404; messages: ""; }>}
 */
export async function discussionsLoader() {
    return await disc().then(data => {
        if (data.code == 404) {
            return data;
        }
        if (data.code == 200) {
            window.itxyxec = {
                discussions: data.discussions.custom,
                messages: data.messages
            };
            return data;
        } else {
            return data;
        }
    })
}

/**
 * @param {number} key discussion index
 */
export function goToDown(key) {
    try {
        messageListItem[key].scrollTo(0, messageListItem[key].scrollHeight);
    } catch (error) {
        messageListItem[0].scrollTo(0, messageListItem[0].scrollHeight);
    }
}

/**
 * Get current discussion Id
 * @param {HTMLLIElement} discussion
 * @returns number
 */
export function getDiscussionId(discussion) {
    const id = parseInt(discussion.getAttribute('data-item').match(/[0-9]+/)[0]);
    return id;
}

/**
 * @type {MediaStream}
 */
let stream = null,
/**
 * @type {Array<{type: 'audio'|'video'; id: string;}>}
 */
callStory = [],
incoming_call_state;

/**
 * Call section
 * @param {boolean} initiator
 * @param {string} call_id
 * @param {boolean} audio_mode
 * @param {boolean} video_mode
 * @param {{username: string; photo: string; disc: number;}} credentials
 * @returns
 */
async function runCall(initiator, call_id, audio_mode, video_mode, credentials, signal) {
    if (window.call_state) { // If call is pending
        return;
    }
    window.call_state = true;
    callStory.push({
        type: video_mode ? 'video' : 'audio',
        id: call_id
    });

    let dote = ['.'];
    incoming_call_state = setInterval(() => {
        if (!callIsRunning) {
            if (dote.length >= 6) dote = ['.']
            if (initiator) {
                if (video_mode) {
                    incoming_call_video_state.textContent = `${dote.join('')}`
                } else {
                    incoming_call_audio_state.textContent = `${dote.join('')}`
                }
            } else {
                if (video_mode) {
                    incoming_call_video_state.textContent = `${dote.join('')}`
                } else {
                    incoming_call_audio_state.textContent = `${dote.join('')}`
                }
            }
            dote.push('.')
        } else {
            incoming_call_video_state.textContent = ``
            incoming_call_audio_state.textContent = ``
        }
    }, 1000);

    const index = getElementIndex(credentials.disc)

    socket.on('decline call request received', () => {
        callDecline(initiator, video_mode, credentials.disc, false, call_id)
        if (video_mode) {
            stopSoung('VideoCall')
        } else {
            stopSoung('AudioCall')
        }
    })

    if (initiator) {
        if (video_mode) {
            hide(acceptVideoCall)
        } else {
            hide(acceptCall)
        }
    }

    // Starting
    try {
        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = function(constraints) {

                var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                if (!getUserMedia) { // Decline call if is not present in browser
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }

                return new Promise(function(resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }
    } catch (error) {
        callDecline(initiator, video_mode, credentials.disc, true, call_id)
        throw error;
    }
    const peerConfig = {
        iceServers: [{
                url: 'turn:numb.viagenie.ca',
                credential: 'muazkh',
                username: 'webrtc@live.com'
            },
            {
                url: 'turn:192.158.29.39:3478?transport=udp',
                credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
                username: '28224511:1379330808'
            },
            {
                url: 'turn:192.158.29.39:3478?transport=tcp',
                credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
                username: '28224511:1379330808'
            },
            {
                url: 'turn:turn.bistri.com:80',
                credential: 'homeo',
                username: 'homeo'
            },
            {
                url: 'turn:turn.anyfirewall.com:443?transport=tcp',
                credential: 'webrtc',
                username: 'webrtc'
            },
            {
                urls: [
                    "turn:13.250.13.83:3478?transport=udp"
                ],
                username: "YzYNCouZM1mhqhmseWk6",
                credential: "YzYNCouZM1mhqhmseWk6"
            }
        ]
    },
    constraints = {
        audio: audio_mode,
        video: video_mode,
        groupId: call_id,
        echoCancellation: true,
        noiseSuppression: true,
        facingMode: { exact: "user" },
        width: { min: 640, ideal: 1280 },
        height: { min: 480, ideal: 720 },
        advanced: [
            { width: 1920, height: 1280 },
            { aspectRatio: 1.333 }
        ]
    };

    stream = await navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => stream)
        .catch(error => {
            console.error(error)
            callDecline(initiator, video_mode, credentials.disc, true, call_id)
        })

    if (!(stream instanceof MediaStream)) return;

    if (video_mode) {
        document.querySelector('#my-video').srcObject = stream
        document.querySelector('#my-video').play()
    }

    // Activating Buttons
    if (video_mode) {
        enable(document.querySelector('#control-point > a.mute-call'))
        enable(toggleMuteVideoTrackCall)
        window.muteVideoCall = () => {
            toggleVideoState(stream)
        }
    } else {
        enable(document.querySelector('div.yesorno > a.mute-call'))
    }

    window.muteCall = () => {
        toggleAudioState(stream, video_mode)
    }

    if (initiator) {
        declineCall.addEventListener('click', () => {
            if (!isNull(myPeer)) {
                myPeer.destroy()
            }
            callDecline(initiator, video_mode, credentials.disc, true, call_id);
        })
        declineVideoCall.addEventListener('click', () => {
            if (!isNull(myPeer)) {
                myPeer.destroy()
            }
            callDecline(initiator, video_mode, credentials.disc, true, call_id);
        })
    } else {
        declineCall.addEventListener('click', () => {
            if (!isNull(myPeer)) {
                myPeer.destroy()
            }
            callDecline(initiator, video_mode, credentials.disc, true, call_id);
        })
        declineVideoCall.addEventListener('click', () => {
            if (!isNull(myPeer)) {
                myPeer.destroy()
            }
            callDecline(initiator, video_mode, credentials.disc, true, call_id);
        })
    }
    // Activating Buttons End

    // Display user informations
    if (!video_mode) {
        new_user_logo.src = credentials.photo
        new_user_name.textContent = credentials.username
        incoming_call_audio.textContent = initiator ? 'Outgoing Call' : 'Incoming Call'
        // Display call and run song
        if (!initiator) {
            audioCallBulle.classList.value = 'call-wraper active'
            enable(acceptCall)
            runSoung('AudioCall', true);
            /**
             * @type {boolean}
             */
            const response = await (() => {
                return new Promise(resolve => {
                    acceptCall.addEventListener('click', () => {resolve(true)}, false)
                    declineCall.addEventListener('click', () => {resolve(false)}, false)
                })
            })().then(response => response);
            if (!response) { // Decline call
                callDecline(initiator, video_mode, credentials.disc, true, call_id)
                stopSoung('AudioCall')
                return;
            }
            stopSoung('AudioCall')
        }
    } else {
        new_user_logo_video_call.src = credentials.photo
        new_user_name_video_call.textContent = credentials.username
        incoming_call_video.textContent = initiator ? 'Outgoing Call' : 'Incoming Call'
        // Display call and run song
        if (!initiator) {
            videoCallBulle.classList.value = 'vid-call-wraper active'
            enable(acceptVideoCall)
            runSoung('VideoCall', true);
            /**
             * @type {boolean}
             */
            const response = await (() => {
                return new Promise(resolve => {
                    acceptVideoCall.addEventListener('click', () => {resolve(true)}, false)
                    declineVideoCall.addEventListener('click', () => {resolve(false)}, false)
                })
            })().then(response => response);
            if (!response) { // Decline call
                callDecline(initiator, video_mode, credentials.disc, true, call_id)
                stopSoung('VideoCall')
                return;
            }
            stopSoung('VideoCall')
        }
    }

    if (SimplePeer.WEBRTC_SUPPORT) {
        if (initiator) {
            myPeer = new SimplePeer({
                initiator: initiator,
                stream: stream,
                config: peerConfig,
                trickle: false,
            });
            peerController(myPeer, initiator, call_id, audio_mode, video_mode, credentials.disc)
        } else {
            if (myPeer == null) {
                myPeer = new SimplePeer({
                    initiator: initiator,
                    stream: stream,
                    config: peerConfig,
                    trickle: false,
                });
                peerController(myPeer, initiator, call_id, audio_mode, video_mode, credentials.disc)
            }
            myPeer.signal(JSON.parse(signal))
        }

        myPeer.on('close', () => { // Decline call
            if (video_mode) {
                disable(document.querySelector('#control-point > a.mute-call'))
                disable(toggleMuteVideoTrackCall)
                document.querySelector('#control-point > a.mute-call').innerHTML = '<i class="fa fa-microphone"></i>'
                document.querySelector('div.yesorno > a.mute-video-call').innerHTML = '<i class="fa fa-video"></i>'
            } else {
                disable(document.querySelector('div.yesorno > a.mute-call'))
                document.querySelector('div.yesorno > a.mute-call').innerHTML = '<i class="fa fa-microphone"></i>'
            }
            video_call_souce.srcObject = null;
            myPeer = null;
        })
        myPeer.on('error', error => {
            callDecline(initiator, video_mode, credentials.disc, true, call_id)
            console.error(error);
        })
    } else {
        console.error('Your navigator does\'nt support WRTC technology. Pleace update your browser and try again !!');
        callDecline(initiator, video_mode, credentials.disc, true, call_id)
    }
}

/**
 * Run decline call process
 * @param {boolean} initiator
 * @param {boolean} is_video_call
 * @param {number} currentDiscussion
 * @param {boolean} declineInitiator
 * @param {string} call_id
 * @returns
 */
function callDecline(initiator, is_video_call, currentDiscussion, declineInitiator, call_id) {
    let call_credentials = callStory.filter(call => call.id == call_id)
    if (call_credentials.length == 0) return;
    call_credentials = call_credentials[0]
    if (is_video_call) {
        show(acceptVideoCall)
        disable(acceptVideoCall)
        disable(document.querySelector('#control-point > a.mute-call'))
        disable(toggleMuteVideoTrackCall)
        document.querySelector('#control-point > a.mute-call').innerHTML = '<i class="fa fa-microphone"></i>'
        document.querySelector('div.yesorno > a.mute-video-call').innerHTML = '<i class="fa fa-video"></i>'
    } else {
        show(acceptCall)
        disable(acceptCall)
        disable(document.querySelector('div.yesorno > a.mute-call'))
        document.querySelector('div.yesorno > a.mute-call').innerHTML = '<i class="fa fa-microphone"></i>'
    }

    if (stream !== null) {
        stream.getTracks().forEach(track => {
            track.stop();
        })
    }
    clearInterval(tmpCall)
    if (is_video_call) {
        document.querySelector('#my-video').srcObject = null
        document.querySelector('#my-video').pause()
        document.querySelector('#my-video').currentTime = 0
    }
    callIsRunning = false
    myPeer = null
    incoming_call_audio.textContent = `Incoming Call`
    incoming_call_video.textContent = `Incoming Call`
    audioCallBulle.classList.value = 'call-wraper'
    videoCallBulle.classList.value = 'vid-call-wraper'
    window.call_state = false
    if (declineInitiator) socket.emit('call declined')
    saveCall(initiator, is_video_call ? 'video' : 'audio', currentDiscussion)
    callStory = callStory.filter(call => call.id !== call_id)
}

/**
 * This function is an integral part of the runCall function. It has been separated for code readability.
 * @param {any} peerConnexion
 * @param {boolean} initiator
 * @param {string} call_id
 * @param {boolean} audio_mode
 * @param {boolean} video_mode
 * @param {number} discussion_id
 */
function peerController(peerConnexion, initiator, call_id, audio_mode, video_mode, discussion_id) {
    let call_accepted = false;
    peerConnexion.on('signal', signal => {
        if (initiator) {
            socket.emit('call request', {
                call_id,
                audio_mode,
                video_mode,
                discussion_id,
                signal: JSON.stringify(signal)
            })
            setTimeout(() => {
                if (!call_accepted) {
                    callDecline(initiator, video_mode, discussion_id, true, call_id);
                    peerConnexion.destroy();
                }
            }, (1000*60));
        } else {
            socket.emit('call answer', JSON.stringify(signal))
        }
    })

    peerConnexion.on('stream', stream => {
        video_call_souce.srcObject = stream
        video_call_souce.play()
    })

    peerConnexion.on('connect', () => {
        call_accepted = true
        callIsRunning = true
        setTimeout(() => {
            clearInterval(incoming_call_state);
        }, 2000);
        callRecorder()
        if (initiator) {
            declineCall.addEventListener('click', () => {
                peerConnexion.destroy()
                callDecline(initiator, video_mode, discussion_id, true, call_id);
            })
            declineVideoCall.addEventListener('click', () => {
                peerConnexion.destroy()
                callDecline(initiator, video_mode, discussion_id, true, call_id);
            })
            return;
        }

        video_mode ? hide(acceptVideoCall) : hide(acceptCall)
        declineCall.addEventListener('click', () => {
            peerConnexion.destroy()
            callDecline(initiator, video_mode, discussion_id, true, call_id);
        })
        declineVideoCall.addEventListener('click', () => {
            peerConnexion.destroy()
            callDecline(initiator, video_mode, discussion_id, true, call_id);
        })
    })
}

/**
 * Save call in database like storie
 * @param {boolean} initiator
 * @param {'video'|'audio'} type
 * @param {number} currentDiscussion
 * @returns {void}
 */
function saveCall(initiator, type, disc_id) {
    let date = new Date().toString().split(' ').slice(0, 5).join(' '),
        config = {
            message: '',
            disc: disc_id,
            sender: window.id,
            user_id: window.id,
            date: date
        };
    if (initiator) {
        axios({ //  Save as message
            method: 'POST',
            url: `${getURI()}/api/send/message`,
            data: config
        }).then(async data => {
            if (data.data.code == 200) {
                const discus = await discussionsLoader().then(data => data);
                if (discus.code == 200) {
                    itxyxec = discus; // Update discussion list localy
                    let message_id = lastItem(itxyxec.messages[disc_id])['id'];
                    axios({ //  Save as attachment
                        url: `${getURI()}/api/save-call`,
                        method: 'POST',
                        data: {
                            message_id: message_id,
                            user_id: window.id,
                            date: date,
                            type: type ? 'video' : 'audio'
                        }
                    }).then(response => {
                        if (response.data.code == 200) {
                            socket.emit('call_saved');
                            displayCallState(date, disc_id, initiator);
                        }
                    });
                }
            }
        });
        return;
    }
    socket.on('call-saved-acknowledge', async() => {
        const discus = await discussionsLoader().then(data => data);
        if (discus.code == 200) {
            itxyxec = { discussions: discus.discussions.custom, messages: discus.messages }; // Update discussion list localy
            let date = lastItem(itxyxec.messages[disc_id])['created_at'];
            displayCallState(date, disc_id, initiator);
        }
    });
}

/**
 * Display call storie to message list
 * @param {number} message_id
 * @param {number} user_id
 * @param {string} call_date
 * @param {number} discussion_id
 * @param {boolean} initiator
 */
function displayCallState(call_date, discussion_id, initiator) {
    call_date = call_date.replace('.000000Z', '');
    call_date = call_date.replace('T', ' ');
    let date = new Date(call_date),
        /**
         * @type {HTMLDivElement}
         */
        storie = '';
    if (initiator) {
        storie = `
            <div class="d-flex mb-4 justify-content-center align-items-center">
                <div class="call-divider w-100 rounded-lg pt-1 bg-info"></div>
                <small class="bg-light position-absolute px-3">Call at ${ date.getHours() < 10 ? '0'+date.getHours() : date.getHours() }:${ date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes() }</small>
            </div>
        `;
    } else {
        storie = `
            <div class="d-flex mb-4 justify-content-center align-items-center">
                <div class="call-divider w-100 rounded-lg pt-1 bg-info"></div>
                <small class="bg-light position-absolute px-3">You received call at ${ date.getHours() < 10 ? '0'+date.getHours() : date.getHours() }:${ date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes() }</small>
            </div>
        `;
    }
    storie = DomBuilder(storie).children[0];
    document.querySelectorAll('ul#message-list-item')[getElementIndex(discussion_id)].insertBefore(storie, document.querySelectorAll('ul#message-list-item li#onWriting')[getElementIndex(discussion_id)]);
    discussionList[getElementIndex(discussion_id)].click();
    messageEventRenderer();
    goToDown(getElementIndex(discussion_id));
}

function callRecorder() {
    let hours = 0,
        minutes = 0,
        seconds = 0;

    tmpCall = setInterval(() => {
        seconds++;
        if (seconds > 59) {
            seconds = 0;
            minutes++;
        }
        if (minutes > 59) {
            minutes = 0;
            hours++;
        }
        let tmpHours = hours < 10 ? '0' + hours.toString() : hours,
            tmpMinutes = minutes < 10 ? '0' + minutes.toString() : minutes,
            tmpSeconds = seconds < 10 ? '0' + seconds.toString() : seconds;
        if (callIsRunning) {
            incoming_call_audio.textContent = `${tmpHours}:${tmpMinutes}:${tmpSeconds}`;
            incoming_call_video.textContent = `${tmpHours}:${tmpMinutes}:${tmpSeconds}`;
        }
    }, 1000);
}

/**
 * @param {MediaStream} stream
 * @param {boolean} is_video_call
 */
 function toggleAudioState(stream, is_video_call) {
    stream.getAudioTracks().forEach(track => {
        const newTrack = !track.enabled;
        track.enabled = newTrack;
        if (newTrack) {
            if (is_video_call) {
                document.querySelector('#control-point > a.mute-call').innerHTML = '<i class="fa fa-microphone"></i>'
            } else {
                document.querySelector('div.yesorno > a.mute-call').innerHTML = '<i class="fa fa-microphone"></i>'
            }
        } else {
            if (is_video_call) {
                document.querySelector('#control-point > a.mute-call').innerHTML = '<i class="fa fa-microphone-slash"></i>'
            } else {
                document.querySelector('div.yesorno > a.mute-call').innerHTML = '<i class="fa fa-microphone-slash"></i>'
            }
        }
    })
}

/**
 * @param {MediaStream} stream
 */
function toggleVideoState(stream) {
    stream.getVideoTracks().forEach(track => {
        const newTrack = !track.enabled;
        track.enabled = newTrack;
        if (newTrack) {
            document.querySelector('div.yesorno > a.mute-video-call').innerHTML = '<i class="fa fa-video"></i>'
        } else {
            document.querySelector('div.yesorno > a.mute-video-call').innerHTML = '<i class="fa fa-video-slash"></i>'
        }
    })
}

/**
 * @param {Element} element
 * @return {void}
 */
 function show(element) {
     if (element.classList.contains('d-none')) element.classList.remove('d-none');
}

/**
 * @param {Element} element
 * @return {void}
 */
function hide(element) {
    if (!element.classList.contains('d-none')) element.classList.add('d-none');
}

/**
 * @param {Element} element
 * @return {void}
 */
 function enable(element) {
     if (element.classList.contains('disabled')) element.classList.remove('disabled');
}

/**
 * @param {Element} element
 * @return {void}
 */
function disable(element) {
    if (!element.classList.contains('disabled')) element.classList.add('disabled');
}
