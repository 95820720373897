import { DomBuilder } from "./page_actions_main";

/**
 * Build a progressbar Element
 * @returns {HTMLDivElement}
 */
export function progressbarBuilder() {
    return DomBuilder(`
        <div id="custom-progessbar" class="mb-3 mb-2">
            <span class="text-dark" id="progessbar-title" style="font-size: 13px"></span>
            <div class="progress-container overflow-hidden">
                <div class="progress-inner bg-warning progress-bar-animated progress-bar-striped" id="progress-inner" style="width: 40%" data-current="40" data-total="100"><small class="text-dark">40%</small></div>
            </div>
        </div>
    `).children[0];
}
