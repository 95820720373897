// Elements list
// Discussions page cred
let discussionList = document.querySelectorAll('#discussion-list > li[data-item], #chat-users > li[data-item]'),
    // btn option
    btnMute = document.querySelectorAll('#discussion-list > li[data-item] li#post-mute'),
    btnDelete = document.querySelectorAll('#discussion-list > li[data-item] li#post-delete'),
    btnArchive = document.querySelectorAll('#discussion-list > li[data-item] li#post-archive'),
    btnBlock = document.querySelectorAll('#discussion-list > li[data-item] li#post-block'),
    btnUnreaded = document.querySelectorAll('#discussion-list > li[data-item] li#post-unreaded'),
    // End
    header = document.querySelectorAll('#header-message-top-tab'),
    messageList = document.querySelectorAll('#message-list'),
    messageListItem = document.querySelectorAll('#message-list-item'),
    contactInfo = document.querySelectorAll('#contact-info'),
    emojiList = document.querySelectorAll('#emoji-list > li img'),
    textInput = document.querySelectorAll('#text-input'),
    textInputHoverView = document.querySelectorAll('div[contenteditable="true"]'),
    onWriting = document.querySelectorAll('#onWriting'),
    attachment = document.querySelectorAll('.attachment-content#main-closer'),
    attachmentCloser = document.querySelectorAll('.attachment-closer#close-content'),
    attachmentContent = {
        'attachment-inner': (index) => attachment[index].querySelector('div.attachment-list'),
        'closer-item': document.querySelectorAll('a.attachment-closer#close-item'),
        'video-close-content': document.querySelectorAll('a.attachment-closer#video-close-content')
    },
    formMsg = document.querySelectorAll('form#form-msg a#submit-btn'),
    submit = document.querySelectorAll('#submit-msg'),
    // Home page credentials
    chat_name = document.querySelector('#chat-name'),
    chat_status = document.querySelector('#chat-status'),
    chat_close_message = document.querySelector('#close-message'),
    chat_users = document.querySelector('ul.chat-users#chat-users'),
    chat_box = document.querySelector('div.chat-box#chat-box'),
    options = {
        /**
         * @type {Array<HTMLLinkElement>}
         */
        toggleBtn: document.querySelectorAll('a[data-action="toggle"]#toggler'),
        /**
         * @type {Array<HTMLDivElement>}
         */
        'option-content': document.querySelectorAll('div.option-content'),
        /**
         * @type {Array<HTMLDivElement>}
         */
        'attachment-content': document.querySelectorAll('div.attachment-content'),
    },
    attachmentFile = document.querySelectorAll('input[type="file"]');

// Datas
let fileAllowed = ['jpeg', 'jpg', 'png', 'mp3', 'mp4', '3gp', 'txt', 'pdf', 'zip'];

const _discussionList = discussionList;
export { _discussionList as discussionList };
const _btnMute = btnMute;
export { _btnMute as btnMute };
const _btnDelete = btnDelete;
export { _btnDelete as btnDelete };
const _btnArchive = btnArchive;
export { _btnArchive as btnArchive };
const _btnBlock = btnBlock;
export { _btnBlock as btnBlock };
const _btnUnreaded = btnUnreaded;
export { _btnUnreaded as btnUnreaded };
const _header = header;
export { _header as header };
const _messageList = messageList;
export { _messageList as messageList };
const _contactInfo = contactInfo;
export { _contactInfo as contactInfo };
const _messageListItem = messageListItem;
export { _messageListItem as messageListItem };
const _emojiList = emojiList;
export { _emojiList as emojiList };
const _formMsg = formMsg;
export { _formMsg as formMsg };
const _submit = submit;
export { _submit as submit };
const _textInput = textInput;
export { _textInput as textInput };
const _textInputHoverView = textInputHoverView;
export { _textInputHoverView as textInputHoverView };
const _onWriting = onWriting;
export { _onWriting as onWriting };
//
const _attachmentFile = attachmentFile;
export { _attachmentFile as attachmentFile };
//
const _attachment = attachment;
export { _attachment as attachment };
const _attachmentCloser = attachmentCloser;
export { _attachmentCloser as attachmentCloser };
const _attachmentContent = attachmentContent;
export { _attachmentContent as attachmentContent };
const _options = options;
export { _options as attachment_options };
const _fileAllowed = fileAllowed;
export { _fileAllowed as fileAllowed };
const _chat_users = chat_users;
export { _chat_users as chat_users };
const _chat_box = chat_box;
export { _chat_box as chat_box };
const _chat_name = chat_name;
export { _chat_name as chat_name };
const _chat_status = chat_status;
export { _chat_status as chat_status };
const _chat_close_message = chat_close_message;
export { _chat_close_message as chat_close_message };