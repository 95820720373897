
let uploadFormPhoto = document.querySelector('form#postImage, form#postVideo'),
      fileFormPhoto = document.querySelector('form#postImage input#chooseFile, form#postVideo input#chooseFile'),
      filterSelection = document.querySelectorAll('ul#photo-filter li > a, ul#video-filter li > a'),
      popupCloseBtn = document.querySelector('span.popup-closed');

const _uploadFormPhoto = uploadFormPhoto;
export { _uploadFormPhoto as uploadFormPhoto };
const _fileFormPhoto = fileFormPhoto;
export { _fileFormPhoto as fileFormPhoto };
const _filterSelection = filterSelection;
export { _filterSelection as filterSelection };
const _popupCloseBtn = popupCloseBtn;
export { _popupCloseBtn as popupCloseBtn };